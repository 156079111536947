import { React, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import RichTextEditor from "react-rte"; // Copy Editor
import { useParams } from "react-router-dom";
import { getUser } from "../../../../services/AuthService";
import { useDispatch } from "react-redux";
import { addPatientClinicalDischarge } from "../../../../services/PatientClinicalDischargeService";
import { toast } from "react-toastify";
import { fetchPatientClinicalDischarge } from "../../../../features/patients/patientClinicalDischargeSlice";
import { fetchPatientClinicalDischargesCount } from "../../../../features/patients/counts/patientClinicalDischargesCountSlice";
import { Bars } from "react-loader-spinner";

function DSNoteModal({ show, onClose }) {
  let { id } = useParams();
  const user = getUser();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState(RichTextEditor.createEmptyValue());
  const [value2, setValue2] = useState(RichTextEditor.createEmptyValue());
  const [value3, setValue3] = useState(RichTextEditor.createEmptyValue());
  // const [value4, setValue4] = useState(RichTextEditor.createEmptyValue());

  const [dischargeDiagnosis, setDischargeDiagnosis] = useState("");
  const [pertinent, setPertinent] = useState("");
  const [lab, setLab] = useState("");

  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);

  const handleSub = () => {
    onClose();
    setAddReportShow(false);

    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const onChange = (value) => {
    setValue(value);
    value.toString("html");
    setDischargeDiagnosis(value.toString("html"));
  };

  const onChange2 = (value) => {
    setValue2(value);
    value.toString("html");
    setPertinent(value.toString("html"));

  };

  const onChange3 = (value) => {
    setValue3(value);
    value.toString("html");
    setLab(value.toString("html"));
  };



  const [isProcedures, setIsProcedures] = useState();
  const [isComplications, setIsComplications] = useState();
  const [isConsultations, setIsConsultations] = useState();
  const [isConduct, setIsConduct] = useState();
  const [isDisposition, setIsDisposition] = useState();
  const [isDischarge, setIsDischarge] = useState();
  const [isDiet, setIsDiet] = useState();
  const [isActivity, setIsActivity] = useState();
  const [isDME, setIsDME] = useState();
  const [isIHomeHealth, setIsHomeHealth] = useState();
  const [isLab2, setIsLab2] = useState();
  const [isApts, setIsApts] = useState();
  const [isMeds, setIsMeds] = useState();
  const [isStop, setIsStop] = useState();
  const [isModify, setIsModify] = useState();
  const [isNewRx, setIsNewRx] = useState();

  const handleAdd = () => {
    setLoading(true);
    const data = {
      user: user?._id,
      patient: id,
      is_procedures: isProcedures,
      is_complications: isComplications,
      is_consultations: isConsultations,
      is_conduct: isConduct,
      is_disposition: isDisposition,
      is_discharge: isDischarge,
      is_diet: isDiet,
      is_activity: isActivity,
      is_dME: isDME,
      is_iHomeHealth: isIHomeHealth,
      is_lab2: isLab2,
      is_apts: isApts,
      is_meds: isMeds,
      is_stop: isStop,
      is_modify: isModify,
      is_newRx: isNewRx,
      lab: lab,
      pertinent: pertinent,
      discharge_diagnosis: dischargeDiagnosis,
    };
    console.log(data);
    addPatientClinicalDischarge(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(
            fetchPatientClinicalDischarge({ patientId: id})
          );
          dispatch(fetchPatientClinicalDischargesCount({patientId: id}))
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };


  return (
    <div>
     <div>
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                    <div className="Solera-Settings-Header">
                      Discharge Summary
                    </div>
                    <div className="Solera-Settings-Note">Add a Note</div>
                  </div>
                </div>
                <br />
                <br />
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="Solera-Settings-Header44">
                      Discharge Diagnosis
                    </div>
                    <div className="Solera-Settings-Note">
                    Add the discharge diagnosis number for each line.
                    </div>
                    <div className="col">
                      {/* Copy Editor */} <br />
                      <RichTextEditor
                        className="editor"
                        value={value}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="Solera-Settings-Header44">
                    Discharge Diagnosis
                  </div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Procedures and Therapies"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsProcedures(e.target.value)}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Complications"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsComplications(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Consultations"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsConsultations(e.target.value)}
                      />
                    </div>
                  </div>
                </div>{" "}

               
                <br />
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="Solera-Settings-Header44">
                      Pertinent History
                    </div>
                    <br />
                    {/* <div className="Solera-Settings-Note">
                      Add by numbers tha
                    </div> */}
                    <div className="col">
                      <RichTextEditor
                        className="editor"
                        value={value2}
                        onChange={onChange2}
                      />
                    </div>
                  </div>
                </div>   <br/>
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="Solera-Settings-Header44">Lab</div>
                    <br />
                    {/* <div className="Solera-Settings-Note">
                      Add by numbers tha
                    </div> */}
                    <div className="col">
                      <RichTextEditor
                        className="editor"
                        value={value3}
                        onChange={onChange3}
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Conduct on Discharge"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsConduct(e.target.value)}

                        
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Disposition"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsDisposition(e.target.value)}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Discharge to"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsDischarge(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Diet"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsDiet(e.target.value)}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Activity"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsActivity(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
               
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="DME"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsDME(e.target.value)}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Home Health Service"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsHomeHealth(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Lab2"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsLab2(e.target.value)}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="F/U Apts"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsApts(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Meds"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsMeds(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
               
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Stop"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsStop(e.target.value)}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Modify"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsModify(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="New Rx"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsNewRx(e.target.value)}
                      />
                    </div>
                   
                  </div>
                </div>
                <div className="FormUpload">
                  {loading ? (
                    <Bars
                      heigth="50"
                      width="50"
                      color="gray"
                      ariaLabel="loading-indicator"
                    />
                  ) : (
                    <button className="Add-Button" onClick={handleAdd}>
                      Submit Discharge Summary
                    </button>
                  )}
                </div>
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default DSNoteModal;
