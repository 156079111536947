import axios from '../axiosinstance';

      
export function listPatientIntraoperatives(initData){
        return axios.get('/patient-intraoperatives', { params: { name: initData?.search, type: initData?.type, patient: initData?.patientId } })
}

export function addPatientIntraoperative(data){
        return axios.post('/patient-intraoperatives', data)
}

export function getPatientIntraoperative(initData){
        return axios.get('/patient-intraoperatives/'+initData?.id)
}

export function updatePatientIntraoperative(id, initData){
        return axios.patch('/patient-intraoperatives/'+id, initData)
}

export function patientPreIntraoperativesCount(search){
        return axios.get('/patient-intraoperatives/stat/count', { params: search })
}

