import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientIntraoperatives } from '../../services/PatientIntraoperativeService'

const initialState = {
    loading: false,
    intraoperatives: [],
    isSuccessful: false
}

export const fetchPatientIntraoperatives = createAsyncThunk('fetchPatientIntraoperatives', (initData) => {
    return listPatientIntraoperatives(initData)
    .then((response) => response.data.data)
})


const patientIntraoperativeSlice = createSlice({
    name: 'patientIntraoperatives',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientIntraoperatives.pending, (state) => {
        state.loading = true
        state.isSuccessful = false
       })
       builder.addCase(fetchPatientIntraoperatives.fulfilled, (state, action ) => {
        state.loading = false
        state.intraoperatives = action.payload.data
        state.isSuccessful = true
       })
       builder.addCase(fetchPatientIntraoperatives.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default patientIntraoperativeSlice.reducer 