import { React, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import moment from "moment";
import { Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

function ViewAnaModal({ show, onClose, selected }) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [addDoctorShow, setAddDoctorShow] = useState(false);
  const handleAddDoctor = () => setAddDoctorShow(false);

  const showDate = (passDate) => {
    return moment(passDate).format("DD-MM-YYYY");
  };
  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera-Modal-Container">
                <br />
                <div className="Report-Display-Heading">
                  Patient's Pre-Anaesthetic
                </div>
                <div className="Report-Tittle">Patient's Anasethetic</div>
                <div className="Solera-Modal-Container50">
                  <div className="Note-Modal-Cont">
                    <div>
                      <div className="Doctors-Name">Doctor's Name</div>
                      <div className="Doctors-Name1">
                        {selected?.user?.full_name}
                      </div>
                      <div className="Doctors-Name">Date</div>
                      <div className="Doctors-Name1">
                        {showDate(selected?.created_at)}
                      </div>
                      <div className="Doctors-Name">Number</div>
                      <div className="Doctors-Name1">{selected?.code}</div>
                      <br />
                      <br />
                      <div className="Doctors-Name">DIAGNOSTICS(Surgical-Anaesthetic)</div>
                      <div className="Report-Date4">
                        <div
                          dangerouslySetInnerHTML={{ __html: selected?.surgicalAnaesthetic }}
                        ></div>
                      </div>
                      <br />
                      <br />
                      <div className="Doctors-Name">PLANNED INTERVENTION(Treaatments)</div>
                      <div className="Report-Date4">
                        <div
                          dangerouslySetInnerHTML={{ __html: selected?.treatments }}
                        ></div>
                      </div>
                      <br />
                      <br />
                      {/* <div className="Doctors-Name">SYSTEM</div> <br /> */}
                      <div className="Doctors-Name">(SYSTEM)RESPIRATORY</div>
                      <div className="Report40">{selected?.if_respirory}</div>
                     
                      <br />
                      <br />
                      <div className="Doctors-Name">CARDIOVASCULAR</div>
                      <div className="Report40">{selected?.if_cardio}</div>
                      <br />
                      <br />
                      <div className="Doctors-Name">HEPATO/GASTRO</div>
                      <div className="Report40">{selected?.if_hepato}</div>
                      <br />
                      <br />
                      <div className="Doctors-Name">
                      NEURO/MUSCULO-SKELETAL
                      </div>
                      <div className="Report40">{selected?.if_neuro}</div>
                      <br />
                      <br />
                      <div className="Doctors-Name">
                      RENAL / ENDOCRINE
                      </div>
                      <div className="Report40">{selected?.if_renal}</div>
                      <br />
                      <br />
                      <div className="Doctors-Name">
                      AUTRES
                      </div>
                      <div className="Report40">{selected?.if_autres}</div>
                      <br />
                      <br />
                      <div className="Doctors-Name">EXAMINATION</div> <br />
                      <div className="Doctors-Name">CONJUCITIVA</div>
                      <div className="Report40">{selected?.if_examinations}</div>
                      <br />
                      <br />
                      <div className="Doctors-Name">PULMONARY AUSCULTATION</div>
                      <div className="Report40">{selected?.if_ausculation}</div>




                      <div className="Report30">
                        Nature :{" "}
                        <span className="Report40"> Nature Display</span>
                      </div>
                      <br />
                      <br />
                      <div className="Doctors-Name">CARDIAC AUSCULTATION</div>
                      <div className="Report40">{selected?.if_cardiac}</div>
                      <br />
                      <br />
                      <div className="Doctors-Name">VENOUS ACCESS</div>
                      <div className="Report40">{selected?.if_venous}</div>
                      <br />
                      <br />
                      <div className="Doctors-Name">
                        AIRWAY EXAMINATION
                      </div>{" "}
                      <br />
                      <div className="Doctors-Name">MOUTH OPENING</div>
                      <div className="Report40">{selected?.if_mouth}</div>
                      <div className="Report30">
                        Others :{" "}
                        <span className="Report40"> Others Display</span>
                      </div>
                      <br />
                      <br />
                      <div className="Doctors-Name">MALLAMPATI</div>
                      <div className="Report40">{selected?.if_pulmonary}</div>
                      <br />
                      <br />
                      <div className="Doctors-Name">THYRO-MENTAL DISTANCE</div>
                      <div className="Report40">{selected?.if_thyro}</div>
                      <br />
                      <br />

                      <div className="Doctors-Name">MOBITITY OF THE C-SPINE</div>
                      <div className="Report40">{selected?.if_mobility}</div>
                      <div className="Report30">
                        Others :{" "}
                        <span className="Report40">{selected?.if_CSpine} </span>
                      </div>
                      <br />
                      <br />

                      <div className="Doctors-Name">  EXAMEN LABO</div>
                      <div className="Report-Date4">
                        <div
                          dangerouslySetInnerHTML={{ __html: selected?.examen_labo }}
                        ></div>
                      </div>                    

                      <div className="Doctors-Name">CONCLUSION</div> <br />
                      <div className="Doctors-Name">PULMONARY AUSULTATION</div>
                      <div className="Report40">{selected?.if_conclusions}</div>
                      <br />
                      <br />
                      <div className="Doctors-Name">ASA CLASSIFICATION</div>
                      <div className="Report40">{selected?.if_classification}</div>
                      <br />
                      <br />
                      <div className="Doctors-Name">ADDITIONAL TESTS</div>
                      <div className="Report-Date4">
                        <div
                          dangerouslySetInnerHTML={{ __html: selected?.aditional_test }}
                        ></div>
                      </div>
                      <br />
                      <br />
                      <div className="Doctors-Name">
                        {" "}
                        ADDITIONAL EXAMINATION RESULTS (WITH DATES)
                      </div>
                      <div className="Report-Date4">
                        <div
                          dangerouslySetInnerHTML={{ __html: selected?.result }}
                        ></div>
                      </div>
                      <div className="Doctors-Name">PRE- MEDICATIONS</div>
                      <div className="Report-Date4">
                        <div
                          dangerouslySetInnerHTML={{ __html: selected?.pre_medications }}
                        ></div>
                      </div>
                    
                      <br />
                      <br />
                      <div className="Doctors-Name">TRANSFUSION</div>
                      <div className="Report40">{selected?.if_transfussion}</div>
                      <div className="Report30">
                        Quantity :{" "}
                        <span className="Report40">{selected?.if_quantity}</span>
                      </div>
                      <br />
                      <br />
                      <div className="Doctors-Name">FINAL CONCLUSION(AVEC DATE)</div>
                      <div className="Report-Date4">
                        <div
                          dangerouslySetInnerHTML={{ __html: selected?.final_conclution }}
                        ></div>
                      </div>
                      <br />
                      <br />


               




                      <br />
                      {selected?.url?.length ? (
                        selected?.fileType === "application/pdf" ? (
                          <div className="pdf-div">
                            <Viewer
                              fileUrl={selected?.url}
                              plugins={[defaultLayoutPluginInstance]}
                            />
                          </div>
                        ) : (
                          <div>
                            <img
                              width={900}
                              height={1000}
                              src={selected?.url}
                            />
                          </div>
                        )
                      ) : null}
                    </div>
                  </div>
                </div>
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default ViewAnaModal;
