import { React, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { getUser } from "../../../../services/AuthService";
import { updatePatientClinicalNote } from "../../../../services/PatientClinicalNoteService";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import { fetchPreOps } from "../../../../features/patients/preOpSlice";
import RichTextEditor from "react-rte"; // Copy Editor

function ViewPreOpNoteDetailsModal({ show, onClose, selected }) {
  const [value, setValue] = useState(RichTextEditor.createEmptyValue()); // Copy Editor

  let { id } = useParams();
  const dispatch = useDispatch();
  const user = getUser();
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);

  const [viewPreOpNoteDetailsShow, setViewPreOpNoteDetailsShow] =
    useState(false);
  const handleViewPreOpNoteDetails = () => setViewPreOpNoteDetailsShow(false);

  const showDate = (passDate) => {
    return moment(passDate).format("DD-MM-YYYY");
  };

  const handleUpdate = () => {
    setLoading(true);
    const data = {
      id: selected?._id,
      doc_note: comment,
      doctor: user?.full_name,
    };
    console.log(data);
    updatePatientClinicalNote(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(fetchPreOps({ patientId: id, type: "pre Op" }));
          setComment("");
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  {
    /* Copy Editor */
  }
  const onChange = (value) => {
    setValue(value);
    value.toString("html");
    setComment(value.toString("html"));
  };
  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera-Modal-Container">
                <br />
                <div className="Report-Display-Heading">Operative Report</div>
                <div className="Report-Tittle">Operative Report Display</div>
                <div className="Solera-Modal-Container">
                  <div className="Note-Modal-Cont">
                    <div>
                      <div className="Doctors-Name">
                        Doctors's name : &nbsp;
                        <span>{selected?.user?.full_name}</span>
                      </div>
                      <div className="Report-Date">
                        Report Date: &nbsp;
                        <span>{showDate(selected?.created_at)}</span>
                      </div>
                      <div className="Report-Date">
                        Report Number: &nbsp;<span>{selected?.code}</span>
                      </div>
                    </div>
                    <div className="Report-Display">Operative Report</div>
                    <div className="Report-Display-Note">
                      {/* {selected?.note} */}

                      <div className="Report-Date4">
                        <div
                          dangerouslySetInnerHTML={{ __html: selected?.note }}
                        ></div>
                      </div>
                    </div>
                    {/* <div className="Doctors-Name">
                        Commented by :&nbsp;<span>{selected?.doctor}</span>
                      </div> */}
                    <div className="Doctors-Name">DIAGNOSTIC OPERATION</div>
                    <div className="Report30">
                      Anatomical location :{" "}
                      <span className="Report40">
                        {" "}
                        {selected?.is_anatomical_location}
                      </span>
                    </div>
                    <div className="Report30">
                      Cote : <span className="Report40">{selected?.is_cote}</span>
                    </div>
                    <div className="Report30">
                      Diagnostic details :{" "}
                      <span className="Report40">
                        {" "}
                        {selected?.is_diagnostic_details}
                      </span>
                    </div>
                    <div className="Report30">
                      Operation :{" "}
                      <span className="Report40">{selected?.is_operations}</span>
                    </div>
                    <div className="Report30">
                      Date : <span className="Report40"> {showDate(selected?.is_dates)}</span>
                    </div>
                    <div className="Report30">
                      Cote : <span className="Report40"> {selected?.is_cote2}</span>
                    </div>
                    <div className="Report30">
                      Operation Details :{" "}
                      <span className="Report40">
                        {" "}
                        {selected?.is_operation_details}
                      </span>
                    </div>
                    <div className="Report30">
                      Principal Operator :{" "}
                      <span className="Report40">
                        {" "}
                        {selected?.is_principal_operator}
                      </span>
                    </div>
                    <div className="Report30">
                      Assistant :{" "}
                      <span className="Report40"> {selected?.is_assistant}</span>
                    </div>
                    <div className="Report30">
                      Instrumentalist :{" "}
                      <span className="Report40"> {selected?.is_instrumentalist}</span>
                    </div>
                    <div className="Report30">
                      Anesthetist :{" "}
                      <span className="Report40"> {selected?.is_anesthetist2}</span>
                    </div>
                    <div className="Report30">
                      Door up :{" "}
                      <span className="Report40"> {selected?.is_doorup}</span>
                    </div>
                    <div className="Report30">
                      fin up : <span className="Report40"> {selected?.is_finup}</span>
                    </div>
                    <br /> <br />
                    <div className="Doctors-Name">
                      DESCRIPTION OF THE DOCTOR INTERVENTION
                    </div>
                    <div className="Report30">
                      <span className="Report40">
                        {" "}
                        Description of the intervention Display
                      </span>
                    </div>
                    <br /> <br />
                    <div className="Report30">
                      Post operative :{" "}
                      <span className="Report40"> {selected?.is_post_operative}</span>
                    </div>
                    <div className="Report30">
                      Patient positioning :{" "}
                      <span className="Report40">
                        {" "}
                        {selected?.is_patient_positioning}
                      </span>
                    </div>
                    <div className="Report30">
                      Private surveillance :{" "}
                      <span className="Report40">
                        {" "}
                        {selected?.is_private_surveillance}
                      </span>
                    </div>
                    <div className="Report30">
                      First dressing :{" "}
                      <span className="Report40"> {selected?.is_first_dressing}</span>
                    </div>
                    <div className="Report30">
                      Date of ablation if necessary :{" "}
                      <span className="Report40">
                        {" "}
                        {showDate(selected?.is_son)}
                      </span>
                    </div>
                    <div className="Report30">
                      Change of plaster :{" "}
                      <span className="Report40">
                        {" "}
                        {selected?.is_changeof_plaster}
                      </span>
                    </div>
                    <div className="Report30">
                      Possible departure from the hospital on :{" "}
                      <span className="Report40">
                        {" "}
                        {selected?.is_possible}
                      </span>
                    </div>
                    <div className="Report30">
                      Perceived tracking :{" "}
                      <span className="Report40">
                        {" "}
                        {selected?.is_perceived}
                      </span>
                    </div>
                    <div className="Report30">
                      next consultation :{" "}
                      <span className="Report40">
                        {" "}
                        {selected?.is_next}
                      </span>
                    </div>
                    <div className="Report30">
                      Diagnostic details :{" "}
                      <span className="Report40">
                      {selected?.is_diagnostic}
                      </span>
                    </div>
                    <div>
                      <br />
                      {/* <div className="Report-Display">Comment</div> */}
                      {/* <div className="Report-Display-Note">
                      {selected?.doc_note}
                    </div> */}
                      {/* 
                      <div className="Report-Date4">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: selected?.doc_note,
                          }}
                        ></div>
                      </div> */}
                      {/* <br />
                      <div className="Doctors-Name">
                        Commented by :&nbsp;<span>{selected?.doctor}</span>
                      </div>
                      {selected?.doc_note ? (
                        <div className="Report-Date">
                          Date :&nbsp;
                          <span>{showDate(selected?.created_at)}</span>
                        </div>
                      ) : null}
                      <br /> */}
                    </div>
                    <br />
                    {/* <div className="FormSect-Contianer">
                      <div className="Text-Result-Display">
                        Doctor's Comment
                      </div>{" "}
                      <br />
                      <div className="col">
                        <RichTextEditor
                          className="editor"
                          value={value}
                          onChange={onChange}
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
                {/* {
                loading ? (
                  <Bars
                    heigth="30"
                    width="30"
                    color="gray"
                    ariaLabel="loading-indicator"
                  />
                ) : (
                  <button
                    disabled={comment?.length < 1}
                    className="Add-Patient-Report"
                    onClick={handleUpdate}
                  >
                    Submit Doctor's Comment
                  </button>
                ) 
              } */}
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default ViewPreOpNoteDetailsModal;
