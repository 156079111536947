import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { patientClinicalDischargesCount } from '../../../services/PatientClinicalDischargeService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchPatientClinicalDischargesCount = createAsyncThunk('fetchPatientClinicalDischargesCount', (initData) => {
    return patientClinicalDischargesCount(initData)
    .then((response) =>  response.data.data)
})


const patientClinicalDischargesCountSlice = createSlice({
    name: 'patientClinicalDischargesCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientClinicalDischargesCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPatientClinicalDischargesCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default patientClinicalDischargesCountSlice.reducer 