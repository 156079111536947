import { React, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import { getUser } from "../../../../services/AuthService";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import moment from "moment";
import { updatePatientClinicalNote } from "../../../../services/PatientClinicalNoteService";
import { fetchPostOps } from "../../../../features/patients/postOpSlice";

import RichTextEditor from "react-rte"; // Copy Editor

function ViewPostOpModal({ show, onClose, selected }) {
  const [value, setValue] = useState(RichTextEditor.createEmptyValue()); // Copy Editor

  let { id } = useParams();
  const dispatch = useDispatch();
  const user = getUser();
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);

  const [viewPreOpNoteDetailsShow, setViewPreOpNoteDetailsShow] =
    useState(false);
  const handleViewPreOpNoteDetails = () => setViewPreOpNoteDetailsShow(false);

  const showDate = (passDate) => {
    return moment(passDate).format("DD-MM-YYYY");
  };

  const handleUpdate = () => {
    setLoading(true);
    const data = {
      id: selected?._id,
      doc_note: comment,
      doctor: user?.full_name,
    };
    console.log(data);
    updatePatientClinicalNote(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(fetchPostOps({ patientId: id, type: "post Op" }));
          setComment("");
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  {
    /* Copy Editor */
  }
  const onChange = (value) => {
    setValue(value);
    value.toString("html");
    setComment(value.toString("html"));
  };

  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera-Modal-Container">
                <br />
                <div className="Report-Display-Heading">
                  {" "}
                  PRE OPERATIVE PREPERATION
                </div>
                <div className="Report-Tittle">
                  Pre Operative Preperation Display
                </div>
                <div className="Solera-Modal-Container">
                  <div className="Note-Modal-Cont">
                    <div>
                      <div className="Doctors-Name">
                        Doctors's name : &nbsp;
                        <span>{selected?.user?.full_name}</span>
                      </div>
                      <div className="Report-Date">
                        Report Date: &nbsp;
                        <span>{showDate(selected?.created_at)}</span>
                      </div>
                      <div className="Report-Date">
                        Report Number: &nbsp;<span>{selected?.code}</span>
                      </div>
                    </div>
                    <br />
                    <div className="Report-Display">
                      Pre Operative Preperation
                    </div>
                    <br />
                    <div className="Doctors-Name">TO DO </div>
                    <div className="Report30">
                      To do 
                      <span className="Report40"> {selected?.is_b}</span>
                    </div>
                    <div className="Report30">
                      Time : <span className="Report40"> {selected?.is_time1}</span>
                    </div>
                    <div className="Report30">
                      Initial :{" "}
                      <span className="Report40"> {selected?.is_initial1}</span>
                    </div>
                    <br />
                    <div className="Report30">
                      Body wash :
                      <span className="Report40"> {selected?.is_bodywash}</span>
                    </div>
                    <div className="Report30">
                      Time : <span className="Report40"> {selected?.is_time2}</span>
                    </div>
                    <div className="Report30">
                      Initial :{" "}
                      <span className="Report40"> {selected?.is_initial2}</span>
                    </div>
                    <br />
                    <div className="Report30">
                      Urine : <span className="Report40">{selected?.is_urine}</span>
                    </div>
                    <div className="Report30">
                      Time : <span className="Report40"> {selected?.is_time3}</span>
                    </div>
                    <div className="Report30">
                      Initial :{" "}
                      <span className="Report40"> {selected?.is_initial3}</span>
                    </div>
                    <br />
                    <div className="Report30">
                      Oral Disinfection :{" "}
                      <span className="Report40">
                        {" "}
                        {selected?.is_oral}
                      </span>
                    </div>
                    <div className="Report30">
                      Time : <span className="Report40"> {selected?.is_time4}</span>
                    </div>
                    <div className="Report30">
                      Initial :{" "}
                      <span className="Report40">{selected?.is_initial4}</span>
                    </div>
                    <br />
                    <div className="Report30">
                      VITAL SIGN BP :{" "}
                      <span className="Report40"> {selected?.is_vital}</span>
                    </div>
                    <div className="Report30">
                      Time : <span className="Report40"> {selected?.is_time5}</span>
                    </div>
                    <div className="Report30">
                      Initial :{" "}
                      <span className="Report40"> {selected?.is_initial5}</span>
                    </div>
                    <br />
                    <div className="Report30">
                      PULSE : <span className="Report40"> {selected?.is_pulse}</span>
                    </div>
                    <div className="Report30">
                      Time : <span className="Report40"> {selected?.is_time6}</span>
                    </div>
                    <div className="Report30">
                      Initial :{" "}
                      <span className="Report40"> {selected?.is_initial6}</span>
                    </div>
                    <br />
                    <div className="Report30">
                      TEMPERATURE :{" "}
                      <span className="Report40"> {selected?.is_temp}</span>
                    </div>
                    <div className="Report30">
                      Time : <span className="Report40"> {selected?.is_time7}</span>
                    </div>
                    <div className="Report30">
                      Initial :{" "}
                      <span className="Report40"> {selected?.is_initial7}</span>
                    </div>
                    <br />
                    <div className="Report30">
                      RESP RATE :{" "}
                      <span className="Report40"> {selected?.is_respRate}</span>
                    </div>
                    <div className="Report30">
                      Time : <span className="Report40"> {selected?.is_time8}</span>
                    </div>
                    <div className="Report30">
                      Initial :{" "}
                      <span className="Report40"> {selected?.is_initial8}</span>
                    </div>
                    <br />
                    <div className="Report30">
                      IVF ACCESS :{" "}
                      <span className="Report40"> {selected?.is_ivf_access}</span>
                    </div>
                    <div className="Report30">
                      Time : <span className="Report40"> {selected?.is_time9}</span>
                    </div>
                    <div className="Report30">
                      Initial :{" "}
                      <span className="Report40"> {selected?.is_initial9}</span>
                    </div>
                    <br />
                    <div className="Report30">
                      Body wash :
                      <span className="Report40"> {selected?.is_premedication}</span>
                    </div>
                    <div className="Report30">
                      Time : <span className="Report40"> {selected?.is_time0}</span>
                    </div>
                    <div className="Report30">
                      Initial :
                      <span className="Report40"> {selected?.is_initial0}</span>
                    </div>
                    <br />
                    
                    <br /> <br />
                    <div className="Doctors-Name">Remark</div>
                    <div className="Report40">
                    <div
                        dangerouslySetInnerHTML={{ __html: selected?.note }}
                      ></div>
                    </div>
                    <br /> <br />
                    {/* <div className="Report-Display-Note">
                      {selected?.note}
                    </div> */}
                    {/* <div className="Report-Date4">
                      <div
                        dangerouslySetInnerHTML={{ __html: selected?.note }}
                      ></div>
                    </div> */}
                    <br />
                    {/* 
                    <div>
                      <br />
                      <div className="Report-Display">Comment</div>
                      <div className="Report-Display-Note">
                        {selected?.doc_note}
                      </div>

                      <div className="Report-Date4">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: selected?.doc_note,
                          }}
                        ></div>
                      </div>
                      <br />
                      <div className="Doctors-Name">
                        Commented by :&nbsp;<span>{selected?.doctor}</span>
                      </div>
                      {selected?.doc_note ? (
                        <div className="Report-Date">
                          Date :&nbsp;
                          <span>{showDate(selected?.created_at)}</span>
                        </div>
                      ) : null}
                      <br />
                    </div> */}
                    <br />
                    {/* <div className="FormSect-Contianer">
                      <div className="Text-Result-Display">
                        Doctor's Comment
                      </div>{" "}
                      <br />
                      <div className="col">
                        <RichTextEditor
                          className="editor"
                          value={value}
                          onChange={onChange}
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
                {/* {loading ? (
                  <Bars
                    heigth="50"
                    width="50"
                    color="gray"
                    ariaLabel="loading-indicator"
                  />
                ) : (
                  <button
                    disabled={comment?.length < 1}
                    className="Add-Patient-Report"
                    onClick={handleUpdate}
                  >
                    Submit Doctor's Comment
                  </button>
                )} */}
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default ViewPostOpModal;
