import { React, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import "../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import MenuItem from "@mui/material/MenuItem";
import { getUser } from "../../services/AuthService";
import { addUser } from "../../services/UserService";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import { fetchUsers } from "../../features/admins/usersSlice";
import { useDispatch } from "react-redux";

function AdminAccountModal({ show, onClose }) {
  const dispatch = useDispatch()
  const user = getUser();

  const [loading, setLoading] = useState(false);

  const [otherName, setOtherName] = useState('');
  const [errOtherName, setErrOtherName] = useState("");
  const [lastName, setLastName] = useState('');
  const [errlastName, setErrLastName] = useState("");
  const [email, setEmail] = useState('');
  const [errEmail, setErrEmail] = useState("");
  const [phone, setPhone] = useState('');
  const [errPhone, setErrPhone] = useState("");
  const [password, setPassword] = useState('');
  const [errPassword, setErrPassword] = useState("");
  const [confrimPwd, setConfrimPwd] = useState('');
  const [errConfrimPwd, setErrConfrimPwd] = useState("");
  const [role, setRole] = useState('');
  const [errRole, setErrRole] = useState("");

  const [addAccountShow, setAddAccountShow] = useState(false);
  const handleAddAccount = () => setAddAccountShow(false);


  const handleAdd = () => {
    if (!otherName) {
      setErrOtherName("First name is required");
      return;
    }
    if (!lastName) {
      setErrLastName("Last name is required");
      return;
    }
    if (!role) {
      setErrRole("Role is required");
      return;
    }
    if (!password) {
      setErrPassword("Password is required");
      return;
    }

    if (password !== confrimPwd) {
      setErrConfrimPwd("Password does not match");
      return;
    }

    if (!email) {
      setErrEmail("Email is required");
      return;
    }

    if (!phone) {
      setErrPhone("Phone is required");
      return;
    }

    setLoading(true);
    const data = {
      user: user?._id,
      first_name: otherName,
      last_name: lastName,
      password: password,
      email: email,
      phone: phone,
      role: role,
    };
    console.log(data);
    addUser(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          setAddAccountShow(false);
          dispatch(fetchUsers({}))
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  const occupation = [
    {
      value: "sys-admin",
      label: "Super Admin",
    },
    {
      value: "sys-doctor",
      label: "Doctor",
    },
    {
      value: "sys-nurse",
      label: "Nurse",
    },
    {
      value: "sys-pharmacy",
      label: "Pharmacist",
    },
    {
      value: "sys-radio",
      label: "Radiographer",
    },
    {
      value: "sys-lap",
      label: "Lab Scientist",
    },
    {
      value: "sys-store",
      label: "Store",
    },
    {
      value: "sys-matron",
      label: "Matron",
    },
    {
      value: "sys-social",
      label: "Social Worker",
    },
    {
      value: "sys-clinicalsychologist",
      label: "Clinical phychologist",
    },


    {
      value: "sys-dietician",
      label: "Dietician",
    },
    {
      value: "sys-physiotherapist",
      label: "Physiotherapist",
    },
    {
      value: "sys-dentist",
      label: "Dentist",
    },
    
    {
      value: "sys-anaesthetist",
      label: "Anaesthetist",
    }

  ];

  return (
    <div>
      {" "}
      <div>
        <Modal
          size="xl"
          show={show}
          onHide={onClose}
          backdrop="static"
          aria-labelledby="example-modal-sizes-title-lg"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="Solera__ModalHeadings">
              <MdClose className="Solera__Close" onClick={onClose} />
            </div>

            <div className="Solera__ModalHeadings"></div>
            <div className="Solera-Modal-Container">
              <div className="Solera__Headings">
                <div className="Log__Cont">
                  <img
                    src="/assets/lg.png"
                    alt=""
                    className="Log__Img__Modal"
                  />
                </div>
                <div className="Solera__Headings__Note">
                  <div className="Solera-Settings-Header">
                    Create Admin Account
                  </div>
                  <div className="Solera-Settings-Note">
                    Create your admin account and assign roles.
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="FormSect-Contianer">
                <div className="FormSect">Admin account Details</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="First Name"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={otherName}
                        onChange={(event) => {
                          if(event.target.value.length){
                            setOtherName("")
                          }else{
                            setOtherName("Name is required")
                          }
                          setOtherName(event.target.value)
                        }}
                      />
                      {(errOtherName) && <div className='input_error'>{errOtherName}</div>}
                      
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Last name"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={lastName}
                        onChange={(event) => {
                          if(event.target.value.length){
                            setErrLastName("")
                          }else{
                            setErrLastName("Last name is required")
                          }
                          setLastName(event.target.value)
                        }}
                      />
                      {(errlastName) && <div className='input_error'>{errlastName}</div>}
                      
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <div>
                        <TextField
                          fullWidth
                          id="outlined-select-currency"
                          select
                          label="Admin Type"
                          defaultValue="Admin Type"
                          value={role}
                          onChange={(event) => {
                            if(event.target.value.length){
                              setErrRole("")
                            }else{
                              setErrRole("Role is required")
                            }
                            setRole(event.target.value)
                          }}
                        >
                          {occupation?.map((option, index) => (
                            <MenuItem key={index} value={option.value || ""}>
                              {option.label}
                            </MenuItem>
                          ))}
                          {(errRole) && <div className='input_error'>{errRole}</div>}
                        </TextField>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Phone number"
                        id="fullWidth"
                        type="number"
                        className="Solera-Login-Input mb-3"
                        value={phone}
                        onChange={(event) => {
                          if(event.target.value.length){
                            setErrPhone("")
                          }else{
                            setErrPhone("Phone is required")
                          }
                          setPhone(event.target.value)
                        }}
                      />
                      {(errPhone) && <div className='input_error'>{errPhone}</div>}
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Email"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        value={email}
                        onChange={(event) => {
                          if(event.target.value.length){
                            setErrEmail("")
                          }else{
                            setErrEmail("Email is required")
                          }
                          setEmail(event.target.value)
                        }}
                      />
                      {(errEmail) && <div className='input_error'>{errEmail}</div>}
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Password"
                        id="fullWidth"
                        type="password"
                        className="Solera-Login-Input mb-3"
                        value={password}
                        onChange={(event) => {
                          if(event.target.value.length){
                            setErrPassword("")
                          }else{
                            setErrPassword("Password is required")
                          }
                          setPassword(event.target.value)
                        }}
                      />
                      {(errPassword) && <div className='input_error'>{errPassword}</div>}
                    
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Confrim Password"
                        id="fullWidth"
                        type="password"
                        className="Solera-Login-Input mb-3"
                        value={confrimPwd}
                        onChange={(event) => {
                          if(event.target.value.length){
                            setErrConfrimPwd("")
                          }else{
                            setErrConfrimPwd("Password is required")
                          }
                          setConfrimPwd(event.target.value)
                        }}
                      />
                      {(errConfrimPwd) && <div className='input_error'>{errConfrimPwd}</div>}
                    
                    </div>
                  </div>
                </div>
              </div>
              {
                loading ? (
                  <Bars
                    heigth="30"
                    width="30"
                    color="gray"
                    ariaLabel="loading-indicator"
                  />
                ) : (
                  <button className="Add-Button" onClick={handleAdd}>
                    Create Admin
                  </button>
                ) 
              }
        
              <br /> <br /> <br />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default AdminAccountModal;
