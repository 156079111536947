import { React, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Bars } from "react-loader-spinner";
import Form from "react-bootstrap/Form";
import RichTextEditor from "react-rte"; // Copy Editor
import Swal from "sweetalert2";
import { addPatientIntraoperative } from "../../../services/PatientIntraoperativeService";
import { toast } from "react-toastify";
import { fetchPatientIntraoperatives } from "../../../features/patients/patientIntraoperativeSlice";
import { fetchPatientIntraoperativesCount } from "../../../features/patients/counts/patientIntraoperativesCountSlice";
import { getUser } from "../../../services/AuthService";

function SheetModal({ show, onClose }) {
    let { id } = useParams();
    const user = getUser();
    const dispatch = useDispatch();
  //   const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState(RichTextEditor.createEmptyValue()); // Copy Editor
  const [value2, setValue2] = useState(RichTextEditor.createEmptyValue()); // Copy Editor
  const [value3, setValue3] = useState(RichTextEditor.createEmptyValue()); // Copy Editor
  const [value4, setValue4] = useState(RichTextEditor.createEmptyValue()); // Copy Editor
  const [value5, setValue5] = useState(RichTextEditor.createEmptyValue()); // Copy Editor

  const onChange = (value) => {
    setValue(value);
    value.toString("html");
    SetClinicalAssessment(value.toString("html"));
  };

  const onChange2 = (value) => {
    setValue2(value);
    value.toString("html");
    SetMedication(value.toString("html"));
  };

  const onChange3 = (value) => {
    setValue3(value);
    value.toString("html");
    SetNotes(value.toString("html"));
  };

  const onChange4 = (value) => {
    setValue4(value);
    value.toString("html");
    SetLineInsertions(value.toString("html"));
  };

  const onChange5 = (value) => {
    setValue5(value);
    value.toString("html");
    SetSpecificInstructions(value.toString("html"));
  };

 

  const handleOnCheck = (e, setData, index) => {
    const check = e.target.checked;
    if (check) {
      setData((prevArray) => [...prevArray, index]);
    } else {
      setData((oldValues) => {
        return oldValues.filter((item) => item !== index);
      });
    }
  };



  const [clinicalAssessment, SetClinicalAssessment] = useState('');
  const [medication, SetMedication] = useState('');
  const [notes, SetNotes] = useState('');
  const [lineInsertions, SetLineInsertions] = useState('');
  const [specificInstructions, SetSpecificInstructions] = useState('');
  // const [referrals, setReferrals] = useState('');

  const [isPreMedicationDrugs, setIsPreMedicationDrugs] = useState();
  const [isSpO2onAir, setIsSpO2onAir] = useState();
  const [isWT, setIsWT] = useState();
  const [isHT, setIsHT] = useState();
  const [isBMI, setIsBMI] = useState();
  const [isBP, setIsBP] = useState();
  const [isInvestigation, setIsInvestigation] = useState();
  const [isHb, setIsHb] = useState();
  const [isWCC, setIsWCC] = useState();
  const [isPLT, setIsPLT] = useState();
  const [isGS, setIsGS] = useState();
  const [isXMatch, setIsXMatch] = useState();
  const [isNa, setIsNa] = useState();
  const [isK, setIsK] = useState();
  const [isUr, setIsUr] = useState();
  const [isCr, setIsCr] = useState();
  const [isSickle, setIsSickle] = useState();
  const [isPTT, setIsPTT] = useState();
  const [isINR, setIsINR] = useState();
  const [isFEV, setIsFEV] = useState();
  const [isIsFVC, setIsFVC] = useState();
  const [isOthers1, setIsOthers1] = useState();
  const [isAnaesthetist, setIsAnaesthetist] = useState();
  const [isGrade, setIsGrade] = useState();
  const [isAnaesthetist2, setIsAnaesthetist2] = useState();
  const [isGrade2, setIsGrade2] = useState();
  const [isOperation, setIsOperation] = useState();
  const [isDate1, setIsDate1] = useState();
  const [isECG, setIsECG] = useState();
  const [isCXR, setIsCXR] = useState();
  const [isConsultant, setIsConsultant] = useState();
  const [isContacted, setIfContacted] = useState();
  const [isAsaGrading, setIfAsaGrading] = useState();
  const [isAsaGrading2, setIfAsaGrading2] = useState();
  const [isOthers3, setIsOthers3] = useState();
  const [isDate3, setIsDate3] = useState();
  const [isTime1, setIsTime1] = useState();
  const [isSignature, setIsSignature] = useState();
  const [isAllergies, setIsAllergies] = useState();
  const [isAirway, setIsAirway] = useState();
  const [isInsertion, setIsInsertion] = useState();
  const [isLocal, setIsLocal] = useState();
  const [isTime2, setIsTime2] = useState();
  const [isNiddle, setIsNiddle] = useState();
  const [isType2, setIsType2] = useState();
  const [isSize2, setIsSize2] = useState();
  const [isLOR, setIsLOR] = useState();
  const [isCatheir, setIsCatheir] = useState();

  const [isSize22, setIsSize22] = useState();
  const [isLAUsed, setIsLAUsed] = useState();
  const [isTestDose, setIsTestDose] = useState();
  const [isIntrathecal, setIsIntrathecal] = useState();
  const [isOpoid, setIsOpoid] = useState();
  const [isTestedWith, setIsTestedWith] = useState();
  const [isTime5, setIsTime5] = useState();
  const [isThreshold, setIsThreshold] = useState();
  const [isBlockHeight, setIsBlockHeight] = useState();

  const [isL, setIsL] = useState();
  const [isR, setIsR] = useState();
  const [isNA2, setIsNA2] = useState();
  const [isTypesofBlock, setIfTypesofBlock] = useState();
  const [isOthers5, setIsOthers5] = useState();
  const [isDificulties, setIfDificulties2] = useState();
  const [isPosition2, setIfPosition2] = useState();

  const [Techniques, setTechniques] = useState([]);
  const [Complications, setComplications] = useState([]);
  const [Cathether, setCathether] = useState([]);
  const [Postops, setPostops] = useState([]);

  const handleAdd = () => {
    setLoading(true);
    const data = {
      user: user?._id,
      patient: id,
      is_preMedication_drugs: isPreMedicationDrugs,
      is_spO2_onAir: isSpO2onAir,
      is_wT: isWT,
      is_hT: isHT,
      is_bMI: isBMI,
      is_bP: isBP,
      is_investigation: isInvestigation,
      is_hb: isHb,
      is_wCC: isWCC,
      is_pLT: isPLT,
      is_gS: isGS,
      is_xMatch: isXMatch,
      is_na: isNa,
      is_k: isK,
      is_ur: isUr,
      is_cr: isCr,
      is_sickle: isSickle,
      is_pTT: isPTT,
      is_iNR: isINR,
      is_fEV: isFEV,
      is_fVC: isIsFVC,
      is_Others: isOthers1,
      is_anaesthetist: isAnaesthetist,
      is_grade: isGrade,
      is_anaesthetist2: isAnaesthetist2,
      is_grade2: isGrade2,
      is_operation: isOperation,
      is_date1: isDate1,
      is_eCG: isECG,
      is_cXR: isCXR,
      is_consultant: isConsultant,
      is_contacted: isContacted,
      is_asaGrading: isAsaGrading,
      is_asaGrading2: isAsaGrading2,
      is_others3: isOthers3,
      is_date3: isDate3,
      is_time1: isTime1,
      is_signature: isSignature,
      is_allergies: isAllergies,
      is_airway: isAirway,
      is_insertion: isInsertion,
      is_local: isLocal,
      is_time2: isTime2,
      is_niddle: isNiddle,
      is_type2: isType2,
      is_size2: isSize2,
      is_lOR: isLOR,
      is_catheir: isCatheir,
      is_size22: isSize22,
      is_lAUsed: isLAUsed,
      is_testDose: isTestDose,
      is_intrathecal: isIntrathecal,
      is_opoid: isOpoid,
      is_testedWith: isTestedWith,
      is_time5: isTime5,
      is_threshold: isThreshold,
      is_blockHeight: isBlockHeight,
      is_l: isL,
      is_r: isR,
      is_nA2: isNA2,
      is_typesofBlock: isTypesofBlock,
      is_others5: isOthers5,
      is_dificulties: isDificulties,
      is_position2: isPosition2,
      complications:Complications,
      cathether: Cathether,
      postops: Postops,
      specific_instructions: specificInstructions,
      line_insertions: lineInsertions,
      notes: notes,
      medication: medication,
      clinical_assessment: clinicalAssessment,
      techniques: Techniques,
    };
    addPatientIntraoperative(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(
            fetchPatientIntraoperatives({ patientId: id})
          );
          dispatch(fetchPatientIntraoperativesCount({patient: id}))
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });

        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  return (
    <div>
      <div>
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container30">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                    <div className="Solera-Settings-Header">
                      Intraoperative Sheet
                    </div>
                    <div className="Solera-Settings-Note">
                      Intraoperative Sheet
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div>
                  <div className="FormSect">PRE MEDICATION DRUGS</div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <br />
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Pre Medication Drugs"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) =>
                          setIsPreMedicationDrugs(e.target.value)
                        }
                      />
                    </div>

                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="SpO2 on Air"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsSpO2onAir(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="WT-kg"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsWT(e.target.value)}
                      />
                    </div>

                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="HT-m"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsHT(e.target.value)}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="BMI"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsBMI(e.target.value)}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="BP/H.R"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsBP(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Investigation Date"
                        id="fullWidth"
                        type="date"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsInvestigation(e.target.value)}
                      />
                    </div>

                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Hb"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsHb(e.target.value)}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="WCC"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsWCC(e.target.value)}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="PLT"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsPLT(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="G$S"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsGS(e.target.value)}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="X-Match"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsXMatch(e.target.value)}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Na"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsNa(e.target.value)}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="K"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsK(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Ur"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsUr(e.target.value)}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Cr"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsCr(e.target.value)}
                      />
                    </div>

                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Sickle"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsSickle(e.target.value)}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="PTT"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsPTT(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="INR"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsINR(e.target.value)}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="FEV 1"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsFEV(e.target.value)}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="FVC"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsFVC(e.target.value)}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Others"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsOthers1(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Anaesthetist 1"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsAnaesthetist(e.target.value)}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Grade"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsGrade(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Anaesthetist 2"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsAnaesthetist2(e.target.value)}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Grade"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsGrade2(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Operation"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsOperation(e.target.value)}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label=""
                        id="fullWidth"
                        type="date"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsDate1(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="ECG"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsECG(e.target.value)}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="CXR"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsCXR(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <div className="FormSect">Consultant</div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <br />
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Name of Dr"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsConsultant(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="FormSect-Contianer">
                  {" "}
                  <Form>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Contacted"
                            type="radio"
                            id="Contacted"
                            name="Contacted"
                            onChange={(e) => setIfContacted("Contacted")}
                          />
                        </div>

                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Present"
                            type="radio"
                            id="Contacted"
                            name="Contacted"
                            onChange={(e) => setIfContacted("Present")}
                          />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                </div>
                <div>
                  <div className="FormSect">ASA Grading</div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <br />
                <div className="FormSect-Contianer">
                  {" "}
                  <Form>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="1"
                            type="radio"
                            id="AsaGrading"
                            name="AsaGrading"
                            onChange={(e) => setIfAsaGrading("1")}
                          />
                        </div>

                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="2"
                            type="radio"
                            id="AsaGrading"
                            name="AsaGrading"
                            onChange={(e) => setIfAsaGrading("2")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="3"
                            type="radio"
                            id="AsaGrading"
                            name="AsaGrading"
                            onChange={(e) => setIfAsaGrading("3")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="4"
                            type="radio"
                            id="AsaGrading"
                            name="AsaGrading"
                            onChange={(e) => setIfAsaGrading("4")}
                          />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                  <Form>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Immediate"
                            type="radio"
                            id="AsaGrading2"
                            name="AsaGrading2"
                            onChange={(e) => setIfAsaGrading2("Immediate")}
                          />
                        </div>

                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Urgent"
                            type="radio"
                            id="AsaGrading2"
                            name="AsaGrading2"
                            onChange={(e) => setIfAsaGrading2("Urgent")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Expedited"
                            type="radio"
                            id="AsaGrading2"
                            name="AsaGrading2"
                            onChange={(e) => setIfAsaGrading2("Expedited")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Elective"
                            type="radio"
                            id="AsaGrading2"
                            name="AsaGrading2"
                            onChange={(e) => setIfAsaGrading2("Elective")}
                          />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                </div>
                <div>
                  <div className="FormSect">
                    Techniques explained and agreed
                  </div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <br />
                <div className="FormSect-Contianer">
                  {" "}
                  <Form>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="GA"
                            onChange={(e) =>
                              handleOnCheck(e, setTechniques, "GA")
                            }
                          />
                        </div>

                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="IV"
                            onChange={(e) =>
                              handleOnCheck(e, setTechniques, "IV")
                            }
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Gaseous"
                            onChange={(e) =>
                              handleOnCheck(e, setTechniques, "Gaseous")
                            }
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Spinal"
                            onChange={(e) =>
                              handleOnCheck(e, setTechniques, "Spinal")
                            }
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Epidural"
                            onChange={(e) =>
                              handleOnCheck(e, setTechniques, "Epidural")
                            }
                          />
                        </div>

                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Thorasic"
                            onChange={(e) =>
                              handleOnCheck(e, setTechniques, "Thorasic")
                            }
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Lumber"
                            onChange={(e) =>
                              handleOnCheck(e, setTechniques, "Lumber")
                            }
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Caudal"
                            onChange={(e) =>
                              handleOnCheck(e, setTechniques, "Caudal")
                            }
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Nerve block"
                            onChange={(e) =>
                              handleOnCheck(e, setTechniques, "Nerve block")
                            }
                          />
                        </div>

                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Inversive Monitoring"
                            onChange={(e) =>
                              handleOnCheck(
                                e,
                                setTechniques,
                                "Inversive Monitoring"
                              )
                            }
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Morphin PCA"
                            onChange={(e) =>
                              handleOnCheck(e, setTechniques, "Morphin PCA")
                            }
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Analgesic Suppository"
                            onChange={(e) =>
                              handleOnCheck(
                                e,
                                setTechniques,
                                "Analgesic Suppository"
                              )
                            }
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="ITU/HDU"
                            onChange={(e) =>
                              handleOnCheck(e, setTechniques, "ITU/HDU")
                            }
                          />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                </div>
                <div>
                  <div className="FormSect">Complications Discussed</div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <br />
                <div className="FormSect-Contianer">
                  {" "}
                  <Form>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Salt Throat"
                            onChange={(e) =>
                              handleOnCheck(e, setComplications, "Salt Throat")
                            }
                          />
                        </div>

                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Pain"
                            onChange={(e) =>
                              handleOnCheck(e, setComplications, "Pain")
                            }
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="PONV"
                            onChange={(e) =>
                              handleOnCheck(e, setComplications, "PONV")
                            }
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="PDPH"
                            onChange={(e) =>
                              handleOnCheck(e, setComplications, "PDPH")
                            }
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Neuro Damage"
                            onChange={(e) =>
                              handleOnCheck(e, setComplications, "Neuro Damage")
                            }
                          />
                        </div>

                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Dental Damage"
                            onChange={(e) =>
                              handleOnCheck(
                                e,
                                setComplications,
                                "Dental Damage"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Others"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsOthers3(e.target.value)}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Date"
                        id="fullWidth"
                        type="date"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsDate3(e.target.value)}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Time"
                        id="fullWidth"
                        type="Time"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsTime1(e.target.value)}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Name or Signature"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsSignature(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="FormSect-Contianer">
                  <div className="FormSect">Clinical Assessment</div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      {/* <TextField
                        fullWidth
                        id="outlined-multiline-static "
                        label="Make a note for this patient"
                        multiline
                        rows={15}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        // defaultValue="Default Value"
                      />  */}

                      <RichTextEditor
                        className="editor"
                        value={value}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="FormSect-Contianer">
                  <div className="FormSect">Medication</div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      {/* <TextField
                        fullWidth
                        id="outlined-multiline-static "
                        label="Make a note for this patient"
                        multiline
                        rows={15}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        // defaultValue="Default Value"
                      />  */}

                      <RichTextEditor
                        className="editor"
                        value={value2}
                        onChange={onChange2}
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Allergies"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsAllergies(e.target.value)}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Airway"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsAirway(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="FormSect">Regional Anaesthesia</div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <br />

                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Time"
                        id="fullWidth"
                        type="time"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsTime2(e.target.value)}
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Insertion Level"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsInsertion(e.target.value)}
                      />
                    </div>
                    <div className="FormSect-Contianer">
                      <Form>
                        <div className="container-fluid p-0 FormInput">
                          <div className="row g-2">
                            <div className="col-lg-3 CheckSheet">
                              <Form.Check
                                label="Position"
                                type="radio"
                                id="Position"
                                name="Position"
                                onChange={(e) => setIfPosition2("Position")}
                              />
                            </div>
                            <div className="col-lg-3 CheckSheet">
                              <Form.Check
                                label="Sitting"
                                type="radio"
                                id="Position"
                                name="Position"
                                onChange={(e) => setIfPosition2("Sitting")}
                              />
                            </div>
                            <div className="col-lg-3 CheckSheet">
                              <Form.Check
                                label="Lateral"
                                type="radio"
                                id="Position"
                                name="Position"
                                onChange={(e) => setIfPosition2("Lateral")}
                              />
                            </div>
                            <div className="col-lg-3 CheckSheet">
                              <Form.Check
                                label="Awake"
                                type="radio"
                                id="Position"
                                name="Position"
                                onChange={(e) => setIfPosition2("Awake")}
                              />
                            </div>

                            <div className="col-lg-3 CheckSheet">
                              <Form.Check
                                label="Asleep"
                                type="radio"
                                id="Position"
                                name="Position"
                                onChange={(e) => setIfPosition2("Asleep")}
                              />
                            </div>
                            <div className="col-lg-3 CheckSheet">
                              <Form.Check
                                label="Hat"
                                type="radio"
                                id="Position"
                                name="Position"
                                onChange={(e) => setIfPosition2("Hat")}
                              />
                            </div>
                            <div className="col-lg-3 CheckSheet">
                              <Form.Check
                                label="Gloves"
                                type="radio"
                                id="Position"
                                name="Position"
                                onChange={(e) => setIfPosition2("Gloves")}
                              />
                            </div>
                            <div className="col-lg-3 CheckSheet">
                              <Form.Check
                                label="Gown"
                                type="radio"
                                id="Position"
                                name="Position"
                                onChange={(e) => setIfPosition2("Gown")}
                              />
                            </div>
                            <div className="col-lg-3 CheckSheet">
                              <Form.Check
                                label="Mask"
                                type="radio"
                                id="Position"
                                name="Position"
                                onChange={(e) => setIfPosition2("Mask")}
                              />
                            </div>
                            <div className="col-lg-3 CheckSheet">
                              <Form.Check
                                label="Paraesthsia"
                                type="radio"
                                id="Position"
                                name="Position"
                                onChange={(e) => setIfPosition2("Awake")}
                              />
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>

                    <Form>
                      <div className="container-fluid p-0 FormInput">
                        <div className="row g-2">
                          <div className="col-lg">
                            <TextField
                              fullWidth
                              label="Local Infiltration"
                              id="fullWidth"
                              type="text"
                              className="Solera-Login-Input mb-3"
                              onChange={(e) => setIsLocal(e.target.value)}
                            />
                          </div>
                          <div className="col-lg">
                            <TextField
                              fullWidth
                              label="Niddle"
                              id="fullWidth"
                              type="text"
                              className="Solera-Login-Input mb-3"
                              onChange={(e) => setIsNiddle(e.target.value)}
                            />
                          </div>
                          <div className="col-lg">
                            <TextField
                              fullWidth
                              label="Type"
                              id="fullWidth"
                              type="text"
                              className="Solera-Login-Input mb-3"
                              onChange={(e) => setIsType2(e.target.value)}
                            />
                          </div>
                          <div className="col-lg">
                            <TextField
                              fullWidth
                              label="Size"
                              id="fullWidth"
                              type="text"
                              className="Solera-Login-Input mb-3"
                              onChange={(e) => setIsSize2(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </Form>

                    <Form>
                      <div className="container-fluid p-0 FormInput">
                        <div className="row g-2">
                          <div className="col-lg">
                            <TextField
                              fullWidth
                              label="LOR Saline/Air"
                              id="fullWidth"
                              type="text"
                              className="Solera-Login-Input mb-3"
                              onChange={(e) => setIsLOR(e.target.value)}
                            />
                          </div>
                          <div className="col-lg">
                            <TextField
                              fullWidth
                              label="Catheir in space-cm"
                              id="fullWidth"
                              type="text"
                              className="Solera-Login-Input mb-3"
                              onChange={(e) => setIsCatheir(e.target.value)}
                            />
                          </div>
                          <div className="col-lg">
                            <TextField
                              fullWidth
                              label="Size"
                              id="fullWidth"
                              type="text"
                              className="Solera-Login-Input mb-3"
                              onChange={(e) => setIsSize22(e.target.value)}
                            />
                          </div>
                          <div className="col-lg">
                            <TextField
                              fullWidth
                              label="LA Used"
                              id="fullWidth"
                              type="text"
                              className="Solera-Login-Input mb-3"
                              onChange={(e) => setIsLAUsed(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </Form>

                    <Form>
                      <div className="container-fluid p-0 FormInput">
                        <div className="row g-2">
                          <div className="col-lg">
                            <TextField
                              fullWidth
                              label="Test Dose Volume"
                              id="fullWidth"
                              type="text"
                              className="Solera-Login-Input mb-3"
                              onChange={(e) => setIsTestDose(e.target.value)}
                            />
                          </div>
                          <div className="col-lg">
                            <TextField
                              fullWidth
                              label="Intrathecal Volume"
                              id="fullWidth"
                              type="text"
                              className="Solera-Login-Input mb-3"
                              onChange={(e) => setIsIntrathecal(e.target.value)}
                            />
                          </div>
                          <div className="col-lg">
                            <TextField
                              fullWidth
                              label="Opoid"
                              id="fullWidth"
                              type="text"
                              className="Solera-Login-Input mb-3"
                              onChange={(e) => setIsOpoid(e.target.value)}
                            />
                          </div>
                          <div className="col-lg">
                            <TextField
                              fullWidth
                              label="Tested With"
                              id="fullWidth"
                              type="text"
                              className="Solera-Login-Input mb-3"
                              onChange={(e) => setIsTestedWith(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </Form>

                    <Form>
                      <div className="container-fluid p-0 FormInput">
                        <div className="row g-2">
                          <div className="col-lg">
                            <TextField
                              fullWidth
                              label="Time"
                              id="fullWidth"
                              type="time"
                              className="Solera-Login-Input mb-3"
                              onChange={(e) => setIsTime5(e.target.value)}
                            />
                          </div>
                          <div className="col-lg">
                            <TextField
                              fullWidth
                              label="Threshold Used- mAmps"
                              id="fullWidth"
                              type="text"
                              className="Solera-Login-Input mb-3"
                              onChange={(e) => setIsThreshold(e.target.value)}
                            />
                          </div>
                          <div className="col-lg">
                            <TextField
                              fullWidth
                              label="Block Height"
                              id="fullWidth"
                              type="text"
                              className="Solera-Login-Input mb-3"
                              onChange={(e) => setIsBlockHeight(e.target.value)}
                            />
                          </div>
                          <div className="col-lg">
                            <TextField
                              fullWidth
                              label="L"
                              id="fullWidth"
                              type="text"
                              className="Solera-Login-Input mb-3"
                              onChange={(e) => setIsL(e.target.value)}
                            />
                          </div>
                          <div className="col-lg">
                            <TextField
                              fullWidth
                              label="R"
                              id="fullWidth"
                              type="text"
                              className="Solera-Login-Input mb-3"
                              onChange={(e) => setIsR(e.target.value)}
                            />
                          </div>
                          <div className="col-lg">
                            <TextField
                              fullWidth
                              label="NA"
                              id="fullWidth"
                              type="text"
                              className="Solera-Login-Input mb-3"
                              onChange={(e) => setIsNA2(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </Form>

                    <Form>
                      <div className="container-fluid p-0 FormInput">
                        <div className="row g-2">
                          <div className="col-lg-3 CheckSheet">
                            <Form.Check
                              label="Cathether in siu"
                              onChange={(e) =>
                                handleOnCheck(
                                  e,
                                  setCathether,
                                  "Cathether in siu"
                                )
                              }
                            />
                          </div>
                          <div className="col-lg-3 CheckSheet">
                            <Form.Check
                              label="Peripheral Nerve simulator"
                              onChange={(e) =>
                                handleOnCheck(
                                  e,
                                  setCathether,
                                  "Peripheral Nerve simulator"
                                )
                              }
                            />
                          </div>
                          <div className="col-lg-3 CheckSheet">
                            <Form.Check
                              label="Ultrasound Guidance"
                              onChange={(e) =>
                                handleOnCheck(
                                  e,
                                  setCathether,
                                  "Ultrasound Guidance"
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </Form>
                    <div>
                      <div className="FormSect">Types of Block</div>
                      <div className="FormSect-Note"></div>
                    </div>
                    <Form>
                      <div className="container-fluid p-0 FormInput">
                        <div className="row g-2">
                          <div className="col-lg-3 CheckSheet">
                            <Form.Check
                              label="Caudal"
                              type="radio"
                              id="TypesofBlock"
                              name="TypesofBlock"
                              onChange={(e) => setIfTypesofBlock("Caudal")}
                            />
                          </div>
                          <div className="col-lg-3 CheckSheet">
                            <Form.Check
                              label="Epidural"
                              type="radio"
                              id="TypesofBlock"
                              name="TypesofBlock"
                              onChange={(e) => setIfTypesofBlock("Epidural")}
                            />
                          </div>
                          <div className="col-lg-3 CheckSheet">
                            <Form.Check
                              label="Spinal"
                              type="radio"
                              id="TypesofBlock"
                              name="TypesofBlock"
                              onChange={(e) => setIfTypesofBlock("Spinal")}
                            />
                          </div>
                        </div>
                      </div>
                    </Form>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Others"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsOthers5(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div className="FormSect-Contianer">
                  <div className="FormSect">Notes</div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      {/* <TextField
                        fullWidth
                        id="outlined-multiline-static "
                        label="Make a note for this patient"
                        multiline
                        rows={15}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        // defaultValue="Default Value"
                      />  */}

                      <RichTextEditor
                        className="editor"
                        value={value3}
                        onChange={onChange3}
                      />
                    </div>
                  </div>
                </div>
                <div className="FormSect-Contianer">
                  <div className="FormSect">Line Insertion Technique</div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      {/* <TextField
                        fullWidth
                        id="outlined-multiline-static "
                        label="Make a note for this patient"
                        multiline
                        rows={15}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        // defaultValue="Default Value"
                      />  */}

                      <RichTextEditor
                        className="editor"
                        value={value4}
                        onChange={onChange4}
                      />
                    </div>
                  </div>
                </div>
                <div className="FormSect-Contianer">
                  <div className="FormSect">Specific Instructions</div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      {/* <TextField
                        fullWidth
                        id="outlined-multiline-static "
                        label="Make a note for this patient"
                        multiline
                        rows={15}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        // defaultValue="Default Value"
                      />  */}

                      <RichTextEditor
                        className="editor"
                        value={value5}
                        onChange={onChange5}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="FormSect">Dificulties and complications</div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>

                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="Dificulties2"
                          name="Dificulties2"
                          onChange={(e) => setIfDificulties2("Yes")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="Dificulties2"
                          name="Dificulties2"
                          onChange={(e) => setIfDificulties2("No")}
                        />
                      </div>
                    </div>
                  </div>
                </Form>

                <div>
                  <div className="FormSect">Post ops recovery care</div>
                  <div className="FormSect-Note"></div>
                </div>

                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Routing Care"
                          onChange={(e) =>
                            handleOnCheck(e, setPostops, "Routing Care")
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Morphin Infusion"
                          onChange={(e) =>
                            handleOnCheck(e, setPostops, "Morphin Infusion")
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Oxygen Prescribed"
                          onChange={(e) =>
                            handleOnCheck(e, setPostops, "Oxygen Prescribed")
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Epidural Infusion "
                          onChange={(e) =>
                            handleOnCheck(e, setPostops, "Epidural Infusion")
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="IV fluid Prescribed "
                          onChange={(e) =>
                            handleOnCheck(e, setPostops, "IV fluid Prescribed")
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="HDU "
                          onChange={(e) => handleOnCheck(e, setPostops, "HDU")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Analgesia Prescribed "
                          onChange={(e) =>
                            handleOnCheck(e, setPostops, "Analgesia Prescribed")
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Oxygen "
                          onChange={(e) =>
                            handleOnCheck(e, setPostops, "Oxygen")
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="ITU "
                          onChange={(e) => handleOnCheck(e, setPostops, "ITU")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Blood Sugar "
                          onChange={(e) =>
                            handleOnCheck(e, setPostops, "Blood Sugar ")
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Inotropes "
                          onChange={(e) =>
                            handleOnCheck(e, setPostops, "Inotropes")
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="PCA "
                          onChange={(e) => handleOnCheck(e, setPostops, "PCA")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Venifon Out "
                          onChange={(e) =>
                            handleOnCheck(e, setPostops, "Venifon Out")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Form>

                <div className="FormUpload">
                  {loading ? (
                    <Bars
                      heigth="50"
                      width="50"
                      color="gray"
                      ariaLabel="loading-indicator"
                    />
                  ) : (
                    <button className="Add-Button" onClick={handleAdd}>
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default SheetModal;
