import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { patientPreAnaestheticsCount } from '../../../services/PatientPreAnaestheticsService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchPatientPreAnasetheticCount = createAsyncThunk('fetchPatientPreAnasetheticCount', (initData) => {
    return patientPreAnaestheticsCount(initData)
    .then((response) =>  response.data.data)
})


const patientPreAnasetheticCountSlice = createSlice({
    name: 'patientPreAnasetheticCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientPreAnasetheticCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPatientPreAnasetheticCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default patientPreAnasetheticCountSlice.reducer 