import { React, useState } from "react";
import PatientRegistrationDate from "../PatientPage/PatientRegistrationDate";
import Dropdown from "react-bootstrap/Dropdown";
import ViewDrugDetails from "./ViewDrugDetails";
import EditDrugs from "./EditDrugs";
import moment from "moment";

function DrugsList({drug}) {
  const [drugsDetailsShow, setDrugsDetailsShow] = useState(false);
  const handleDrugsDetails = () => setDrugsDetailsShow(false);
  const [selected, setSelected] = useState({})

  const [editDrugsShow, setEditDrugsShow] = useState(false);
  const handleEditDrugs = () => setEditDrugsShow(false);

  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }
  return (
    <div>
      <div className="Patient-Displays-Details2">
        <div className="Phamarcy-C">
          <div className="Wallet-Ref-Heading">Drug Name</div>
          <div className="Wallet-Ref-Result">{drug?.name}</div>
        </div>
        <div className="StaffNumber">
          <div className="Wallet-Ref-Heading">Suplier's name</div>
          <div className="Wallet-Ref-Result">{drug?.supplier_name}</div>
        </div>
        {/* <div className="StaffNumber">
          <div className="Wallet-Ref-Heading">Number</div>
          <div className="Wallet-Ref-Result">{drug?.code}</div>
        </div> */}

        <div className="Position-Cont-P">
          <div className="Wallet-Ref-Heading">Expiry Date</div>
          <div className="Wallet-Ref-Result">{showDate(drug?.expiry_date)}</div>
        </div>
        <div className="Role-Cont-P">
          <div className="Wallet-Ref-Heading">Quantity</div>
          <div className="Wallet-Ref-Result">{drug?.qty}</div>
        </div>
        <div className="Stat-Cont-P">
          <div className="Wallet-Ref-Heading">Status</div>
          <div className="Wallet-Ref-Result">{drug?.status}</div>
        </div>
      

        <div className="Action-Drop-Down">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
              Manage Drugs
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                className="Solera-Patients"
                onClick={() => {setSelected(drug); setDrugsDetailsShow(true)}}
              >
                View Drug Details
              </Dropdown.Item>

              <Dropdown.Item 
                className="Solera-Patients"
                onClick={() => {setSelected(drug); setEditDrugsShow(true)}}
              >
                Update Drugs
              </Dropdown.Item>
              <Dropdown.Item className="Solera-Patients">Remove</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div>
        <ViewDrugDetails
          selected={selected}
          show={drugsDetailsShow}
          onClose={handleDrugsDetails}
          onOpen={() => setDrugsDetailsShow(true)}
        />
         <EditDrugs
          selected={selected}
          show={editDrugsShow}
          onClose={handleEditDrugs}
          onOpen={() => setEditDrugsShow(true)}
        />   
      </div>
    </div>
  );
}

export default DrugsList;
