import { React } from "react";
// import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import moment from "moment";

function ViewSheet({ show, onClose, selected }) {
  const showDate = (passDate) => {
    return moment(passDate).format("DD-MM-YYYY");
  };
  return (
    <div>
      <div>
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                    <div className="Solera-Settings-Header">
                      Intraoperative Sheet Details
                    </div>
                    <div className="Solera-Settings-Note">
                      Intraoperative Sheet
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div>
                  <div className="FormSect">PRE MEDICATION DRUGS</div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <br />
                <div className="FormSect"> Pre Medication Drugs</div>
                <div className="Solera-Settings-Note">
                  {selected?.is_preMedication_drugs}
                </div>
                <br />
                <div className="FormSect">SpO2 on Air</div>
                <div className="Solera-Settings-Note">{selected?.is_spO2_onAir}</div>
                <br />
                <div className="FormSect">HT-m</div>
                <div className="Solera-Settings-Note">{selected?.is_hT}</div>
                <br />
                <div className="FormSect">BMI</div>
                <div className="Solera-Settings-Note">{selected?.is_bMI}</div>
                <br />
                <div className="FormSect">BP/H.R</div>
                <div className="Solera-Settings-Note">{selected?.is_bP}</div>
                <br />
                <div className="FormSect">Investigation Date</div>
                <div className="Solera-Settings-Note">
                {selected?.is_investigation}
                </div>
                <br />
                <div className="FormSect">Hb</div>
                <div className="Solera-Settings-Note">{selected?.is_hb}</div>
                <br />
                <div className="FormSect">WCC</div>
                <div className="Solera-Settings-Note">{selected?.is_wCC}</div>
                <br />
                <div className="FormSect">PLT</div>
                <div className="Solera-Settings-Note">{selected?.is_pLT}</div>
                <br />
                <div className="FormSect">G$S</div>
                <div className="Solera-Settings-Note">{selected?.is_gS}</div>
                <br />
                <div className="FormSect">X-Match</div>
                <div className="Solera-Settings-Note">{selected?.is_xMatch}</div>
                <br />
                <div className="FormSect">Na</div>
                <div className="Solera-Settings-Note">{selected?.is_na}</div>
                <br />
                <div className="FormSect">K</div>
                <div className="Solera-Settings-Note">{selected?.is_k}</div>
                <br />
                <div className="FormSect">Ur</div>
                <div className="Solera-Settings-Note">{selected?.is_ur}</div>
                <br />
                <div className="FormSect">Cr</div>
                <div className="Solera-Settings-Note">{selected?.is_cr}</div>
                <br />
                <div className="FormSect">Sickle</div>
                <div className="Solera-Settings-Note">{selected?.is_sickle}</div>
                <br />
                <div className="FormSect">PTT</div>
                <div className="Solera-Settings-Note">{selected?.is_pTT}</div>
                <br />
                <div className="FormSect">INR</div>
                <div className="Solera-Settings-Note">{selected?.is_iNR}</div>
                <br />
                <div className="FormSect">FEV 1</div>
                <div className="Solera-Settings-Note">{selected?.is_fEV}</div>
                <br />
                <div className="FormSect">FVC</div>
                <div className="Solera-Settings-Note">{selected?.is_fVC}</div>
                <br />
                <div className="FormSect">Others</div>
                <div className="Solera-Settings-Note">{selected?.is_Others}</div>
                <br /> <br />
                <div className="FormSect">Anaesthetist 1</div>
                <div className="Solera-Settings-Note">
                  {selected?.is_anaesthetist}
                </div>
                <div className="Solera-Settings-Note">{selected?.is_grade}</div>
                <br />
                <div className="FormSect">Anaesthetist 2</div>
                <div className="Solera-Settings-Note">
                  {selected?.is_anaesthetist2}
                </div>
                <div className="Solera-Settings-Note">{selected?.is_grade2}</div>
                <br />
                <div className="FormSect">Operation</div>
                <div className="Solera-Settings-Note">{selected?.is_operation}</div>
                <div className="Solera-Settings-Note">{selected?.is_date1}</div>
                <br /> <br />
                <div className="FormSect">ECG</div>
                <div className="Solera-Settings-Note">{selected?.is_eCG}</div>
                <br />
                <div className="FormSect">CXR</div>
                <div className="Solera-Settings-Note">{selected?.is_cXR}</div>
                <br /> <br />
                <div className="FormSect">CONSULTANT</div>
                <br /> <br />
                <div className="FormSect">Name of Dr</div>
                <div className="Solera-Settings-Note">{selected?.is_consultant}</div>
                <div className="Solera-Settings-Note">{selected?.is_contacted}</div>
                <br /> <br />
                <div className="FormSect">ASA GRADING</div>
                <br /> <br />
                <div className="FormSect">Asa Grading</div>
                <div className="Solera-Settings-Note">{selected?.is_asaGrading}</div>
                <br />
                <div className="FormSect">Asa Grading v2</div>
                <div className="Solera-Settings-Note">{selected?.is_asaGrading2}</div>
                <br />
                <div className="FormSect"> Techniques explained and agreed</div>
                {selected?.techniques?.map((item, index) => (
                  <div key={index} className="Solera-Settings-Note">
                    {item}
                  </div>
                ))}
                <br />
                <div className="FormSect">Complications Discussed</div>
                {selected?.complications?.map((item, index) => (
                  <div key={index} className="Solera-Settings-Note">
                    {item}
                  </div>
                ))}
                <div className="Solera-Settings-Note">
                  Others : {selected?.is_others3}
                </div>
                <div className="Solera-Settings-Note">Date : {showDate(selected?.is_date3)}</div>
                <div className="Solera-Settings-Note">Time : {selected?.is_time1}</div>
                <div className="Solera-Settings-Note">
                  Name or Signature : {selected?.is_signature}
                </div>
                <br />
                <div className="FormSect">Clinical Assessment</div>
                <div className="Report-Date4">
                  <div
                    dangerouslySetInnerHTML={{ __html: selected?.clinical_assessment }}
                  ></div>
                </div>
                <br />
                <div className="FormSect">Medication</div>
                <div className="Report-Date4">
                  <div
                    dangerouslySetInnerHTML={{ __html: selected?.medication }}
                  ></div>
                </div>
                <br />
                <div className="FormSect">Allergies</div>
                <div className="Solera-Settings-Note">{selected?.is_allergies}</div>
                <br />
                <div className="FormSect">Airway</div>
                <div className="Solera-Settings-Note">{selected?.is_airway}</div>
                <br />
                <br />
                <div className="FormSect">Regional Anaesthesia</div>
                <div className="Solera-Settings-Note">Time : {selected?.is_time2}</div>
                <div className="Solera-Settings-Note">
                  Insertion Level: {selected?.is_insertion}
                </div>
                <div className="Solera-Settings-Note">Selected display</div>
                <div className="Solera-Settings-Note">
                  Local Infiltration : {selected?.is_local}
                </div>
                <div className="Solera-Settings-Note">
                  Niddle : {selected?.is_niddle}
                </div>
                <div className="Solera-Settings-Note">Type :  {selected?.is_type2}</div>
                <div className="Solera-Settings-Note">Size : {selected?.is_size2}</div>
                <div className="Solera-Settings-Note">
                  LOR Saline/Air : {selected?.is_lOR}
                </div>
                <div className="Solera-Settings-Note">
                  Catheir in space-cm : {selected?.is_catheir}
                </div>
                <div className="Solera-Settings-Note">Size : {selected?.is_size22}</div>
                <div className="Solera-Settings-Note">
                  LA Used : {selected?.is_lAUsed}
                </div>
                <div className="Solera-Settings-Note">
                  Test Dose Volume : {selected?.is_testDose}
                </div>
                <div className="Solera-Settings-Note">
                  Intrathecal Volume :{selected?.is_intrathecal}
                </div>
                <div className="Solera-Settings-Note">
                  Opoid : {selected?.is_opoid}
                </div>
                <div className="Solera-Settings-Note">
                  Tested With : {selected?.is_testedWith}
                </div>
                <div className="Solera-Settings-Note">Time : {selected?.is_time5}</div>
                <div className="Solera-Settings-Note">
                  Threshold Used- mAmps : {selected?.is_threshold}
                </div>
                <div className="Solera-Settings-Note">Time : {selected?.is_opoid}</div>
                <div className="Solera-Settings-Note">
                  Block Height : {selected?.is_blockHeight}
                </div>
                <div className="Solera-Settings-Note">L : {selected?.is_l}</div>
                <div className="Solera-Settings-Note">R : {selected?.is_r}</div>
                <div className="Solera-Settings-Note">NA : {selected?.is_nA2}</div>
                <br />
                <br />
                <div className="Solera-Settings-Note">
                  Multiple Selection display
                </div>
                <br />
                <br />
                <div className="FormSect">Types of Block</div>
                <br />
                <br />
                <div className="Solera-Settings-Note">{selected?.is_typesofBlock}</div>
                <div className="Solera-Settings-Note">
                  Others : {selected?.is_typesofBlock}
                </div>
                <br />
                <br />
                <div className="FormSect">Notes</div>
                <div className="Report-Date4">
                  <div
                    dangerouslySetInnerHTML={{ __html: selected?.note }}
                  ></div>
                </div>
                <br />
                <br />
                <div className="FormSect"> Line Insertion Technique</div>
                <div className="Report-Date4">
                  <div
                    dangerouslySetInnerHTML={{ __html: selected?.line_insertions }}
                  ></div>
                </div>
                
                <br />
                <br />
                <div className="FormSect"> Specific Instructions Technique</div>
                <div className="Report-Date4">
                  <div
                    dangerouslySetInnerHTML={{ __html: selected?.specific_instructions }}
                  ></div>
                </div>
                <br />
                <div className="FormSect"> Dificulties and complications</div>
                <div className="Solera-Settings-Note">{selected?.is_dificulties}</div>
                <br />
                <br />
                <div className="FormSect"> Post ops recovery care</div>
                {selected?.postops?.map((item, index) => (
                  <div key={index} className="Solera-Settings-Note">
                    {item}
                  </div>
                ))}
                
                <br />
                <div className="Solera-Settings-Note">
                  Others: Display Others
                </div>
                <br />
                <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default ViewSheet;
