import { React, useEffect, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import Dropdown from "react-bootstrap/Dropdown";
import AddDs from "./AddDs";
import ViewDsModal from "./ViewDsModal";
import UpdateDs from "./UpdateDs";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatientClinicalDischarge } from "../../../../features/patients/patientClinicalDischargeSlice";
import { fetchPatientClinicalDischargesCount } from "../../../../features/patients/counts/patientClinicalDischargesCountSlice";
import moment from "moment";
import { Bars } from "react-loader-spinner";

function DS() {
  let { id } = useParams();
  const dispatch = useDispatch()
  const {clinicalDischarges, loading } = useSelector((state) => state.patientClinicalDischarge)
  const patientClinicalDischargesCount = useSelector((state) => state.patientClinicalDischargesCount)
  const [selected, setSelected] = useState({})
  const [viewPreOpNoteDetailsShow, setViewPreOpNoteDetailsShow] =
    useState(false);
  const handleViewPreOpNoteDetails = () => setViewPreOpNoteDetailsShow(false);

  const [editPreOpNoteDetailsShow, setEditPreOpNoteDetailsShow] =
    useState(false);
  const handleEditPreOpNoteDetails = () => setEditPreOpNoteDetailsShow(false);

  useEffect(() => {
    if (id) {
      dispatch(fetchPatientClinicalDischarge({patientId: id }))
      dispatch(fetchPatientClinicalDischargesCount({patientId: id }))
    }
  }, [id]);

  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }

  
  return (
    <div>
      <div className="Report-Notes">
        <div className="ReportHeader">
          <div className="Report-Heading">Discharge Summary</div>
          <div className="Report-Count">
            <span>{patientClinicalDischargesCount?.loading ? "Loading..." : patientClinicalDischargesCount?.count}</span>&nbsp;Discharge Summary
          </div>
          <AddDs />
        </div>
      </div>
      <br />

      {loading ? (
            <div className="d-flex justify-content-center">
              <Bars
                heigth="30"
                width="30"
                color="gray"
                ariaLabel="loading-indicator"
              />
            </div>
        ) : null
      }

     {clinicalDischarges?.map((item, index) => (
       <div key={index} className="Patient-Record-Container">
        <div className="Patient-Record-Wrapper">
          <div className="PatientNote-C">
            <div className="Wallet-Ref-Heading">Note Number</div>
            <div className="Wallet-Ref-Result">{item?.code}</div>
          </div>
          <div className="PatientNote-C">
            <div className="Wallet-Ref-Heading">Doctor's Name</div>
            <div className="Wallet-Ref-Result">{item?.user?.full_name}</div>
          </div>
          <div className="PatientNote-C">
            <div className="Wallet-Ref-Heading">Date</div>
            <div className="Wallet-Ref-Result">{showDate(item?.created_at)}</div>
          </div>
          <div className="PatientNote-C">
            <div className="Wallet-Ref-Heading">Status</div>
            <div className="Wallet-Ref-Result">{item?.status}</div>
          </div>
          <div className="Action-Drop-Down">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
                Manage Note
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {setSelected(item); setViewPreOpNoteDetailsShow(true)}}
                  className="Solera-Patients"
                >
                  View Discharge Summary Note
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setEditPreOpNoteDetailsShow(true)}
                  className="Solera-Patients"
                >
                  Update Discharge Summary Note
                </Dropdown.Item>
                <Dropdown.Item
                  // onClick={() => setPatientDetailsShow(true)}
                  className="Solera-Patients"
                >
                  Delete Discharge Summary Note
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
     ))}
      

      <div>
        <ViewDsModal
          selected={selected}
          show={viewPreOpNoteDetailsShow}
          onClose={handleViewPreOpNoteDetails}
          onOpen={() => setViewPreOpNoteDetailsShow(true)}
        />

        <UpdateDs
          show={editPreOpNoteDetailsShow}
          onClose={handleEditPreOpNoteDetails}
          onOpen={() => setEditPreOpNoteDetailsShow(true)}
        />
      </div>
    </div>
  );
}

export default DS