import { React, useState } from "react";
import { Link } from "react-router-dom";
import PatientName from "./PatientName";
import PatientNumber from "./PatientNumber";
import PatientRegistrationDate from "./PatientRegistrationDate";
import PatientAge from "./PatientAge";
import Dropdown from "react-bootstrap/Dropdown";
import PatientDetails from "./PatientDetails";
import "../../SoleraCss/Responsive.css";
import "../../SoleraCss/Solera.css";
import EditPatientDetails from "./PatientRecords/EditPatientDetails";
import Swal from "sweetalert2";
import { updatePatient } from "../../services/PatientService";
import { fetchPatients } from "../../features/patients/patientsSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getSys } from "../../services/AuthService";
import { patientRole } from "../../services/extras";
import UpdatePatientRefFormModal from "./UpdatePatientRefFormModal";
import UpdatePatientRefLetterModal from "./UpdatePatientRefLetterModal";
import UpdatePatientConcentFormModal from "./UpdatePatientConcentFormModal";
import PatientImageDetails from "./PatientImageDetails";
import { fetchPatientCount } from "../../features/patients/patientCountSlice";
import { fetchPatientAdmissionCount } from "../../features/patients/patientAdmissionCountSlice";
import { fetchPatientDischargedCount } from "../../features/patients/patientDischargedCountSlice";
import { fetchPatientQuarantineCount } from "../../features/patients/patientQuarantineCountSlice";
import UpdatePatientReleaseFormModal from "./UpdatePatientReleaseFormModal";
import PatientSummary from "./PatientRecords/PatientSummary/PatientSummary";

function Patient({ patient }) {
  const sys = getSys();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState("");

  const [patientDetailsShow, setPatientDetailsShow] = useState(false);
  const handlePatientDetails = () => setPatientDetailsShow(false);
  const [selected, setSelected] = useState({});

  const [editPatientDetailsShow, setEditPatientDetailsShow] = useState(false);
  const handleEditPatientDetails = () => setEditPatientDetailsShow(false);

  const [editRefFormShow, setEditRefFormShow] = useState(false);
  const handleEditRefFormDetails = () => setEditRefFormShow(false);

  const [patientSummaryShow, setPatientSummaryShow] = useState(false);
  const handlePatientSummary = () => setPatientSummaryShow(false);

  const [editRefLetterShow, setEditRefLetterShow] = useState(false);
  const handleEditRefLetterDetails = () => setEditRefLetterShow(false);

  const [editConcentFormShow, setEditConcentFormShow] = useState(false);
  const handleEditConcentFormDetails = () => setEditConcentFormShow(false);

  const [editReleaseFormShow, setEditReleaseFormShow] = useState(false);
  const handleEditReleaseFormDetails = () => setEditReleaseFormShow(false);

  const [showImage, setShowImage] = useState(false);
  const handleShowImageDetails = () => setShowImage(false);

  const [showImageName, setShowImageName] = useState("");

  const handleAdmission = () => {
    setLoading(true);
    const data = {
      id: patient?._id,
      status: "admitted",
    };

    updatePatient(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          dispatch(fetchPatients({}));
          dispatch(fetchPatientCount({}));
          dispatch(fetchPatientAdmissionCount({ status: "admitted" }));
          dispatch(fetchPatientDischargedCount({ status: "discharged" }));
          dispatch(fetchPatientQuarantineCount({ status: "quarantined" }));
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Admitted",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleDischarge = () => {
    setLoading(true);
    const data = {
      id: patient?._id,
      status: "discharged",
    };

    updatePatient(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          dispatch(fetchPatients({}));
          dispatch(fetchPatientCount({}));
          dispatch(fetchPatientAdmissionCount({ status: "admitted" }));
          dispatch(fetchPatientDischargedCount({ status: "discharged" }));
          dispatch(fetchPatientQuarantineCount({ status: "quarantined" }));
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Discharged",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleQuarantine = () => {
    setLoading(true);
    const data = {
      id: patient?._id,
      status: "quarantined",
    };

    updatePatient(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          dispatch(fetchPatients({}));
          dispatch(fetchPatientCount({}));
          dispatch(fetchPatientAdmissionCount({ status: "admitted" }));
          dispatch(fetchPatientDischargedCount({ status: "discharged" }));
          dispatch(fetchPatientQuarantineCount({ status: "quarantined" }));
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Quarantined",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  // const handleUpdateStatus = (status) => {

  //   setLoading(true);
  //   const data = {
  //     id: selected?._id,
  //     status: status,
  //   };

  //   console.log(data);
  //   updatePatient(data)
  //     .then((response) => {
  //       setLoading(false);
  //       if (response.data.success) {
  //         //closeRef.current.click()
  //         toast.success(
  //           response.data.message
  //             ? response.data.message
  //             : "Successfully Updated"
  //         );
  //         dispatch(fetchPatients({}))
  //         Swal.fire({
  //           customClass: {
  //             title: "title-class",
  //             icon: "icon-class",
  //           },

  //           icon: "success",
  //           title: status,
  //           showConfirmButton: false,
  //           timer: 2000,
  //         });

  //       }
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       console.log(err);
  //     });
  // }

  return (
    <div>
      <div className="Patient-Displays-Details2">
        <div className="PatientNumber-C">
          <div className="Wallet-Ref-Heading">Patient Number</div>
          <div className="Wallet-Ref-Result">
            <PatientNumber pId={patient?.cus_code} />
          </div>
        </div>
        <div className="PatientName-C">
          <div className="Wallet-Ref-Heading">Patient Name</div>
          <div className="Wallet-Ref-Result">
            <PatientName name={patient?.full_name} />
          </div>
        </div>
        <div className="PatientAge-C">
          <div className="Wallet-Ref-Heading">Age</div>
          <div className="Wallet-Ref-Result">
            <PatientAge age={patient?.age} />
          </div>
        </div>
        <div className="Status-Cont-P">
          <div className="Wallet-Ref-Heading">Status </div>
          <div className="Wallet-Ref-Result">{patient?.status}</div>
        </div>

        <div className="Action-Drop-Down">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
              {loading ? "Loading..." : "Manage Patient"}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                to={`/patients/${patient?._id}`}
                state={{ patient }}
              >
                <Link
                  to={`/patients/${patient?._id}`}
                  state={{ patient }}
                  className="Solera-Patients"
                >
                  Patient Record
                </Link>
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => {
                  setSelected(patient);
                  setPatientDetailsShow(true);
                }}
                className="Solera-Patients"
              >
                View Patient Details
              </Dropdown.Item>

              {/* {sys?.role === "sys-admin" || sys?.role === "sys-doctor" ? (
                <Dropdown.Item
                  onClick={() => {
                    setSelected(patient);
                    setPatientSummaryShow(true);
                  }}
                  className="Solera-Patients"
                >
                  Patient Summary
                </Dropdown.Item>
              ) : null} */}

              {sys?.role === "sys-admin" || sys?.role === "sys-social" ? (
                <Dropdown.Item
                  onClick={() => {
                    setSelected(patient);
                    setEditPatientDetailsShow(true);
                  }}
                  className="Solera-Patients"
                >
                  Update Patient
                </Dropdown.Item>
              ) : null}

              {/* {sys?.role === "sys-admin" || sys?.role === "sys-socialworker" ? (
                      
                  ) : null} */}

              {sys?.role === "sys-admin" || sys?.role === "sys-doctor" ? (
                <Dropdown.Item
                  onClick={handleAdmission}
                  className="Solera-Patients"
                >
                  On Admission
                </Dropdown.Item>
              ) : null}

              {sys?.role === "sys-admin" 
              // || sys?.role === "sys-doctor" 
              ? (
                <Dropdown.Item
                  onClick={handleDischarge}
                  className="Solera-Patients"
                >
                  Discharge
                </Dropdown.Item>
              ) : null}

              {sys?.role === "sys-admin" 
              // || sys?.role === "sys-doctor" 
              ? (
                <Dropdown.Item
                  onClick={handleQuarantine}
                  className="Solera-Patients"
                >
                  Quarantine
                </Dropdown.Item>
              ) : null}

              {sys?.role === "sys-admin" ||
              sys?.role === "sys-doctor" ||
              sys?.role === "sys-nurse" ? (
                <Dropdown.Item
                  onClick={() => {
                    setSelected(patient);
                    setEditRefLetterShow(true);
                  }}
                  className="Solera-Patients"
                >
                  Upload LAMA
                </Dropdown.Item>
              ) : null}

              {sys?.role === "sys-admin" ||
              sys?.role === "sys-doctor" ||
              sys?.role === "sys-nurse" ? (
                <Dropdown.Item
                  onClick={() => {
                    setSelected(patient);
                    setEditRefFormShow(true);
                  }}
                  className="Solera-Patients"
                >
                  Upload Referral Form
                </Dropdown.Item>
              ) : null}

              {sys?.role === "sys-admin" ||
              sys?.role === "sys-doctor" ||
              sys?.role === "sys-nurse" ? (
                <Dropdown.Item
                  onClick={() => {
                    setSelected(patient);
                    setEditConcentFormShow(true);
                  }}
                  className="Solera-Patients"
                >
                  Upload Consent Form
                </Dropdown.Item>
              ) : null}

              {sys?.role === "sys-admin" ||
              sys?.role === "sys-doctor" ||
              sys?.role === "sys-nurse" ? (
                <Dropdown.Item
                  onClick={() => {
                    setSelected(patient);
                    setEditReleaseFormShow(true);
                  }}
                  className="Solera-Patients"
                >
                  Upload Release Form
                </Dropdown.Item>
              ) : null}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div>
        <PatientDetails
          selected={selected}
          show={patientDetailsShow}
          onClose={handlePatientDetails}
          onOpen={() => setPatientDetailsShow(true)}
          setImage={setShowImage}
          setImageName={setShowImageName}
        />

        <EditPatientDetails
          selected={selected}
          show={editPatientDetailsShow}
          onClose={handleEditPatientDetails}
          onOpen={() => setEditPatientDetailsShow(true)}
        />

        <UpdatePatientRefFormModal
          named={"Referral Form"}
          selected={selected}
          show={editRefFormShow}
          onClose={handleEditRefFormDetails}
          onOpen={() => setEditRefFormShow(true)}
        />

        <UpdatePatientRefLetterModal
          named={"Referral Letter"}
          selected={selected}
          show={editRefLetterShow}
          onClose={handleEditRefLetterDetails}
          onOpen={() => setEditRefLetterShow(true)}
        />

        <UpdatePatientConcentFormModal
          named={"Concent Form"}
          selected={selected}
          show={editConcentFormShow}
          onClose={handleEditConcentFormDetails}
          onOpen={() => setEditConcentFormShow(true)}
        />

        <UpdatePatientReleaseFormModal
          named={"Release Form"}
          selected={selected}
          show={editReleaseFormShow}
          onClose={handleEditReleaseFormDetails}
          onOpen={() => setEditReleaseFormShow(true)}
        />

        <PatientImageDetails
          named={showImageName}
          selected={selected}
          show={showImage}
          onClose={handleShowImageDetails}
          onOpen={() => setShowImage(true)}
        />
        <PatientSummary
          selected={selected}
          show={patientSummaryShow}
          onClose={handlePatientSummary}
          onOpen={() => setPatientSummaryShow(true)}

          // setImage={setShowImage}
          // setImageName={setShowImageName}
        />
      </div>
    </div>
  );
}

export default Patient;
