import { React, useEffect, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import Dropdown from "react-bootstrap/Dropdown";
import AddAnasetheticNote from "./AddAnasetheticNote";
import ViewAnaModal from "./ViewAnaModal";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Bars } from "react-loader-spinner";
import UploadNote from "./UploadNote";
import UpdateAnaethticModal from "./UpdateAnaethticModal";
import { fetchPatientPreAnaesthetics } from "../../../../features/patients/patientPreAnaestheticSlice";
import { fetchPatientPreAnasetheticCount } from "../../../../features/patients/counts/patientPreAnasetheticCountSlice";

function Anasethetic() {
  let { id } = useParams();
  const dispatch = useDispatch()
  const {preAnaesthetics, loading} = useSelector((state) => state.patientPreAnaesthetics)
  const patientAnasetheticCount = useSelector((state) => state.patientPreAnasetheticCount)

  
  const [selected, setSelected] = useState({});

  const [viewPreOpNoteDetailsShow, setViewPreOpNoteDetailsShow] =
    useState(false);
  const handleViewPreOpNoteDetails = () => setViewPreOpNoteDetailsShow(false);


  const [updateNoteShow, setUpdateNoteShow] =useState(false);
const handleUpdateNote = () => setUpdateNoteShow(false);



  

  const [uploadNotesShow, setUploadNotesShow] =
    useState(false);
  const handleUploadNoteDetails = () => setUploadNotesShow(false);

  useEffect(() => {
    if (id) {
      dispatch(fetchPatientPreAnaesthetics({patientId: id}))
      dispatch(fetchPatientPreAnasetheticCount({patient: id}))

    }
  }, [id]);

  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }
  return (
    <div>
      <div className="Report-Notes">
        <div className="ReportHeader">
          <div className="Report-Heading">Pre-Anaesthetic</div>
          <div className="Report-Count">
            <span>{patientAnasetheticCount?.loading ? "Loading..." : patientAnasetheticCount?.count}</span>&nbsp;Pre-Anaesthetic
          </div>
          <AddAnasetheticNote />
        </div>
      </div>
      <br />
      {loading ? (
            <div className="d-flex justify-content-center">
              <Bars
                heigth="30"
                width="30"
                color="gray"
                ariaLabel="loading-indicator"
              />
            </div>
        ) : null
      }
      {
        preAnaesthetics?.map((item, index) => (
          <div key={index} className="Patient-Record-Container">
            <div className="Patient-Record-Wrapper">
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Note Number</div>
                <div className="Wallet-Ref-Result">{item?.code}</div>
              </div>
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Doctor's Name</div>
                <div className="Wallet-Ref-Result">{item?.user?.full_name}</div>
              </div>
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Date</div>
                <div className="Wallet-Ref-Result">{showDate(item?.created_at)}</div>
              </div>
              <div className="PatientNote-C">
                <div className="Wallet-Ref-Heading">Status</div>
                <div className="Wallet-Ref-Result">{item?.status}</div>
              </div>
              <div className="Action-Drop-Down">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
                    Manage Note
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {setSelected(item); setViewPreOpNoteDetailsShow(true)}}
                      className="Solera-Patients"
                    >
                      View Note
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {setSelected(item); setUpdateNoteShow(true)}}
                      className="Solera-Patients"
                    >
                      Update Note
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {setSelected(item); setUploadNotesShow(true)}}
                      className="Solera-Patients"
                    >
                      Upload Note
                    </Dropdown.Item>
                    <Dropdown.Item
                      // onClick={() => {setSelected(item); setViewPreOpNoteDetailsShow(true)}}
                      className="Solera-Patients"
                    >
                      View uploaded Note
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        ))
      }
      

      <div>
        <ViewAnaModal
          selected={selected}
          show={viewPreOpNoteDetailsShow}
          onClose={handleViewPreOpNoteDetails}
          onOpen={() => setViewPreOpNoteDetailsShow(true)}
        />

        <UploadNote 
          named={'anasethetic'}
          selected={selected}
          show={uploadNotesShow}
          onClose={handleUploadNoteDetails}
          onOpen={() => setUploadNotesShow(true)}
        />
      <UpdateAnaethticModal
          selected={selected}
          show={updateNoteShow}
          onClose={handleUpdateNote}
          onOpen={() => setUpdateNoteShow(true)}
        />

      </div>
    </div>
  );
}

export default Anasethetic;
