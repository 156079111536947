import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientOperativeReports } from '../../services/PatientOperativeReportService'

const initialState = {
    loading: false,
    operativesReports: [],
    isSuccessful: false
}

export const fetchPatientOperativeReport = createAsyncThunk('fetchPatientOperativeReport', (initData) => {
    return listPatientOperativeReports(initData)
    .then((response) => response.data.data)
})


const patientOperativeReportSlice = createSlice({
    name: 'patientOperativeReport',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientOperativeReport.pending, (state) => {
        state.loading = true
        state.isSuccessful = false
       })
       builder.addCase(fetchPatientOperativeReport.fulfilled, (state, action ) => {
        state.loading = false
        state.operativesReports = action.payload.data
        state.isSuccessful = true
       })
       builder.addCase(fetchPatientOperativeReport.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default patientOperativeReportSlice.reducer 