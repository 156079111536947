import axios from '../axiosinstance';

      
export function listPatientOperativeReports(initData){
        return axios.get('/patient-operative-reports', { params: { name: initData?.search, type: initData?.type, patient: initData?.patientId } })
}

export function addPatientOperativeReport(data){
        return axios.post('/patient-operative-reports', data)
}

export function getPatientOperativeReport(initData){
        return axios.get('/patient-operative-reports/'+initData?.id)
}

export function updatePatientOperativeReport(id, initData){
        return axios.patch('/patient-operative-reports/'+id, initData)
}

export function patientOperativeReportCount(search){
        return axios.get('/patient-operative-reports/stat/count', { params: search })
}

