import { React, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import { getUser } from "../../../../services/AuthService";
import { useDispatch } from "react-redux";
import { addPatientTheatre } from "../../../../services/PatientTheatreService";
import { toast } from "react-toastify";
import { fetchPatientAnasethetic } from "../../../../features/patients/patientAnasetheticSlice";
import { Bars } from "react-loader-spinner";
import Form from "react-bootstrap/Form";
import RichTextEditor from "react-rte"; // Copy Editor
import { addPatientPreAnaesthetic } from "../../../../services/PatientPreAnaestheticsService";
import { fetchPatientPreAnaesthetics } from "../../../../features/patients/patientPreAnaestheticSlice";
import { fetchPatientPreAnasetheticCount } from "../../../../features/patients/counts/patientPreAnasetheticCountSlice";

function AnasetheticModal({ show, onClose }) {
  const [value, setValue] = useState(RichTextEditor.createEmptyValue()); // Copy Editor
  const [value2, setValue2] = useState(RichTextEditor.createEmptyValue());
  const [value3, setValue3] = useState(RichTextEditor.createEmptyValue());
  const [value4, setValue4] = useState(RichTextEditor.createEmptyValue());
  const [value5, setValue5] = useState(RichTextEditor.createEmptyValue());
  const [value6, setValue6] = useState(RichTextEditor.createEmptyValue());
  const [value7, setValue7] = useState(RichTextEditor.createEmptyValue());


  const [surgicalAnaesthetic, SetSurgicalAnaesthetic] = useState("");
  const [treaatments, SetTreaatments] = useState("");
  const [examenLabo, SetExamenLabo] = useState("");
  const [aditionalTest, SetAditionalTest] = useState("");
  const [results, SetResults] = useState("");
  const [preMedications, SetPreMedications] = useState("");
  const [finalConclution, SetFinalConclution] = useState("");



  let { id } = useParams();
  const user = getUser();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);



  {
    /* Copy Editor */
  }
  const onChange = (value) => {
    setValue(value);
    value.toString("html");
    SetSurgicalAnaesthetic(value.toString("html"));
  };

  const onChange2 = (value) => {
    setValue2(value);
    value.toString("html");
    SetTreaatments(value.toString("html"));
  };

  /* Copy Editor */

  const onChange3 = (value) => {
    setValue3(value);
    value.toString("html");
    SetExamenLabo(value.toString("html"));
  };

  const onChange4 = (value) => {
    setValue4(value);
    value.toString("html");
    SetAditionalTest(value.toString("html"));
  };
  const onChange5 = (value) => {
    setValue5(value);
    value.toString("html");
    SetResults(value.toString("html"));
  };

  const onChange6 = (value) => {
    setValue6(value);
    value.toString("html");
    SetPreMedications(value.toString("html"));
  };
  const onChange7 = (value) => {
    setValue7(value);
    value.toString("html");
    SetFinalConclution(value.toString("html"));
  };

  const [ifRespirory, setRespirory] = useState();
  const [ifCardio, setCardio] = useState();
  const [ifHepato, setHepato] = useState();
  const [ifNeuro, setNeuro] = useState();
  const [ifRenal, setRenal] = useState();
  const [ifAutres, setAutres] = useState();
  const [ifExaminations, setExaminations] = useState();
  const [ifAusculation, setIsAusculation] = useState();
  const [ifCardiac, setCardiac] = useState();
  const [ifVenous, setVenous] = useState();
  const [ifMouth, setMouth] = useState();
  const [ifPulmonary, setPulmonary] = useState();
  const [ifAirWay, setIsAirWay] = useState();
  const [ifMallampati, setMallampati] = useState();
  const [ifThyro, setThyro] = useState();
  const [ifMobility, setMobility] = useState();
  const [ifCSpine, setIsCSpine] = useState();
  const [ifConclusions, setConclusions] = useState();
  const [ifClassification, setClassification] = useState();
  const [ifTransfussion, setTransfussion] = useState();
  const [ifQuantity, setIsQuantity] = useState();


  const handleAdd = () => {
    setLoading(true);
    const data = {
      user: user?._id,
      patient: id,
      surgicalAnaesthetic: surgicalAnaesthetic,
      if_respirory: ifRespirory,
      if_cardio: ifCardio,
      if_hepato: ifHepato,
      if_neuro: ifNeuro,
      if_renal: ifRenal,
      if_autres: ifAutres,
      if_examinations: ifExaminations,
      if_ausculation: ifAusculation,
      if_cardiac: ifCardiac,
      if_venous: ifVenous,
      if_mouth: ifMouth,
      if_pulmonary: ifPulmonary,
      if_airWay: ifAirWay,
      if_mallampati: ifMallampati,
      if_thyro: ifThyro,
      if_mobility: ifMobility,
      if_CSpine: ifCSpine,
      if_conclusions: ifConclusions,
      if_classification: ifClassification,
      if_transfussion: ifTransfussion,
      if_quantity: ifQuantity,
      treatments: treaatments,
      examen_labo: examenLabo,
      aditional_test: aditionalTest,
      results: results,
      pre_medications: preMedications,
      final_conclution: finalConclution,
    };
    console.log(data);
    addPatientPreAnaesthetic(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(
            fetchPatientPreAnaesthetics({ patientId: id})
          );
          dispatch(fetchPatientPreAnasetheticCount({patient: id}))
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <div>
      <div>
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                    <div className="Solera-Settings-Header">
                      Pre-Anaesthetic Note
                    </div>
                    <div className="Solera-Settings-Note">Add a Note</div>
                  </div>
                </div>
                <br />
                <br />
                <div className="Solera__Headings__Note">
                  <div className="Solera-Settings-Header">DIAGNOSTICS</div>
                </div>
                <div>
                  <div className="Solera-Settings-Header44">
                    Surgical-Anaesthetic
                  </div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <RichTextEditor
                          className="editor"
                          value={value}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="Solera__Headings__Note">
                  <div className="Solera-Settings-Header">
                    PLANNED INTERVENTION
                  </div>
                </div>
                <div>
                  <div className="Solera-Settings-Header44">Treaatments</div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                </div>
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <RichTextEditor
                          className="editor"
                          value={value2}
                          onChange={onChange2}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="Solera-Settings-Header44">SYSTEM</div>
                <br />
                <div className="Solera-Settings-Header44">RESPIRATORY</div>
                <div className="FormSect-Contianer">
                  {" "}
                  <Form>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Asthma"
                            type="radio"
                            id="Respirory"
                            name="Respirory"
                            onChange={(e) => setRespirory("Asthma")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Bronchitis"
                            type="radio"
                            id="Respirory"
                            name="Respirory"
                            onChange={(e) => setRespirory("Bronchitis")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Pheumonia"
                            type="radio"
                            id="Respirory"
                            name="Respirory"
                            onChange={(e) => setRespirory("Pheumonia")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Sinusitis"
                            type="radio"
                            id="Respirory"
                            name="Respirory"
                            onChange={(e) => setRespirory("Sinusitis")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Others"
                            type="radio"
                            id="Respirory"
                            name="Respirory"
                            onChange={(e) => setRespirory("Others")}
                          />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                </div>
                <br />
                <div className="Solera-Settings-Header44">CARDIOVASCULAR</div>
                <div className="FormSect-Contianer">
                  {" "}
                  <Form>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Htn"
                            type="radio"
                            id="Cardio"
                            name="Cardio"
                            onChange={(e) => setCardio("Htn")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Cardiomiophathy"
                            type="radio"
                            id="Cardio"
                            name="Cardio"
                            onChange={(e) => setCardio("Cardiomiophathy")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Rhythm Disorder"
                            type="radio"
                            id="Cardio"
                            name="Cardio"
                            onChange={(e) => setCardio("Rhythm Disorder")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Palpitations"
                            type="radio"
                            id="Cardio"
                            name="Cardio"
                            onChange={(e) => setCardio("Palpitations")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Endocarditis"
                            type="radio"
                            id="Cardio"
                            name="Cardio"
                            onChange={(e) => setCardio("Endocarditis")}
                          />
                        </div>

                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Heart Failure"
                            type="radio"
                            id="Cardio"
                            name="Cardio"
                            onChange={(e) => setCardio("Heart Failure")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Others"
                            type="radio"
                            id="Cardio"
                            name="Cardio"
                            onChange={(e) => setCardio("Others")}
                          />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                </div>
                <br />
                <div className="Solera-Settings-Header44">HEPATO/GASTRO</div>
                <div className="FormSect-Contianer">
                  {" "}
                  <Form>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Obesity"
                            type="radio"
                            id="Hepato"
                            name="Hepato"
                            onChange={(e) => setHepato("Obesity")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Epigastralgies"
                            type="radio"
                            id="Hepato"
                            name="Hepato"
                            onChange={(e) => setHepato("Epigastralgies")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Hepatities"
                            type="radio"
                            id="Hepato"
                            name="Hepato"
                            onChange={(e) => setHepato("Hepatities")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Cirrrhosis"
                            type="radio"
                            id="Hepato"
                            name="Hepato"
                            onChange={(e) => setHepato("Cirrrhosis")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Pancreatitis"
                            type="radio"
                            id="Hepato"
                            name="Hepato"
                            onChange={(e) => setHepato("Pancreatitis")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Others"
                            type="radio"
                            id="Hepato"
                            name="Hepato"
                            onChange={(e) => setHepato("Others")}
                          />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                </div>
                <div className="Solera-Settings-Header44">
                  {" "}
                  NEURO/MUSCULO-SKELETAL
                </div>
                <div className="FormSect-Contianer">
                  {" "}
                  <Form>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Anthritis"
                            type="radio"
                            id="Neuro"
                            name="Neuro"
                            onChange={(e) => setNeuro("Anthritis")}
                          />
                        </div>

                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Scouosis"
                            type="radio"
                            id="Neuro"
                            name="Neuro"
                            onChange={(e) => setNeuro("Scouosis")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Migraine / Headache"
                            type="radio"
                            id="Neuro"
                            name="Neuro"
                            onChange={(e) => setNeuro("Migraine / Headache")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Loss of consciousness"
                            type="radio"
                            id="Neuro"
                            name="Neuro"
                            onChange={(e) => setNeuro("Loss of consciousness")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Epilepsy"
                            type="radio"
                            id="Neuro"
                            name="Neuro"
                            onChange={(e) => setNeuro("Epilepsy")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Psychatric disorder"
                            type="radio"
                            id="Neuro"
                            name="Neuro"
                            onChange={(e) => setNeuro("Psychatric disorder")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Paresthesia"
                            type="radio"
                            id="Neuro"
                            name="Neuro"
                            onChange={(e) => setNeuro("Paresthesia")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Paralysis"
                            type="radio"
                            id="Neuro"
                            name="Neuro"
                            onChange={(e) => setNeuro("Paralysis")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Others"
                            type="radio"
                            id="Neuro"
                            name="Neuro"
                            onChange={(e) => setNeuro("Others")}
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
                <br /> <br />
                <div className="Solera-Settings-Header44">
                  RENAL / ENDOCRINE
                </div>
                <div className="FormSect-Contianer">
                  {" "}
                  <Form>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Diabetes"
                            type="radio"
                            id="Renal"
                            name="Renal"
                            onChange={(e) => setRenal("Diabetes")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Thyroid"
                            type="radio"
                            id="Renal"
                            name="Renal"
                            onChange={(e) => setRenal("Thyroid")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Insuffisance Renale"
                            type="radio"
                            id="Renal"
                            name="Renal"
                            onChange={(e) => setRenal("Insuffisance Renale")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Incontinence Urinaire"
                            type="radio"
                            id="Renal"
                            name="Renal"
                            onChange={(e) => setRenal("Incontinence Urinaire")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Autre"
                            type="radio"
                            id="Renal"
                            name="Renal"
                            onChange={(e) => setRenal("Autre")}
                          />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                </div>
                <br /> <br />
                <div className="Solera-Settings-Header44"> AUTRES</div>
                <div className="FormSect-Contianer">
                  {" "}
                  <Form>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Anemie"
                            type="radio"
                            id="Autres"
                            name="Autres"
                            onChange={(e) => setAutres("Anemie")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Probleme de Saignement"
                            type="radio"
                            id="Autres"
                            name="Autres"
                            onChange={(e) =>
                              setAutres("Probleme de Saignement")
                            }
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="VIH"
                            type="radio"
                            id="Autres"
                            name="Autres"
                            onChange={(e) => setAutres("VIH")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Drepanocytose"
                            type="radio"
                            id="Autres"
                            name="Autres"
                            onChange={(e) => setAutres("Drepanocytose")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Cancer"
                            type="radio"
                            id="Autres"
                            name="Autres"
                            onChange={(e) => setAutres("Cancer")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Sepsis/Infection"
                            type="radio"
                            id="Autres"
                            name="Autres"
                            onChange={(e) => setAutres("Sepsis/Infection")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Allergie"
                            type="radio"
                            id="Autres"
                            name="Autres"
                            onChange={(e) => setAutres("Allergie")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Grossesse"
                            type="radio"
                            id="Autres"
                            name="Autres"
                            onChange={(e) => setAutres("Grossesse")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Autre"
                            type="radio"
                            id="Autres"
                            name="Autres"
                            onChange={(e) => setAutres("Autre")}
                          />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                </div>
                <br /> <br />
                <br />
                <div className="Solera-Settings-Header44">EXAMINATION</div>
                <br />
                <br />
                <div className="FormSect-Contianer">
                  {" "}
                  <Form>
                    <div className="Solera-Settings-Header44">CONJUCITIVA</div>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Normal"
                            type="radio"
                            id="Examinations"
                            name="Examinations"
                            onChange={(e) => setExaminations("Normal")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Mildly Red"
                            type="radio"
                            id="Examinations"
                            name="Examinations"
                            onChange={(e) => setExaminations("Mildly Red")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Red"
                            type="radio"
                            id="Examinations"
                            name="Examinations"
                            onChange={(e) => setExaminations("Red")}
                          />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                  <Form>
                    <div className="Solera-Settings-Header44">
                      PULMONARY AUSCULTATION
                    </div>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Normal"
                            type="radio"
                            id="Pulmonary"
                            name="Pulmonary"
                            onChange={(e) => setPulmonary("Normal")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Abnormal"
                            type="radio"
                            id="Pulmonary"
                            name="Pulmonary"
                            onChange={(e) => setPulmonary("Abnormal")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Red"
                            type="radio"
                            id="Pulmonary"
                            name="Pulmonary"
                            onChange={(e) => setPulmonary("Red")}
                          />
                        </div>
                        <div className="container-fluid p-0 FormInput">
                          <div className="row gx-4">
                            <div className="col-lg">
                              <TextField
                                fullWidth
                                label="If Yes , Nature"
                                id="fullWidth"
                                type="text"
                                className="Solera-Login-Input mb-3"
                                onChange={(e) =>
                                  setIsAusculation(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                  <Form>
                    <div className="Solera-Settings-Header44">
                      CARDIAC AUSCULTATION
                    </div>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                       
                          <Form.Check
                            label="Normal"
                            type="radio"
                            id="Cardiac"
                            name="Cardiac"
                            onChange={(e) => setCardiac("Normal")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Abnormal"
                            type="radio"
                            id="Cardiac"
                            name="Cardiac"
                            onChange={(e) => setCardiac("Abnormal")}
                          />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                  <Form>
                    <div className="Solera-Settings-Header44">
                      VENOUS ACCESS
                    </div>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Accessible"
                            type="radio"
                            id="Cardiac"
                            name="Cardiac"
                            onChange={(e) => setVenous("Accessible")}
                          />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          <Form.Check
                            label="Difficult"
                            type="radio"
                            id="Cardiac"
                            name="Cardiac"
                            onChange={(e) => setVenous("Difficult")}
                          />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                </div>
                <div className="Solera-Settings-Header44">
                  AIRWAY EXAMINATION
                </div>
                <Form>
                  <div className="Solera-Settings-Header44">MOUTH OPENING</div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Normal"
                          type="radio"
                          id="Mouth"
                          name="Mouth"
                          onChange={(e) => setMouth("DifficNormalult")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Psmall"
                          type="radio"
                          id="Mouth"
                          name="Mouth"
                          onChange={(e) => setMouth("Psmall")}
                        />
                      </div>
                      <div className="container-fluid p-0 FormInput">
                        <div className="row gx-4">
                          <div className="col-lg">
                            <TextField
                              fullWidth
                              label="Others"
                              id="fullWidth"
                              type="text"
                              className="Solera-Login-Input mb-3"
                              onChange={(e) => setIsAirWay(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="Solera-Settings-Header44">MALLAMPATI</div>

                      <div className="col-lg-3 CheckSheet">
                   
                        <Form.Check
                          label="I"
                          type="radio"
                          id="Mallampati"
                          name="Mallampati"
                          onChange={(e) => setMallampati("I")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="II"
                          type="radio"
                          id="Mallampati"
                          name="Mallampati"
                          onChange={(e) => setMallampati("II")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="III"
                          type="radio"
                          id="Mallampati"
                          name="Mallampati"
                          onChange={(e) => setMallampati("III")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="IV"
                          type="radio"
                          id="Mallampati"
                          name="Mallampati"
                          onChange={(e) => setMallampati("IV")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
                <Form>
                  <div className="Solera-Settings-Header44">
                    THYRO-MENTAL DISTANCE
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="< 65 mm"
                          type="radio"
                          id="Thyro"
                          name="Thyro"
                          onChange={(e) => setThyro("< 65 mm")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="> 65 mm"
                          type="radio"
                          id="Thyro"
                          name="Thyro"
                          onChange={(e) => setThyro("> 65 mm")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
                <Form>
                  <div className="Solera-Settings-Header44">
                    MOBITITY OF THE C-SPINE
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Normal"
                          type="radio"
                          id="Mobility"
                          name="Mobility"
                          onChange={(e) => setMobility("Normal")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Reduced"
                          type="radio"
                          id="Mobility"
                          name="Mobility"
                          onChange={(e) => setMobility("Reduced")}
                        />
                      </div>

                      <div className="container-fluid p-0 FormInput">
                        <div className="row gx-4">
                          <div className="col-lg">
                            <TextField
                              fullWidth
                              label="Others"
                              id="fullWidth"
                              type="text"
                              className="Solera-Login-Input mb-3"
                              onChange={(e) => setIsCSpine(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="Solera-Settings-Header44">
                        {" "}
                        EXAMEN LABO
                      </div>

     
                      <div className="FormSect-Contianer">
                        <div className="container-fluid p-0 FormInput">
                          <div className="row gx-4">
                            <div className="col-lg">
                              <RichTextEditor
                                className="editor"
                                value={value3}
                                onChange={onChange3}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </Form>
                <br />
                <div className="Solera-Settings-Header44">CONCLUSIONS</div>
                <div className="Solera-Settings-Header44">
                  PULMONARY AUSULTATION
                </div>
                <div className="FormSect-Contianer">
          
                  <Form>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                      
                          <Form.Check
                          label="LA"
                          type="radio"
                          id="Conclusions"
                          name="Conclusions"
                          onChange={(e) => setConclusions("LA")}
                        />
                          
                        </div>
                        <div className="col-lg-3 CheckSheet">
                       
                          <Form.Check
                          label="General with mask"
                          type="radio"
                          id="Conclusions"
                          name="Conclusions"
                          onChange={(e) => setConclusions("General with mask")}
                        />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                         
                            <Form.Check
                          label="General with intubation"
                          type="radio"
                          id="Conclusions"
                          name="Conclusions"
                          onChange={(e) => setConclusions("General with intubation")}
                        />
                        </div>

                        <div className="col-lg-3 CheckSheet">
                   
                          <Form.Check
                          label="ORAL L"
                          type="radio"
                          id="Conclusions"
                          name="Conclusions"
                          onChange={(e) => setConclusions("ORAL L")}
                        />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                      
                          <Form.Check
                          label="ORAL D"
                          type="radio"
                          id="Conclusions"
                          name="Conclusions"
                          onChange={(e) => setConclusions("ORAL D")}
                        />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                          
                            <Form.Check
                          label="NASAL L"
                          type="radio"
                          id="Conclusions"
                          name="Conclusions"
                          onChange={(e) => setConclusions("NASAL L")}
                        />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                         
                            <Form.Check
                          label="NASAL D"
                          type="radio"
                          id="Conclusions"
                          name="Conclusions"
                          onChange={(e) => setConclusions("NASAL D")}
                        />
                        </div>

                        <div className="col-lg-3 CheckSheet">
                      
                          <Form.Check
                          label="Normal Intubation"
                          type="radio"
                          id="Conclusions"
                          name="Conclusions"
                          onChange={(e) => setConclusions("Normal Intubation")}
                        />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                    
                          <Form.Check
                          label="Intunation by the video laryngoscopy"
                          type="radio"
                          id="Conclusions"
                          name="Conclusions"
                          onChange={(e) => setConclusions("Intunation by the video laryngoscopy")}
                        />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                         
                          <Form.Check
                          label="Intubation by fibroscopy"
                          type="radio"
                          id="Conclusions"
                          name="Conclusions"
                          onChange={(e) => setConclusions("Intubation by fibroscopy")}
                        />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                </div>
                <br />
                <div className="Solera-Settings-Header44">
                  ASA CLASSIFICATION
                </div>
                <div className="FormSect-Contianer">
              
                  <Form>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                        
                          <Form.Check
                          label="I"
                          type="radio"
                          id="Classification"
                          name="Classification"
                          onChange={(e) => setClassification("I")}
                        />
                        </div>

                        <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="II"
                          type="radio"
                          id="Classification"
                          name="Classification"
                          onChange={(e) => setClassification("II")}
                        />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="III"
                          type="radio"
                          id="Classification"
                          name="Classification"
                          onChange={(e) => setClassification("III")}
                        />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="IV"
                          type="radio"
                          id="Classification"
                          name="Classification"
                          onChange={(e) => setClassification("IV")}
                        />
                        </div>
                        <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="V"
                          type="radio"
                          id="Classification"
                          name="Classification"
                          onChange={(e) => setClassification("V")}
                        />
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                </div>{" "}
                <br /> <br />
                <div className="Solera-Settings-Header44">
                  {" "}
                  ADDITIONAL TESTS
                </div>


                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <RichTextEditor
                          className="editor"
                          value={value4}
                          onChange={onChange4}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Solera-Settings-Header44">
               


                  {" "}
                  ADDITIONAL EXAMINATION RESULTS (WITH DATES)
                </div>
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <RichTextEditor
                          className="editor"
                          value={value5}
                          onChange={onChange5}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Solera-Settings-Header44">
                  {" "}
                  PRE- MEDICATIONS
                </div>

               
   

                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <RichTextEditor
                          className="editor"
                          value={value6}
                          onChange={onChange6}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                    
                <div className="Solera-Settings-Header44">TRANSFUSION</div>
                <div className="FormSect-Contianer">
                  {" "}
                  <Form>
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg-3 CheckSheet">
                         
                          <Form.Check
                          label="Yes"
                          type="radio"
                          id="ransfussion"
                          name="ransfussion"
                          onChange={(e) => setTransfussion("Yes")}
                        />
                        </div>

                        <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="ransfussion"
                          name="ransfussion"
                          onChange={(e) => setTransfussion("No")}
                        />
                        </div>
                      </div>
                      <div className="container-fluid p-0 FormInput">
                        <div className="row gx-4">
                          <div className="col-lg">
                            <TextField
                              fullWidth
                              label="Quantity"
                              id="fullWidth"
                              type="text"
                              className="Solera-Login-Input mb-3"
                              onChange={(e) => setIsQuantity(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </Form>
                </div>{" "}
                <div className="Solera-Settings-Header44">
                  {" "}
                  FINAL CONCLUSION(AVEC DATE)
                </div>
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <RichTextEditor
                          className="editor"
                          value={value7}
                          onChange={onChange7}

                         
                        

                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Procedures and Therapies"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Complications"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                      />
                    </div>
                  </div>
                </div> */}
                <div className="FormUpload">
                  {loading ? (
                    <Bars
                      heigth="50"
                      width="50"
                      color="gray"
                      ariaLabel="loading-indicator"
                    />
                  ) : (
                    <button className="Add-Button" onClick={handleAdd}>
                      Add Note
                    </button>
                  )}
                </div>
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default AnasetheticModal;
