import { React, useEffect, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import AddPreOpNote from "../ClinicalNote/AddPreOpNote";
import Dropdown from "react-bootstrap/Dropdown";
import ViewPreOpNoteDetailsModal from "./ViewPreOpNoteDetailsModal";
import EditPreOpNoteDetailsModal from "./EditPreOpNoteDetailsModal";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Bars } from "react-loader-spinner";
import UploadClinicalNote from "./UploadClinicalNote";
import ViewPreOpNoteUploadModal from "./ViewPreOpNoteUploadModal";
import { fetchPatientOperativeReport } from "../../../../features/patients/patientOperativeReportSlice";
import { fetchPatientOperativeReportsCount } from "../../../../features/patients/counts/patientOperativeReportsCountSlice";

function PreOpNote() {
  let { id } = useParams();
  const dispatch = useDispatch()
  const {operativesReports, loading } = useSelector((state) => state.patientOperativeReport)
  const patientOperativeReportsCount = useSelector((state) => state.patientOperativeReportsCount)

  const [selected, setSelected] = useState({});

  const [viewPreOpNoteDetailsShow, setViewPreOpNoteDetailsShow] =
    useState(false);
  const handleViewPreOpNoteDetails = () => setViewPreOpNoteDetailsShow(false);

  const [viewPreOpNoteUploadShow, setViewPreOpNoteUploadShow] =
    useState(false);
  const handleViewPreOpNoteUpload = () => setViewPreOpNoteUploadShow(false);

  const [uploadNotesShow, setUploadNotesShow] =
    useState(false);
  const handleUploadNoteDetails = () => setUploadNotesShow(false);

  const [editPreOpNoteDetailsShow, setEditPreOpNoteDetailsShow] =
    useState(false);
  const handleEditPreOpNoteDetails = () => setEditPreOpNoteDetailsShow(false);


  useEffect(() => {
    if (id) {
      dispatch(fetchPatientOperativeReport({patientId: id }))
      dispatch(fetchPatientOperativeReportsCount({patientId: id }))
    }
  }, [id]);

  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }

  return (
    <div>
      <div className="Report-Notes">
        <div className="ReportHeader">
          <div className="Report-Heading">Operative Report Form</div>
          <div className="Report-Count">
            <span>{patientOperativeReportsCount?.loading ? "Loading..." : patientOperativeReportsCount?.count}</span>&nbsp;Operative report
          </div>
          <AddPreOpNote />
        </div>
      </div>
      <br />
      {loading ? (
            <div className="d-flex justify-content-center">
              <Bars
                heigth="30"
                width="30"
                color="gray"
                ariaLabel="loading-indicator"
              />
            </div>
        ) : null
      }
      
        {
          operativesReports?.map((item, index) => (
            <div key={index} className="Patient-Record-Container">
              <div  className="Patient-Record-Wrapper">
                <div className="PatientNote-C">
                  <div className="Wallet-Ref-Heading">Note Number</div>
                  <div className="Wallet-Ref-Result">{item?.code}</div>
                </div>
                <div className="PatientNote-C">
                  <div className="Wallet-Ref-Heading">Doctor's Name</div>
                  <div className="Wallet-Ref-Result">{item?.user?.full_name}</div>
                </div>
                <div className="PatientNote-C">
                  <div className="Wallet-Ref-Heading">Date</div>
                  <div className="Wallet-Ref-Result">{showDate(item?.created_at)}</div>
                </div>
                <div className="PatientNote-C">
                  <div className="Wallet-Ref-Heading">Status</div>
                  <div className="Wallet-Ref-Result">{item?.status}</div>
                </div>

              
                <div className="Action-Drop-Down">
                  <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
                    Manage Report
                  </Dropdown.Toggle>


                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {setSelected(item); setViewPreOpNoteDetailsShow(true)}}
                        className="Solera-Patients"
                      >
                        View Report
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => {setSelected(item); setEditPreOpNoteDetailsShow(true)}}
                        className="Solera-Patients"
                      >
                        Update Report
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {setSelected(item); setUploadNotesShow(true)}}
                        className="Solera-Patients"
                      >
                        Upload Report
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {setSelected(item); setViewPreOpNoteUploadShow(true)}}
                        className="Solera-Patients"
                      >
                        View Uploaded Report
                      </Dropdown.Item>
                      {/* <Dropdown.Item className="Solera-Patients">
                        Delete Pre Op Note
                      </Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>

          ))
        }

      <div>
        <ViewPreOpNoteDetailsModal
          selected={selected}
          show={viewPreOpNoteDetailsShow}
          onClose={handleViewPreOpNoteDetails}
          onOpen={() => setViewPreOpNoteDetailsShow(true)}
        />

        <UploadClinicalNote 
          named={'pre Op'}
          selected={selected}
          show={uploadNotesShow}
          onClose={handleUploadNoteDetails}
          onOpen={() => setUploadNotesShow(true)}
        />

        <ViewPreOpNoteUploadModal 
          selected={selected}
          show={viewPreOpNoteUploadShow}
          onClose={handleViewPreOpNoteUpload}
          onOpen={() => setViewPreOpNoteUploadShow(true)}
        />

        <EditPreOpNoteDetailsModal
          show={editPreOpNoteDetailsShow}
          onClose={handleEditPreOpNoteDetails}
          onOpen={() => setEditPreOpNoteDetailsShow(true)}
        />
      </div>
    </div>
  );
}

export default PreOpNote;
