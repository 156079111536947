import axios from '../axiosinstance';

      
export function listPatientClinicalDischarges(initData){
        return axios.get('/patient-clinical-discharges', { params: { name: initData?.search, patient: initData?.patientId } })
}

export function addPatientClinicalDischarge(data){
        return axios.post('/patient-clinical-discharges', data)
}

export function getPatientClinicalDischarge(initData){
        return axios.get('/patient-clinical-discharges/'+initData?.id)
}

export function updatePatientClinicalDischarge(initData){
        return axios.patch('/patient-clinical-discharges/'+initData.id, initData)
}

export function patientClinicalDischargesCount(search){
        return axios.get('/patient-clinical-discharges/stat/count', { params: search })
}

