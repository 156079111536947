import { React, useState } from "react";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import AddPhysiotherapyModal from "./AddPhysiotherapyModal";
import AddDoctorNoteModal from "./AddDoctorNoteModal";
import { getSys } from "../../../../services/AuthService";

function AddDoctorNote() {

  const sys = getSys();
  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);
  return (
    <div>
     


      {sys?.role === "sys-admin" || sys?.role === "sys-doctor" ? (
              <button
              className="Add-Patient-Report"
              onClick={() => setAddReportShow(true)}
            >
              Add Doctor's Note
            </button>
            ) : null}



      <div>
        <AddDoctorNoteModal
          show={addReportShow}
          onClose={handleAddReport}
          onOpen={() => setAddReportShow(true)}
        />
      </div>
    </div>
  );
}

export default AddDoctorNote;
