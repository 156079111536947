import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientClinicalDischarges } from '../../services/PatientClinicalDischargeService'

const initialState = {
    loading: false,
    clinicalDischarges: [],
    isSuccessful: false
}

export const fetchPatientClinicalDischarge = createAsyncThunk('fetchPatientClinicalDischarge', (initData) => {
    return listPatientClinicalDischarges(initData)
    .then((response) => response.data.data)
})


const patientClinicalDischargeSlice = createSlice({
    name: 'patientClinicalDischarge',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientClinicalDischarge.pending, (state) => {
        state.loading = true
        state.isSuccessful = false
       })
       builder.addCase(fetchPatientClinicalDischarge.fulfilled, (state, action ) => {
        state.loading = false
        state.clinicalDischarges = action.payload.data
        state.isSuccessful = true
       })
       builder.addCase(fetchPatientClinicalDischarge.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default patientClinicalDischargeSlice.reducer 