import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { listPatientPreAnaesthetics } from '../../services/PatientPreAnaestheticsService'

const initialState = {
    loading: false,
    preAnaesthetics: [],
    isSuccessful: false
}

export const fetchPatientPreAnaesthetics = createAsyncThunk('fetchPatientPreAnaesthetics', (initData) => {
    return listPatientPreAnaesthetics(initData)
    .then((response) => response.data.data)
})


const patientPreAnaestheticSlice = createSlice({
    name: 'patientPreAnaesthetics',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatientPreAnaesthetics.pending, (state) => {
        state.loading = true
        state.isSuccessful = false
       })
       builder.addCase(fetchPatientPreAnaesthetics.fulfilled, (state, action ) => {
        state.loading = false
        state.preAnaesthetics = action.payload.data
        state.isSuccessful = true
       })
       builder.addCase(fetchPatientPreAnaesthetics.rejected, (state, action ) => {
        state.loading = false
        state.isSuccessful = false
       })
    }
})

export default patientPreAnaestheticSlice.reducer 