import { React, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { getUser } from "../../../../services/AuthService";
import { useDispatch } from "react-redux";
import { addPatientAssessment } from "../../../../services/PatientAssessmentService";
import { toast } from "react-toastify";
import { fetchPatientAssessment } from "../../../../features/patients/patientAssessmentSlice";
import { Bars } from "react-loader-spinner";
import RichTextEditor from "react-rte"; // Copy Editor
import MenuItem from "@mui/material/MenuItem";
import Form from "react-bootstrap/Form";

function AssessmentModal({ show, onClose }) {
  const [value, setValue] = useState(RichTextEditor.createEmptyValue()); // Copy Editor
  const [value2, setValue2] = useState(RichTextEditor.createEmptyValue()); // Copy Editor
  const [value3, setValue3] = useState(RichTextEditor.createEmptyValue()); // Copy Editor

  let { id } = useParams();
  const user = getUser();
  const dispatch = useDispatch();
  const [presentingConcern, setPresentingConcern] = useState("");
  const [relevantMedicalHistory, setRelevantMedicalHistory] = useState("");
  const [psychosocialFactors, setPsychosocialFactors] = useState("");
  const [arriveHere, setArriveHere] = useState([]);

  const [loading, setLoading] = useState(false);

  const [addDoctorShow, setAddDoctorShow] = useState(false);
  const handleAddDoctor = () => setAddDoctorShow(false);

  const handleSaveDoctor = () => {
    onClose();
    setAddDoctorShow(false);

    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };


  {
    /* Copy Editor */
  }
  const onChange = (value) => {
    setValue(value);
    value.toString("html");
    setPresentingConcern(value.toString("html"));
  };

  {
    /* Copy Editor */
  }
  const onChange2 = (value) => {
    setValue2(value);
    value.toString("html");
    setRelevantMedicalHistory(value.toString("html"));
  };
  {
    /* Copy Editor */
  }
  const onChange3 = (value) => {
    setValue3(value);
    value.toString("html");
    setPsychosocialFactors(value.toString("html"));
  };

  const sex = [
    {
      value: "Gender",
      label: "Gender",
    },
    {
      value: "Male",
      label: "Male",
    },
    {
      value: "Female",
      label: "Female",
    },
  ];
  const status = [
    {
      value: "Single",
      label: "Single",
    },
    {
      value: "Married",
      label: "Married",
    },
  ];

  const language = [
    {
      value: "PG",
      label: "PG",
    },
    {
      value: "HA",
      label: "HA",
    },
    {
      value: "IG",
      label: "IG",
    },
    {
      value: "YO",
      label: "YO",
    },
    {
      value: "FUL",
      label: "FUL",
    },
    {
      value: "OTHERS",
      label: "OTHERS",
    },
  ];

  const handleNumberClick = (number) => {
    setArriveHere((nums) =>
      nums.includes(number)
        ? nums.filter((n) => n !== number)
        : [number, ...nums]
    );
  };

  const [ifCare, setIfCare] = useState();
  const [isAll, setIsAll] = useState();
  const [isOther, setIsOther] = useState();
  const [isHowdid, setIsHowdid] = useState();
  const [isOtherTreatment, setIsOtherTreatment] = useState();
  const [isDidYouFace, setIsDidYouFace] = useState();
  const [isIfYes, setIsIfYes] = useState();
  const [isOtherTrad, setIsOtherTrad] = useState();
  const [isFinancialImpacts, setIsFinancialImpacts] = useState();
  const [isIfSoHow, setIsIfSoHow] = useState();
  const [isDidYouHave, setIsDidYouHave] = useState();
  const [isYesHow, setIsYesHow] = useState();
  const [isOtherPeople, setIsOtherPeople] = useState();
  const [isYesContact, setIsYesContact] = useState();
  const [isWhoWill, setIsWhoWill] = useState();
  const [isItPainful, setIsIsItPainful] = useState();
  const [isDoesyourRestricted, setIsDoesyourRestricted] = useState();
  const [isDoYourFamily, setIsDoYourFamily] = useState();
  const [isCanYouSmile, setIsCanYouSmile] = useState();
  const [isDoYouLike, setIsDoYouLike] = useState();
  const [isPhysicalHealth, setIsPhysicalHealth] = useState();
  const [isPaifulDrink, setIsPaifulDrink] = useState();
  const [isHowManyDays, setIsHowManyDays] = useState();
  const [isHealthActivities, setIsHealthActivities] = useState();
  const [isHowMuchPain, setIsHowMuchPain] = useState();
  const [isIndependence, setIsIndependence] = useState();
  const [isWhatWould, setIsWhatWould] = useState();
  const [isSocialCommunal, setIsSocialCommunal] = useState();
  const [isCommunal, setIsCommunal] = useState();
  const [isDoPeople, setIsDoPeople] = useState();
  const [isProspects, setIsProspects] = useState();
  const [isReligious, setIsReligious] = useState();
  const [isMental, setIsMental] = useState();
  const [isAnxious, setIsAnxious] = useState();
  const [isDays, setIsDays] = useState();
  const [isHappy, setIsHappy] = useState();
  const [isChild, setIsChild] = useState();
  const [isRestricted, setIsRestricted] = useState();
  const [isMealtimes, setIsMealtimes] = useState();
  const [isSpeaks, setIsSpeaks] = useState();
  const [isWithout, setIsWithout] = useState();
  const [issLook, setIsLook] = useState();
  const [isPastMonth, setIsPastMonth] = useState();
  const [isGood, setIsGood] = useState();
  const [isStopped, setIsStopped] = useState();
  const [isUsual, setIsUsual] = useState();
  const [isAbility, setIsAbility] = useState();
  const [isYExclude, setIsExclude] = useState();
  const [isSocialInclusion, setIsSocialInclusion] = useState();
  const [isTreatOther, setIsTreatOther] = useState();
  const [isGatherings, setIsGatherings] = useState();

  const [isWorried, setIsWorried] = useState();
  const [isChildFelt, setIsChildFelt] = useState();
  const [isPsychosocial, setIsPsychosocial] = useState();


  const handleAdd = () => {
    setLoading(true);
    const data = {
      user: user?._id,
      patient: id,
      presenting_concern: presentingConcern,
      relevant_medical_history: relevantMedicalHistory,
      psychosocial_factors: psychosocialFactors,
      if_care: ifCare,
      is_all: isAll,
      is_other: isOther,
      is_how_did: isHowdid,
      is_other_treatment: isOtherTreatment,
      is_did_you_face: isDidYouFace,
      is_if_yes: isIfYes,
      is_other_trad: isOtherTrad,
      is_financial_impacts: isFinancialImpacts,
      is_if_so_how: isIfSoHow,
      is_did_you_have: isDidYouHave,
      is_yes_how: isYesHow,
      is_other_people: isOtherPeople,
      is_yes_contact: isYesContact,
      is_who_will: isWhoWill,
      is_it_painful: isItPainful,
      is_does_your_restricted: isDoesyourRestricted,
      is_do_your_family: isDoYourFamily,
      is_can_you_smile: isCanYouSmile,
      is_do_you_like: isDoYouLike,
      is_physical_health: isPhysicalHealth,
      is_painful_drink: isPaifulDrink,
      is_how_many_days: isHowManyDays,
      is_health_activities: isHealthActivities,
      is_how_much_pain: isHowMuchPain,
      is_independence: isIndependence,
      is_what_would: isWhatWould,
      is_social_communal: isSocialCommunal,
      is_communal: isCommunal,
      is_do_people: isDoPeople,
      is_prospects: isProspects,
      is_religious: isReligious,
      is_mental: isMental,
      is_anxious: isAnxious,
      is_days: isDays,
      is_happy: isHappy,
      is_child: isChild,
      is_restricted: isRestricted,
      is_meal_times: isMealtimes,
      is_speaks: isSpeaks,
      is_without: isWithout,
      is_look: issLook,
      is_past_month: isPastMonth,
      is_good: isGood,
      is_stopped: isStopped,
      is_usual: isUsual,
      is_ability: isAbility,
      is_y_exclude: isYExclude,
      is_social_inclusion: isSocialInclusion,
      is_treat_other: isTreatOther,
      is_gathering: isGatherings,
      is_worried: isWorried,
      is_child_felt: isChildFelt,
      is_psychosocial: isPsychosocial,
    };
    addPatientAssessment(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(fetchPatientAssessment({ patientId: id }));
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };



  return (
    <div>
      {" "}
      <div>
        <Modal
          size="xl"
          show={show}
          onHide={onClose}
          backdrop="static"
          aria-labelledby="example-modal-sizes-title-lg"
          keyboard={false}
          animation={true}
        >
          <Modal.Body>
            <div className="Solera__ModalHeadings">
              <MdClose className="Solera__Close" onClick={onClose} />
            </div>

            <div className="Solera__ModalHeadings"></div>
            <div className="Solera-Modal-Container">
              <div className="Solera__Headings">
                <div className="Log__Cont">
                  <img
                    src="/assets/lg.png"
                    alt=""
                    className="Log__Img__Modal"
                  />
                </div>
                <div className="Solera__Headings__Note">
                  <div className="Solera-Settings-Header">
                    Patient Assessment
                  </div>
                  {/* <div className="Solera-Settings-Note">
                            Kindly add a doctor for your records
                          </div> */}
                </div>
              </div>
              <br />
              <br />
              <div className="FormSect">Assessment 1</div>
              <div className="FormSect-Note">
                Please fill all the required field
              </div>
              <br />
              <br />
              <div className="FormSect">
                If care was sought before arriving here today, where was it
                sought? Select all that apply
              </div>
              <div className="FormSect-Contianer">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Nowhere, this was our first visit to a health care provider"
                          type="radio"
                          id="IfCareWas"
                          name="IfCareWas"
                          onChange={(e) =>
                            setIfCare(
                              "Nowhere, this was our first visit to a health care provider"
                            )
                          }
                        
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          type="radio"
                          id="IfCareWas"
                          name="IfCareWas"
                          onChange={(e) => setIfCare("Traditional healer")}
                          label="Traditional healer"
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          type="radio"
                          id="IfCareWas"
                          name="IfCareWas"
                          onChange={(e) => setIfCare("Health centre")}
                          label="Health centre
"
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Hospital "
                          type="radio"
                          id="IfCareWas"
                          name="IfCareWas"
                          onChange={(e) => setIfCare("Hospital")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Other NGO"
                          type="radio"
                          id="IfCareWas"
                          name="IfCareWas"
                          onChange={(e) => setIfCare("Other NGO")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Other_previous_care"
                          type="radio"
                          id="IfCareWas"
                          name="IfCareWas"
                          onChange={(e) => setIfCare("Other_previous_care")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Don Not know"
                          type="radio"
                          id="IfCareWas"
                          name="IfCareWas"
                          onChange={(e) => setIfCare("Don Not Know")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                What treatment was offered? Select all that apply
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          type="radio"
                          id="whatTreatmenrt"
                          name="whatTreatmenrt"
                          onClick={(e) =>
                            setIsAll(
                              "Traditional treatment- herbs on skin/ ointment herbs to drink"
                            )
                          }
                          label="Traditional treatment- herbs on skin/ ointment herbs to drink/"
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          type="radio"
                          id="whatTreatmenrt"
                          name="whatTreatmenrt"
                          onClick={(e) =>
                            setIsAll(
                              "Traditional treatment- piercing of the cheek"
                            )
                          }
                          label="Traditional treatment- piercing of the cheek"
                        />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Antibiotics"
                          type="radio"
                          id="whatTreatmenrt"
                          name="whatTreatmenrt"
                          onClick={(e) => setIsAll("Antibiotics")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Nutritional support "
                          type="radio"
                          id="whatTreatmenrt"
                          name="whatTreatmenrt"
                          onClick={(e) => setIsAll("Nutritional support")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Mouth wash"
                          type="radio"
                          id="whatTreatmenrt"
                          name="whatTreatmenrt"
                          onClick={(e) => setIsAll("Mouth wash")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          type="radio"
                          label="Cleaning of wound"
                          id="whatTreatmenrt"
                          name="whatTreatmenrt"
                          onClick={(e) => setIsAll("Cleaning of wound")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          type="radio"
                          label="Pain management (medication)"
                          id="whatTreatmenrt"
                          name="whatTreatmenrt"
                          onClick={(e) =>
                            setIsAll("Pain management (medication)")
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Treatment for comorbidities "
                          type="radio"
                          id="whatTreatmenrt"
                          name="whatTreatmenrt"
                          onClick={(e) =>
                            setIsAll("Treatment for comorbidities ")
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Surgery"
                          type="radio"
                          id="whatTreatmenrt"
                          name="whatTreatmenrt"
                          onClick={(e) => setIsAll("Surgery")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          type="radio"
                          label="Other: {other_tx}"
                          id="whatTreatmenrt"
                          name="whatTreatmenrt"
                          onClick={(e) => setIsAll("Other")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          type="radio"
                          label="I do not know"
                          id="whatTreatmenrt"
                          name="whatTreatmenrt"
                          onClick={(e) => setIsAll("I do not know")}
                        />
                      </div>

                      <div className="container-fluid p-0 FormInput">
                        <div className="row gx-4">
                          <div className="col-lg">
                            <TextField
                              fullWidth
                              label="Other traditional treatment, please describe"
                              id="fullWidth"
                              type="text"
                              className="Solera-Login-Input mb-3"
                              onChange={(e) => setIsOther(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                How did you hear about our services?
              </div>
              {/* setIsHowdid */}
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="From traditional healer"
                          type="radio"
                          id="HowDid"
                          name="HowDid"
                          onClick={(e) =>
                            setIsHowdid("From traditional healer")
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="From Imam/ community leader"
                          type="radio"
                          id="HowDid"
                          name="HowDid"
                          onClick={(e) =>
                            setIsHowdid("From Imam/ community leader")
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="From friends/ family"
                          type="radio"
                          id="HowDid"
                          name="HowDid"
                          onClick={(e) => setIsHowdid("From friends/ family")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Radio advert"
                          type="radio"
                          id="HowDid"
                          name="HowDid"
                          onClick={(e) => setIsHowdid("Radio advert")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Poster"
                          type="radio"
                          id="HowDid"
                          name="HowDid"
                          onClick={(e) => setIsHowdid("Poster")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="TV advert"
                          type="radio"
                          id="HowDid"
                          name="HowDid"
                          onClick={(e) => setIsHowdid("TV advert")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Outreach team activity"
                          type="radio"
                          id="HowDid"
                          name="HowDid"
                          onClick={(e) => setIsHowdid("Outreach team activity")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Others"
                          type="radio"
                          id="HowDid"
                          name="HowDid"
                          onClick={(e) => setIsHowdid("Others")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="I don't know"
                          type="radio"
                          id="HowDid"
                          name="HowDid"
                          onClick={(e) => setIsHowdid("I don't know")}
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <TextField
                          fullWidth
                          label="Other traditional treatment, please describe"
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                          onChange={(e) => setIsOtherTreatment(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
              <div className="FormSect">
                Did you face any difficulties getting here today?
              </div>
              <div className="FormSect-Contianer00">
                {/* setIsDidYouFace */}
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="DidYouFace"
                          name="DidYouFace"
                          onClick={(e) => setIsDidYouFace("Yes")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="DidYouFace"
                          name="DidYouFace"
                          onClick={(e) => setIsDidYouFace("No")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Unsure"
                          type="radio"
                          id="DidYouFace"
                          name="DidYouFace"
                          onClick={(e) => setIsDidYouFace("Unsure")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                If yes, what were the difficulties *Select all that apply
              </div>
              {/* const [isIfYes, setIsIfYes] = useState();
              const [isOtherTrad, setIsOtherTrad] = useState(); */}
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Transport was expensive"
                          type="radio"
                          id="IfYes"
                          name="IfYes"
                          onClick={(e) => setIsIfYes("Transport was expensive")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Transport was difficult to find"
                          type="radio"
                          id="IfYes"
                          name="IfYes"
                          onClick={(e) =>
                            setIsIfYes("Transport was difficult to find")
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="It was difficult to find people to look after my other children who are at home
"
                          type="radio"
                          id="IfYes"
                          name="IfYes"
                          onClick={(e) =>
                            setIsIfYes(
                              "It was difficult to find people to look after my other children who are at home"
                            )
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="It took a long time to get here"
                          type="radio"
                          id="IfYes"
                          name="IfYes"
                          onClick={(e) =>
                            setIsIfYes("It took a long time to get here")
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="It was difficult to leave my work"
                          type="radio"
                          id="IfYes"
                          name="IfYes"
                          onClick={(e) =>
                            setIsIfYes("It was difficult to leave my work")
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="I was worried about losing my income while we travelled here
"
                          type="radio"
                          id="IfYes"
                          name="IfYes"
                          onClick={(e) =>
                            setIsIfYes(
                              "I was worried about losing my income while we travelled here"
                            )
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Bad weather/ heavy rain causing blocked roads
"
                          type="radio"
                          id="IfYes"
                          name="IfYes"
                          onClick={(e) =>
                            setIsIfYes(
                              "Bad weather/ heavy rain causing blocked roads"
                            )
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Long waiting time "
                          type="radio"
                          id="IfYes"
                          name="IfYes"
                          onClick={(e) => setIsIfYes("Long waiting time")}
                        />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Other
"
                          type="radio"
                          id="IfYes"
                          name="IfYes"
                          onClick={(e) => setIsIfYes("Other")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="I do not know
"
                          type="radio"
                          id="IfYes"
                          name="IfYes"
                          onClick={(e) => setIsIfYes("I do not know")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <TextField
                          fullWidth
                          label="Other traditional treatment, please describe"
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                          onChange={(e) => setIsOtherTrad(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              {/* const [isFinancialImpacts, setIsFinancialImpacts] = useState();
              const [isIfSoHow, setIsIfSoHow] = useState(); */}
              <div className="FormSect">
                <b>Financial impacts</b>- Has noma impacted you/ your family's
                ability to work and earn a living?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="FinancialImpact"
                          name="FinancialImpact"
                          onClick={(e) => setIsFinancialImpacts("Yes")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="FinancialImpact"
                          name="FinancialImpact"
                          onClick={(e) => setIsFinancialImpacts("No")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">If so, how? *Select all that apply</div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="I/ we lost a job due to stigma"
                          type="radio"
                          id="IfSo"
                          name="IfSo"
                          onClick={(e) => setIsIfSoHow("No")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="I/ we had to stop working to look after my child (the noma patient)"
                          type="radio"
                          id="IfSo"
                          name="IfSo"
                          onClick={(e) =>
                            setIsIfSoHow(
                              "I/ we had to stop working to look after my child (the noma patient)"
                            )
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="I/ we have not been offered jobs due to stigma
"
                          type="radio"
                          id="IfSo"
                          name="IfSo"
                          onClick={(e) =>
                            setIsIfSoHow(
                              "I/ we have not been offered jobs due to stigma"
                            )
                          }
                        />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="I was unable to go to school because I had noma and so now cannot find a job"
                          type="radio"
                          id="IfSo"
                          name="IfSo"
                          onClick={(e) =>
                            setIsIfSoHow(
                              "I was unable to go to school because I had noma and so now cannot find a job"
                            )
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="I/ we had to stop working in the fields/ doing my farming duties"
                          type="radio"
                          id="IfSo"
                          name="IfSo"
                          onClick={(e) =>
                            setIsIfSoHow(
                              "I/ we had to stop working in the fields/ doing my farming duties"
                            )
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="I was worried about losing my income while we travelled here
"
                          type="radio"
                          id="IfSo"
                          name="IfSo"
                          onClick={(e) =>
                            setIsIfSoHow(
                              "I was worried about losing my income while we travelled here"
                            )
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Other
"
                          type="radio"
                          id="IfSo"
                          name="IfSo"
                          onClick={(e) => setIsIfSoHow("Other")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Did you have to sell anything to get to or afford care for noma?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="DidYou"
                          name="DidYou"
                          onClick={(e) => setIsDidYouHave("Yes")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="DidYou"
                          name="DidYou"
                          onClick={(e) => setIsDidYouHave("No")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              {/* <div className="FormSect">
                <b>Financial impacts</b>- Has noma impacted you/ your family's
                ability to work and earn a living?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="Yes" />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check label="No" />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div> */}
              <div className="FormSect">If yes, what did you have to sell?</div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Animals"
                          type="radio"
                          id=" YesHow"
                          name="YesHow"
                          onClick={(e) => setIsYesHow("Animals")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Land"
                          type="radio"
                          id=" YesHow"
                          name="YesHow"
                          onClick={(e) => setIsYesHow("Land")}
                        />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Possessions (such as cell phone or clothes)
"
                          type="radio"
                          id=" YesHow"
                          name="YesHow"
                          onClick={(e) =>
                            setIsYesHow(
                              "Possessions (such as cell phone or clothes)"
                            )
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Othes
"
                          type="radio"
                          id=" YesHow"
                          name="YesHow"
                          onClick={(e) => setIsYesHow("Other)")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Do you know any other people who have noma?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id=" OtherP"
                          name="OtherP"
                          onClick={(e) => setIsOtherPeople("Yes)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id=" OtherP"
                          name="OtherP"
                          onClick={(e) => setIsOtherPeople("No)")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="container-fluid p-0 FormInput">
                <div className="row gx-4">
                  <div className="col-lg">
                    <TextField
                      fullWidth
                      label="If yes, can you give us their names or contact details *(such as address or phone number)?"
                      id="fullWidth"
                      type="text"
                      className="Solera-Login-Input mb-3"
                      onChange={(e) => setIsYesContact(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="FormSect">Quality of life</div>
              <div className="FormSect-Note">
                Please fill all the required field
              </div>
              <br />
              <div className="FormSect">
                Who will be answering the quality of life questions?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Patient"
                          type="radio"
                          id=" Whowill"
                          name="Whowill"
                          onClick={(e) => setIsWhoWill("Patient)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Caretaker"
                          type="radio"
                          id=" Whowill"
                          name="Whowill"
                          onClick={(e) => setIsWhoWill("Caretaker)")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <br />
              <br />
              <div className="FormSect">Quality of life (Patient)</div>
              <div className="FormSect-Note">
                Please fill all the required field
              </div>
              <br />
              <div className="FormSect">
                Is it painful when you eat or drink?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="IsIt"
                          name="IsIt"
                          onClick={(e) => setIsIsItPainful("Yes)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Sometimes"
                          type="radio"
                          id="IsIt"
                          name="IsIt"
                          onClick={(e) => setIsIsItPainful("Sometimes)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="IsIt"
                          name="IsIt"
                          onClick={(e) => setIsIsItPainful("No)")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Does your restricted mouth opening make eating or drinking
                difficult?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="DoesYour"
                          name="DoesYour"
                          onClick={(e) => setIsDoesyourRestricted("Yes)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Sometimes"
                          type="radio"
                          id="DoesYour"
                          name="DoesYour"
                          onClick={(e) => setIsDoesyourRestricted("Sometimes)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="DoesYour"
                          name="DoesYour"
                          onClick={(e) => setIsDoesyourRestricted("No)")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Do your family/ household members include you in communal
                mealtimes?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="Familly"
                          name="Familly"
                          onClick={(e) => setIsDoYourFamily("Yes)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Sometimes"
                          type="radio"
                          id="Familly"
                          name="Familly"
                          onClick={(e) => setIsDoYourFamily("Sometimes)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="Familly"
                          name="Familly"
                          onClick={(e) => setIsDoYourFamily("No)")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Can you smile and speak without pain?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="CanYou"
                          name="CanYou"
                          onClick={(e) => setIsCanYouSmile("No)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Sometimes"
                          type="radio"
                          id="CanYou"
                          name="CanYou"
                          onClick={(e) => setIsCanYouSmile("Sometimes)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="CanYou"
                          name="CanYou"
                          onClick={(e) => setIsCanYouSmile("No)")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">Do you like the way you look?</div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="DoYou"
                          name="DoYou"
                          onClick={(e) => setIsDoYouLike("Yes)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Sometimes"
                          type="radio"
                          id="DoYou"
                          name="DoYou"
                          onClick={(e) => setIsDoYouLike("Sometimes)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="DoYou"
                          name="DoYou"
                          onClick={(e) => setIsDoYouLike("No)")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Physical health- In the past month, has your physical health
                been good?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="Physical"
                          name="Physical"
                          onClick={(e) => setIsPhysicalHealth("Yes)")}
                        />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="Physical"
                          name="Physical"
                          onClick={(e) => setIsPhysicalHealth("No)")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Is it painful when you eat or drink?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="Drink"
                          name="Drink"
                          onClick={(e) => setIsPaifulDrink("Yes)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Sometimes"
                          type="radio"
                          id="Drink"
                          name="Drink"
                          onClick={(e) => setIsPaifulDrink("Sometimes)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="Drink"
                          name="Drink"
                          onClick={(e) => setIsPaifulDrink("No)")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>

                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Physical health- How many days in the past month has your physical health not been good?"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsHowManyDays(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect">
                Physical health- How many days in the past month has your
                physical pain stopped you from doing your usual activities?
              </div>
              <div className="FormSect-Contianer00">
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Enter here"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsHealthActivities(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect">
                Physical health- How much pain are you in today? *1 is none and
                7 is unbearable pain
              </div>
              <div className="FormSect-Contianer00">
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Enter here"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsHowMuchPain(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect">
                Independence- Do you feel noma has limited your ability to take
                care of yourself?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="Independence"
                          name="Independence"
                          onClick={(e) => setIsIndependence("Yes)")}
                        />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="Independence"
                          name="Independence"
                          onClick={(e) => setIsIndependence("No)")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Independence- What would help you to reduce the feeling of not
                being able to take care of yourself?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Learning how to feed, dress, care for myself"
                          type="radio"
                          id="WhatWould"
                          name="WhatWould"
                          onClick={(e) =>
                            setIsWhatWould(
                              "Learning how to feed, dress, care for myself)"
                            )
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Assistance with finding a house where I can bemore independent"
                          type="radio"
                          id="WhatWould"
                          name="WhatWould"
                          onClick={(e) =>
                            setIsWhatWould(
                              "Assistance with finding a house where I can bemore independent)"
                            )
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Assistance finding a job which would allow me to earn a living to support myself"
                          type="radio"
                          id="WhatWould"
                          name="WhatWould"
                          onClick={(e) =>
                            setIsWhatWould(
                              "Assistance finding a job which would allow me to earn a living to support myself)"
                            )
                          }
                        />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Surgery to fix the face defect so I can integrate more easily into society"
                          type="radio"
                          id="WhatWould"
                          name="WhatWould"
                          onClick={(e) =>
                            setIsWhatWould(
                              "Surgery to fix the face defect so I can integrate more easily into society)"
                            )
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Other: {other_independence}"
                          type="radio"
                          id="WhatWould"
                          name="WhatWould"
                          onClick={(e) => setIsWhatWould("Other)")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Social inclusion- Do people exclude you from social activities
                due to noma?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="Social"
                          name="Social"
                          onClick={(e) => setIsSocialInclusion("Other)")}
                        />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="Social"
                          name="Social"
                          onClick={(e) => setIsSocialInclusion("No)")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Social inclusion- Have your family been left out of communal
                activities because of your noma diagnosis?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="communal"
                          name="communal"
                          onClick={(e) => setIsCommunal("Yes)")}
                        />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="communal"
                          name="communal"
                          onClick={(e) => setIsCommunal("No)")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Social inclusion- Do people treat you the same as they treat
                other community members?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="DoPeople"
                          name="DoPeople"
                          onClick={(e) => setIsDoPeople("Yes)")}
                        />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="DoPeople"
                          name="DoPeople"
                          onClick={(e) => setIsDoPeople("No)")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Social inclusion- Do you feel your prospects for love
                relationships/ marriage etc are negatively impacted by No noma?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="Prospects"
                          name="Prospects"
                          onClick={(e) => setIsProspects("Yes)")}
                        />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="Prospects"
                          name="Prospects"
                          onClick={(e) => setIsProspects("No)")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Social inclusion- Are you included in religious/ spiritual
                gatherings?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="Prosreligiouspects"
                          name="religious"
                          onClick={(e) => setIsProspects("Yes)")}
                        />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="religious"
                          name="religious"
                          onClick={(e) => setIsReligious("No)")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Mental health- How do you feel about your life now?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Hopeful"
                          type="radio"
                          id="Mental"
                          name="Mental"
                          onClick={(e) => setIsMental("Hopeful)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Excited"
                          type="radio"
                          id="Mental"
                          name="Mental"
                          onClick={(e) => setIsMental("Excited)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Unsure"
                          type="radio"
                          id="Mental"
                          name="Mental"
                          onClick={(e) => setIsMental("Unsure)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Sad"
                          type="radio"
                          id="Mental"
                          name="Mental"
                          onClick={(e) => setIsMental("Sad)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Other"
                          type="radio"
                          id="Mental"
                          name="Mental"
                          onClick={(e) => setIsMental("Other)")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Mental health- In the past two weeks, have you felt sad,
                anxious, worried?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="Anxious"
                          name="Anxious"
                          onClick={(e) => setIsAnxious("Yes)")}
                        />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="Anxious"
                          name="Anxious"
                          onClick={(e) => setIsAnxious("No)")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                How many days in the past two weeks, have you felt sad, anxious,
                worried?
              </div>
              <div className="FormSect-Contianer00">
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Enter here"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsDays(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect">
                How happy are you with your life (1 you are very happy and 7 you
                are very unhappy)?
              </div>
              <div className="FormSect-Contianer00">
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Enter here"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsHappy(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="FormSect">
                Quality of life (Caretaker cpompleting , "your child" is the
                noma Patient)
              </div>
              <div className="FormSect-Note">
                Please fill all the required field
              </div>
              <br />
              <div className="FormSect">
                Is it painful for your child to eat or drink?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="child"
                          name="child"
                          onClick={(e) => setIsChild("Yes)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Sometimes"
                          type="radio"
                          id="child"
                          name="child"
                          onClick={(e) => setIsChild("Sometimes)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="child"
                          name="child"
                          onClick={(e) => setIsChild("No)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Do not know"
                          type="radio"
                          id="child"
                          name="child"
                          onClick={(e) => setIsChild("Do not know)")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Does your child's restricted mouth opening make eating or
                drinking difficult?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="Restricted"
                          name="Restricted"
                          onClick={(e) => setIsRestricted("Yes)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Sometimes"
                          type="radio"
                          id="Restricted"
                          name="Restricted"
                          onClick={(e) => setIsRestricted("Sometimes)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="Restricted"
                          name="Restricted"
                          onClick={(e) => setIsRestricted("No)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Don't know"
                          type="radio"
                          id="Restricted"
                          name="Restricted"
                          onClick={(e) => setIsRestricted("Do not know)")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Do you include your child in communal mealtimes?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="mealtimes"
                          name="mealtimes"
                          onClick={(e) => setIsMealtimes("Yes)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Sometimes"
                          type="radio"
                          id="mealtimes"
                          name="mealtimes"
                          onClick={(e) => setIsMealtimes("Sometimes)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="mealtimes"
                          name="mealtimes"
                          onClick={(e) => setIsMealtimes("No)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Do not know"
                          type="radio"
                          id="mealtimes"
                          name="mealtimes"
                          onClick={(e) => setIsMealtimes("Do not know)")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Do people make fun of the way your child eats, drinks or speaks?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="speaks"
                          name="speaks"
                          onClick={(e) => setIsSpeaks("Yes)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Sometimes"
                          type="radio"
                          id="speaks"
                          name="speaks"
                          onClick={(e) => setIsSpeaks("Sometimes)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="speaks"
                          name="speaks"
                          onClick={(e) => setIsSpeaks("No)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Do not know"
                          type="radio"
                          id="speaks"
                          name="speaks"
                          onClick={(e) => setIsSpeaks("Do not know)")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Can your child smile and speak without pain?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="speaks"
                          name="speaks"
                          onClick={(e) => setIsSpeaks("Yes)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Sometimes"
                          type="radio"
                          id="without"
                          name="without"
                          onClick={(e) => setIsWithout("Sometimes)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="without"
                          name="without"
                          onClick={(e) => setIsWithout("No)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Do not know"
                          type="radio"
                          id="without"
                          name="without"
                          onClick={(e) => setIsWithout("Do not know)")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Does your child like the way they look?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="look"
                          name="look"
                          onClick={(e) => setIsLook("Yes)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Sometimes"
                          type="radio"
                          id="look"
                          name="look"
                          onClick={(e) => setIsLook("Sometimes)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="look"
                          name="look"
                          onClick={(e) => setIsLook("No)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Do not know"
                          type="radio"
                          id="look"
                          name="look"
                          onClick={(e) => setIsLook("Do not know)")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Physical health- In the past month, has your child's physical
                health been good?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="look"
                          name="look"
                          onClick={(e) => setIsPastMonth("Yes)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Sometimes"
                          type="radio"
                          id="pastmonth"
                          name="pastmonth"
                          onClick={(e) => setIsPastMonth("Sometimes)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="pastmonth"
                          name="pastpastmonth"
                          onClick={(e) => setIsPastMonth("No)")}
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Do not know"
                          type="radio"
                          id="pastpastmonth"
                          name="pastpastmonth"
                          onClick={(e) => setIsPastMonth("Do not know)")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Physical health- How many days in the past month has your
                child's physical health not been good?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="good"
                          name="good"
                          onClick={(e) => setIsGood("Yes)")}
                        />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="good"
                          name="good"
                          onClick={(e) => setIsGood("No")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Physical health- In the past month, has physical pain stopped
                your child from doing their usual activities such as playing,
                going to school etc.?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="stopped"
                          name="stopped"
                          onClick={(e) => setIsStopped("Yes")}
                        />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="stopped"
                          name="stopped"
                          onClick={(e) => setIsStopped("No")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Physical health- How many days in the past month has your
                child's physical pain stopped them doing their usual activities?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="usual"
                          name="usual"
                          onClick={(e) => setIsUsual("Yes")}
                        />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="usual"
                          name="usual"
                          onClick={(e) => setIsUsual("No")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Independence- Do you feel noma has limited your child's ability
                to take care of themselves?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="ability"
                          name="ability"
                          onClick={(e) => setIsAbility("Yes")}
                        />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="ability"
                          name="ability"
                          onClick={(e) => setIsAbility("No")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Social inclusion- Do people exclude your child from social
                activities due to noma?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="exclude"
                          name="exclude"
                          onClick={(e) => setIsExclude("Yes")}
                        />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="exclude"
                          name="exclude"
                          onClick={(e) => setIsExclude("No")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Social inclusion- Have your family been left out of communal
                activities because of your child's noma?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="SocialInclusion"
                          name="SocialInclusion"
                          onClick={(e) => setIsSocialCommunal("Yes")}
                        />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="SocialInclusion"
                          name="SocialInclusion"
                          onClick={(e) => setIsSocialCommunal("No")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Social inclusion- Do people treat your child the same as they
                treat other community members?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="treatother"
                          name="treatother"
                          onClick={(e) => setIsTreatOther("No")}
                        />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id=" treatother"
                          name="treatother"
                          onClick={(e) => setIsTreatOther("No")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Social inclusion- Are you and your child included in religious/
                spiritual gatherings?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="gatherings"
                          name="gatherings"
                          onClick={(e) => setIsGatherings("Yes")}
                        />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="gatherings"
                          name="gatherings"
                          onClick={(e) => setIsGatherings("No")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect">
                Mental health- In the past two weeks, has your child felt sad,
                anxious or worried?
              </div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Yes"
                          type="radio"
                          id="worried"
                          name="worried"
                          onClick={(e) => setIsWorried("Yes")}
                        />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="No"
                          type="radio"
                          id="worried"
                          name="worried"
                          onClick={(e) => setIsWorried("No")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>

              <div className="FormSect">
                How many days in the past two weeks has your child felt sad,
                anxious or worried?
              </div>
              <div className="FormSect-Contianer00">
                <div className="container-fluid p-0 FormInput">
                  <div className="row gx-4">
                    <div className="col-lg">
                      <TextField
                        fullWidth
                        label="Enter here"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsChildFelt(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect">Psychosocial needs</div>
              <div className="FormSect">Psychosocial needs of the patient</div>
              <div className="FormSect-Contianer00">
                <Form>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Psychological support"
                          type="radio"
                          id="Psychological"
                          name="Psychological"
                          onClick={(e) =>
                            setIsPsychosocial("Psychological support")
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Nutritional support"
                          type="radio"
                          id="Psychological"
                          name="Psychological"
                          onClick={(e) =>
                            setIsPsychosocial("Nutritional support")
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Medical treatment
                          
"
                          type="radio"
                          id="Psychological"
                          name="Psychological"
                          onClick={(e) =>
                            setIsPsychosocial("Medical treatment")
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Short term housing for patient and caretaker
"
                          type="radio"
                          id="Psychological"
                          name="Psychological"
                          onClick={(e) =>
                            setIsPsychosocial(
                              "Short term housing for patient and caretaker"
                            )
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Reintegration with family"
                          type="radio"
                          id="Psychological"
                          name="Psychological"
                          onClick={(e) =>
                            setIsPsychosocial("Reintegration with family")
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Vocational training"
                          type="radio"
                          id="Psychological"
                          name="Psychological"
                          onClick={(e) =>
                            setIsPsychosocial("Vocational training")
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Schooling/ university
"
                          type="radio"
                          id="Psychological"
                          name="Psychological"
                          onClick={(e) =>
                            setIsPsychosocial("Schooling/ university")
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Acquiring land for farming
"
                          type="radio"
                          id="Psychological"
                          name="Psychological"
                          onClick={(e) =>
                            setIsPsychosocial("Acquiring land for farming")
                          }
                        />
                      </div>
                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Settled in grant (e.g. for purchase of sewing machine after tailor training)"
                          type="radio"
                          id="Psychological"
                          name="Psychological"
                          onClick={(e) =>
                            setIsPsychosocial(
                              "Settled in grant (e.g. for purchase of sewing machine after tailor training)"
                            )
                          }
                        />
                      </div>

                      <div className="col-lg-3 CheckSheet">
                        <Form.Check
                          label="Other
"
                          type="radio"
                          id="Psychological"
                          name="Psychological"
                          onClick={(e) => setIsPsychosocial("Other")}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </Form>
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">Presenting Concerns</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>

                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      {/* <TextField
                        fullWidth
                        id="outlined-multiline-static "
                        label="Presenting Concerns"
                        multiline
                        rows={5}
                        value={presentingConcern}
                        onChange={(e) => setPresentingConcern(e.target.value)}
                        // defaultValue="Default Value"
                      /> */}
                      {/* Copy Editor */}
                      <RichTextEditor
                        className="editor"
                        value={value}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">Relevant Medical History</div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      {/* <TextField
                        fullWidth
                        id="outlined-multiline-static "
                        label="Relevant Medical History"
                        multiline
                        rows={5}
                        value={relevantMedicalHistory}
                        onChange={(e) => setRelevantMedicalHistory(e.target.value)}
                        // defaultValue="Default Value"
                      /> */}
                      <RichTextEditor
                        className="editor"
                        value={value2}
                        onChange={onChange2}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="FormSect-Contianer">
                <div className="FormSect">Psychosocial factors </div>
                <div className="FormSect-Note">
                  Please fill all the required field
                </div>
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      {/* <TextField
                        fullWidth
                        id="outlined-multiline-static "
                        label="Psychosocial factors"
                        multiline
                        rows={5}
                        value={psychosocialFactors}
                        onChange={(e) => setPsychosocialFactors(e.target.value)}
                        // defaultValue="Default Value"
                      /> */}
                      <RichTextEditor
                        className="editor"
                        value={value3}
                        onChange={onChange3}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {loading ? (
                <Bars
                  heigth="50"
                  width="50"
                  color="gray"
                  ariaLabel="loading-indicator"
                />
              ) : (
                <button className="Add-Button" onClick={handleAdd}>
                  Add Patient Assessment
                </button>
              )}
              <br /> <br /> <br />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default AssessmentModal;
