import { configureStore } from '@reduxjs/toolkit'
  
import loginSlice from '../features/auth/loginSlice'
import usersSlice from '../features/admins/usersSlice';
import doctorsSlice from '../features/doctors/doctorsSlice';
import nursesSlice from '../features/nurses/nursesSlice';
import patientsSlice from '../features/patients/patientsSlice';
import staffsSlice from '../features/staff/staffsSlice';
import patientSlice from '../features/patients/patientSlice';
import patientsRecordsSlice from '../features/patients/patientsRecordsSlice';
import patientRecordSlice from '../features/patients/patientRecordSlice';
import patientLapsSlice from '../features/patients/patientLapsSlice';
import patientPrescriptionsSlice from '../features/patients/patientPrescriptionsSlice';
import patientCountSlice from '../features/patients/patientCountSlice';
import nurseCountSlice from '../features/nurses/nurseCountSlice';
import doctorCountSlice from '../features/doctors/doctorCountSlice';
import patientAdmissionCountSlice from '../features/patients/patientAdmissionCountSlice';
import patientDischargedCountSlice from '../features/patients/patientDischargedCountSlice';
import patientQuarantineCountSlice from '../features/patients/patientQuarantineCountSlice';
import patientTreatmentLapsSlice from '../features/patients/patientTreatmentLapsSlice';
import patientListLapsSlice from '../features/patients/patientListLapsSlice';
import drugsSlice from '../features/pharmacy/drugsSlice';
import drugCountSlice from '../features/pharmacy/drugCountSlice';
import drugExpiredCountSlice from '../features/pharmacy/drugExpiredCountSlice';
import drugOutCountSlice from '../features/pharmacy/drugOutCountSlice';
import patientDrugsSlice from '../features/patients/patientDrugsSlice';
import patientMedicationSlice from '../features/patients/patientMedicationSlice';
import staffCountSlice from '../features/staff/staffCountSlice';
import patientPrescriptionsTreatmentSlice from '../features/patients/patientPrescriptionsTreatmentSlice';
import patientVitalsSlice from '../features/patients/patientVitalsSlice';
import patientAllergiesSlice from '../features/patients/patientAllergiesSlice';
import preOpCountSlice from '../features/patients/preOpCountSlice';
import preOpSlice from '../features/patients/preOpSlice';
import postOpSlice from '../features/patients/postOpSlice';
import postOpVitalsSlice from '../features/patients/postOpVitalsSlice';
import physiotherapySlice from '../features/patients/physiotherapySlice';
import patientSurgeonSlice from '../features/patients/patientSurgeonSlice';
import patientAnasetheticSlice from '../features/patients/patientAnasetheticSlice';
import patientAssessmentSlice from '../features/patients/patientAssessmentSlice';
import treatmentPlanSlice from '../features/patients/socials/treatmentPlanSlice';
import progressNoteSlice from '../features/patients/socials/progressNoteSlice';
import collaborativeSlice from '../features/patients/socials/collaborativeSlice';
import socialMedicationSlice from '../features/patients/socials/socialMedicationSlice';
import dischargeSummariesSlice from '../features/patients/socials/dischargeSummariesSlice';
import consumablesSlice from '../features/consumables/consumablesSlice';
import nonConsumablesSlice from '../features/nonConsumables/nonConsumablesSlice';
import consumableCountSlice from '../features/consumables/consumableCountSlice';
import nonConsumableCountSlice from '../features/nonConsumables/nonConsumableCountSlice';
import consumableOutOfStockCountSlice from '../features/consumables/consumableOutOfStockCountSlice';
import consumableExpiredCountSlice from '../features/consumables/consumableExpiredCountSlice';
import nonConsumableExpiredCountSlice from '../features/nonConsumables/nonConsumableExpiredCountSlice';
import nonConsumableOutOfStockCountSlice from '../features/nonConsumables/nonConsumableOutOfStockCountSlice';
import releaseConsumablesSlice from '../features/consumables/releaseConsumablesSlice';
import releasedConsumableCountSlice from '../features/consumables/releasedConsumableCountSlice';
import releaseNonConsumablesSlice from '../features/nonConsumables/releaseNonConsumablesSlice';
import releasedNonConsumableCountSlice from '../features/nonConsumables/releasedNonConsumableCountSlice';
import expiryConsumablesSlice from '../features/consumables/expiryConsumablesSlice';
import outOfStockConsumablesSlice from '../features/consumables/outOfStockConsumablesSlice';
import expiryNonConsumablesSlice from '../features/nonConsumables/expiryNonConsumablesSlice';
import outOfStockNonConsumablesSlice from '../features/nonConsumables/outOfStockNonConsumablesSlice';
import fluidChartsSlice from '../features/patients/fluids/fluidChartsSlice';
import fluidChartsCountSlice from '../features/patients/fluids/fluidChartsCountSlice';
import fluidChartsItemSlice from '../features/patients/fluids/fluidChartsItemSlice';
import patientXraysSlice from '../features/patients/docs/patientXraysSlice';
import patientCTScansSlice from '../features/patients/docs/patientCTScansSlice';
import patientMRIsSlice from '../features/patients/docs/patientMRIsSlice';
import patientUltrasoundSlice from '../features/patients/docs/patientUltrasoundSlice';
import xRayCountSlice from '../features/patients/docs/xRayCountSlice';
import ultransoundCountSliceCopy from '../features/patients/docs/ultransoundCountSlice';
import mRICountSlice from '../features/patients/docs/mRICountSlice';
import cTScanCountSlice from '../features/patients/docs/cTScanCountSlice';
import expiredDrugsSlice from '../features/pharmacy/expiredDrugsSlice';
import outOfStockDrugsSlice from '../features/pharmacy/outOfStockDrugsSlice';
import patientDrugsSumSlice from '../features/patients/stats/patientDrugsSumSlice';
import dispenseDrugsSlice from '../features/pharmacy/dispenseDrugsSlice';
import patientLapCommentsSlice from '../features/patients/patientLapCommentsSlice';
import allTestCountSlice from '../features/lap/count/allTestCountSlice';
import conductedCountSlice from '../features/lap/count/conductedCountSlice';
import waitingCountSlice from '../features/lap/count/waitingCountSlice';
import patientSurgeonCountSlice from '../features/patients/counts/patientSurgeonCountSlice';
import patientAnasetheticCountSlice from '../features/patients/counts/patientAnasetheticCountSlice';
import sysDoctorCountSlice from '../features/admins/counts/sysDoctorCountSlice';
import sysNurseCountSlice from '../features/admins/counts/sysNurseCountSlice';
import sysPharmacistCountSlice from '../features/admins/counts/sysPharmacistCountSlice';
import sysLapCountSlice from '../features/admins/counts/sysLapCountSlice';
import patientDietcianSlice from '../features/patients/theatre/patientDietcianSlice';
import patientDietcianCountSlice from '../features/patients/theatre/counts/patientDietcianCountSlice';
import patientDentistCountSlice from '../features/patients/theatre/counts/patientDentistCountSlice';
import patientDentistSlice from '../features/patients/theatre/patientDentistSlice';
import patientDocNotesSlice from '../features/patients/clinical/patientDocNotesSlice';
import patientsSurgicalSafetySlice from '../features/patients/patientsSurgicalSafetySlice';
import nursesReportsSlice from '../features/nurses/nursesReportsSlice';
import nurseReportsCountSlice from '../features/nurses/nurseReportsCountSlice';
import patientPreAnaestheticSlice from '../features/patients/patientPreAnaestheticSlice';
import patientPreAnasetheticCountSlice from '../features/patients/counts/patientPreAnasetheticCountSlice';
import patientIntraoperativeSlice from '../features/patients/patientIntraoperativeSlice';
import patientIntraoperativesCountSlice from '../features/patients/counts/patientIntraoperativesCountSlice';
import patientOperativeReportSlice from '../features/patients/patientOperativeReportSlice';
import patientOperativeReportsCountSlice from '../features/patients/counts/patientOperativeReportsCountSlice';
import patientClinicalDischargeSlice from '../features/patients/patientClinicalDischargeSlice';
import patientClinicalDischargesCountSlice from '../features/patients/counts/patientClinicalDischargesCountSlice';
import patientClinicalNotesCountSlice from '../features/patients/clinical/count/patientClinicalNotesCountSlice';


  const store = configureStore({
    reducer: {
        login: loginSlice,
        users: usersSlice,
        doctors: doctorsSlice,
        nurses: nursesSlice,
        patients: patientsSlice,
        staffs: staffsSlice,
        patient: patientSlice,
        patientRecords: patientsRecordsSlice,
        patientRecord: patientRecordSlice,
        patientLaps: patientLapsSlice,
        patientPrescriptions: patientPrescriptionsSlice,
        patientCount: patientCountSlice,
        nurseCount: nurseCountSlice,
        doctorCount: doctorCountSlice,
        patientAdmissionCount: patientAdmissionCountSlice,
        patientDischargedCount: patientDischargedCountSlice,
        patientQuarantineCount: patientQuarantineCountSlice,
        patientTreatmentLaps: patientTreatmentLapsSlice,
        patientAllLaps: patientListLapsSlice,
        drugs: drugsSlice,
        drugCount: drugCountSlice,
        drugExpiredCount: drugExpiredCountSlice,
        drugOutCount: drugOutCountSlice,
        patientDrugs: patientDrugsSlice,
        patientMedication: patientMedicationSlice,
        staffCount: staffCountSlice,
        patientPrescriptionsTreatment: patientPrescriptionsTreatmentSlice,
        patientVitals: patientVitalsSlice,
        patientAllergies: patientAllergiesSlice,
        preOpsList: preOpSlice,
        preOpCount: preOpCountSlice,
        postOpsList: postOpSlice,
        postOpVitalsList: postOpVitalsSlice,
        physiotherapiesList: physiotherapySlice,
        patientSurgeon: patientSurgeonSlice,
        patientAnasethetic: patientAnasetheticSlice,
        patientAssessment: patientAssessmentSlice,

        treatmentPlans: treatmentPlanSlice,
        progressNotes: progressNoteSlice,
        collaboratives: collaborativeSlice,
        socialMedications: socialMedicationSlice,
        dischargeSummaries: dischargeSummariesSlice,
        consumables: consumablesSlice,
        nonConsumables: nonConsumablesSlice,
        consumableCount: consumableCountSlice,
        nonConsumableCount: nonConsumableCountSlice,
        consumableOutOfStockCount: consumableOutOfStockCountSlice,
        consumableExpiredCount: consumableExpiredCountSlice,
        nonConsumableExpiredCount: nonConsumableExpiredCountSlice,
        nonConsumableOutOfStockCount: nonConsumableOutOfStockCountSlice,
        releaseConsumables: releaseConsumablesSlice,
        releasedConsumableCount: releasedConsumableCountSlice,
        releaseNonConsumables: releaseNonConsumablesSlice,
        releasedNonConsumableCount: releasedNonConsumableCountSlice,
        expiryConsumables: expiryConsumablesSlice,
        outOfStockConsumables: outOfStockConsumablesSlice,
        expiryNonConsumables: expiryNonConsumablesSlice,
        outOfStockNonConsumables: outOfStockNonConsumablesSlice,
        fluidCharts: fluidChartsSlice,
        fluidChartsCount: fluidChartsCountSlice,
        fluidChartsItems: fluidChartsItemSlice,
        patientXrays: patientXraysSlice,
        patientCTScans: patientCTScansSlice,
        patientMRIs: patientMRIsSlice,
        patientUltrasounds: patientUltrasoundSlice,
        xrayCount: xRayCountSlice,
        ultransoundCount: ultransoundCountSliceCopy,
        mriCount: mRICountSlice,
        ctScanCount: cTScanCountSlice,
        expiredDrugs: expiredDrugsSlice,
        outOfStockDrugs: outOfStockDrugsSlice,
        patientDrugsSum: patientDrugsSumSlice,
        dispenseDrugs: dispenseDrugsSlice,
        patientLapComments: patientLapCommentsSlice,
        allTestCount: allTestCountSlice,
        conductedCount: conductedCountSlice,
        waitingCount: waitingCountSlice,
        patientSurgeonCount: patientSurgeonCountSlice,
        patientAnasetheticCount: patientAnasetheticCountSlice,
        sysDoctorCount: sysDoctorCountSlice,
        sysNurseCount: sysNurseCountSlice,
        sysPharmacistCount: sysPharmacistCountSlice,
        sysLapCount: sysLapCountSlice,
        patientDietcian: patientDietcianSlice,
        patientDietcianCount: patientDietcianCountSlice,
        patientDentistCount: patientDentistCountSlice,
        patientDentist: patientDentistSlice,
        patientDocNotes: patientDocNotesSlice,
        patientsSurgicalSafety: patientsSurgicalSafetySlice,
        nurseReports: nursesReportsSlice,
        nurseReportsCount: nurseReportsCountSlice,
        patientPreAnaesthetics: patientPreAnaestheticSlice,
        patientPreAnasetheticCount: patientPreAnasetheticCountSlice,
        patientIntraoperatives: patientIntraoperativeSlice,
        patientIntraoperativesCount: patientIntraoperativesCountSlice,
        patientOperativeReport: patientOperativeReportSlice,
        patientOperativeReportsCount: patientOperativeReportsCountSlice,
        patientClinicalDischarge: patientClinicalDischargeSlice,
        patientClinicalDischargesCount: patientClinicalDischargesCountSlice,
        patientClinicalNotesCount: patientClinicalNotesCountSlice,
    }
  })

  export default store;