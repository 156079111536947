import { React, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import moment from "moment";
import Form from "react-bootstrap/Form";

function ViewAssessmentModal({ show, onClose, selected }) {
  const [viewPreOpNoteDetailsShow, setViewPreOpNoteDetailsShow] =
    useState(false);
  const handleViewPreOpNoteDetails = () => setViewPreOpNoteDetailsShow(false);

  const handleSubComment = () => {
    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const handleSub = () => {
    onClose();
    setViewPreOpNoteDetailsShow(false);

    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const showDate = (passDate) => {
    return moment(passDate).format("DD-MM-YYYY");
  };

  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera-Modal-Container">
                <br />
                <div className="Report-Display-Heading">
                  {" "}
                  Patient's Assessment
                </div>
                <div className="Report-Tittle">Patient's Assessment</div> <br />
                <div>
                  <div className="Doctors-Name">
                    Social welfare's name: &nbsp;&nbsp;
                    <span>{selected?.user?.full_name}</span>
                  </div>
                  <div className="Report-Date">
                    Date : &nbsp;&nbsp;
                    <span>{showDate(selected?.created_at)}</span>
                  </div>
                  {/* <div className="Report-Date">
                    Discharge Summary Number : &nbsp;&nbsp;
                    <span> {selected?.code}</span>
                  </div> */}
                </div>
                <br /> <br /> <br />
                <div className="Solera-Modal-Container">
                  <div>
                    <div>
                      <div className="Report-Notes2">
                        <div>
                          <div>
                            {" "}
                            <div>
                              <div className="Solera__ModalHeadings"></div>
                              <div className="Solera-Modal-Container">
                                <div className="Solera__Headings">
                                  <div className="Log__Cont">
                                    <img
                                      src="/assets/lg.png"
                                      alt=""
                                      className="Log__Img__Modal"
                                    />
                                  </div>
                                  <div className="Solera__Headings__Note">
                                    <div className="Solera-Settings-Header">
                                      Patient's Assessment
                                    </div>
                                  </div>
                                </div>
                                <br />
                                <br />
                                <div className="FormSect">
                                  If care was sought before arriving here today,
                                  where was it sought?
                                </div>
                            
                                <div className="CheckSheet">
                                  {selected?.if_care}
                                </div>
                                <br />
                                <br />
                                <div className="FormSect">
                                  What treatment was offered?
                                </div>
                              
                                <div className="CheckSheet">
                                  {selected?.is_all}
                                </div>
                                <br />
                                <br />
                                <div className="FormSect">
                                  How did you hear about our services?
                                </div>
                              
                                <div className="CheckSheet">
                                  {selected?.is_how_did}
                                </div>
                                <br /> 
                                <div className="CheckSheet">Note Display</div>
                                <br />
                                <br />
                                <div className="FormSect">
                                  Did you face any difficulties getting here
                                  today?
                                </div>
                             
                                <div className="CheckSheet">
                                  {selected?.is_how_did}
                                </div>
                                <br />
                                <br />
                                <div className="FormSect">
                                  If yes, what were the difficulties
                                </div>
                              
                                {/* <div className="CheckSheet">
                                  Display Checked box
                                </div> */}
                             
                                <div className="CheckSheet">{selected?.is_how_did}</div>
                                <br />
                                <br />
                                <div className="FormSect">
                                  <b>Financial impacts</b>- Has noma impacted
                                  you/ your family's ability to work and earn a
                                  living?
                                </div>
                               
                                <div className="CheckSheet">
                                  {selected?.is_financial_impacts}
                                </div> <br /> <br />
                                <div className="FormSect">
                                  If so, how? *Select all that apply
                                </div>
                                <div className="CheckSheet">
                                  {selected?.is_if_so_how}
                                </div>
                                <br /> <br />
                                <div className="FormSect">
                                  Did you have to sell anything to get to or
                                  afford care for noma?
                                </div>
                             
                                <div className="CheckSheet">
                                  {selected?.is_did_you_have}
                                </div>
                                <br /> <br />
                                <div className="FormSect">
                                  <b>Financial impacts</b>- Has noma impacted
                                  you/ your family's ability to work and earn a
                                  living?
                                </div>
                              
                                <div className="CheckSheet">
                                  {selected?.is_financial_impacts}
                                </div> <br /> <br />
                                <div className="FormSect">
                                  If yes, what did you have to sell?
                                </div>
                             
                                <div className="CheckSheet">
                                  {selected?.is_yes_how}
                                </div>
                                <br /> <br />
                                <div className="FormSect">
                                  Do you know any other people who have noma?
                                </div>
                           
                                <div className="CheckSheet">
                                  {selected?.is_other_people}
                                </div>
                                <br /> <br />
                                <div>
                                  {" "}
                                  contact details *(such as address or phone
                                  number)
                                </div>
                                <div className="CheckSheet"> {selected?.is_yes_contact}</div>
                                <br />
                                <br />
                                <div className="FormSect">Quality of life</div>
                             
                                <div className="FormSect">
                                  Who will be answering the quality of life
                                  questions?
                                </div>
                              
                                <div className="CheckSheet">
                                  {selected?.is_who_will}
                                </div>
                                <br />
                                <br />
                               
                                <div className="FormSect">
                                  Quality of life (Patient)
                                </div>
                              
                                <div className="FormSect">
                                  Is it painful when you eat or drink?
                                </div>
                             
                                <div className="CheckSheet">
                                  {selected?.is_it_painful}
                                </div>
                                <br />
                                <div className="FormSect">
                                  Does your restricted mouth opening make eating
                                  or drinking difficult?
                                </div>
                               
                                <div className="CheckSheet">
                                  {selected?.is_does_your_restricted}
                                </div>
                                <br /> <br />
                                <div className="FormSect">
                                  Do your family/ household members include you
                                  in communal mealtimes?
                                </div>
                                <div className="CheckSheet">
                                {selected?.is_do_your_family}
                                </div>
                                <br /> <br />
                                <div className="FormSect">
                                  Can you smile and speak without pain?
                                </div>
                                <div className="CheckSheet">
                                  {selected?.is_can_you_smile}
                                </div>
                                <br /> <br />
                                <div className="FormSect">
                                  Do you like the way you look?
                                </div>
                                <div className="CheckSheet">
                                  {selected?.is_do_you_like}
                                </div>
                                <br /> <br />
                                <div className="FormSect">
                                  Physical health- In the past month, has your
                                  physical health been good?
                                </div>
                                <div className="CheckSheet">
                                  {selected?.is_physical_health}
                                </div>
                                <br /> <br />
                                <div className="FormSect">
                                  Is it painful when you eat or drink?
                                </div>
                                <div className="CheckSheet">
                                  {selected?.is_painful_drink}
                                </div>
                                <br /> <br />
                                <div>
                                  Physical health- How many days in the past
                                  month has your physical health not been good?
                                  <div className="CheckSheet">{selected?.is_how_many_days}</div>
                                  <br /> <br />
                                  <div className="FormSect">
                                    Physical health- How many days in the past
                                    month has your physical pain stopped you
                                    from doing your usual activities?
                                  </div>
                                  <div className="CheckSheet">{selected?.is_health_activities}</div>
                                  <br /> <br />
                                  <div className="FormSect">
                                    Physical health- How much pain are you in
                                    today? *1 is none and 7 is unbearable pain
                                  </div>
                                  <div className="CheckSheet">{selected?.is_how_much_pain}</div>
                                  <br /> <br />
                                  <div className="FormSect">
                                    Independence- Do you feel noma has limited
                                    your ability to take care of yourself?
                                  </div>
                                  <div className="CheckSheet">
                                    {selected?.is_independence}
                                  </div>
                                  <br /> <br />
                                  <div className="FormSect">
                                    Independence- What would help you to reduce
                                    the feeling of not being able to take care
                                    of yourself?
                                  </div>
                                  <div className="CheckSheet">
                                    {selected?.is_what_would}
                                  </div>
                                  <br /> <br />
                                  <div className="FormSect">
                                    Social inclusion- Do people exclude you from
                                    social activities due to noma?
                                  </div>
                                  <div className="CheckSheet">
                                    {selected?.is_social_communal}
                                  </div>
                                  <br /> <br />
                                  <div className="FormSect">
                                    Social inclusion- Have your family been left
                                    out of communal activities because of your
                                    noma diagnosis?
                                  </div>
                                  <div className="CheckSheet">
                                    {selected?.is_communal}
                                  </div>
                                  <br /> <br />
                                  <div className="FormSect">
                                    Social inclusion- Do people treat you the
                                    same as they treat other community members?
                                  </div>
                                  <div className="CheckSheet">
                                    {selected?.is_do_people}
                                  </div>
                                  <br /> <br />
                                  <div className="FormSect">
                                    Social inclusion- Do you feel your prospects
                                    for love relationships/ marriage etc are
                                    negatively impacted by No noma?
                                  </div>
                                  <div className="CheckSheet">
                                    {selected?.is_prospects}
                                  </div>
                                  <br /> <br />
                                  <div className="FormSect">
                                    Social inclusion- Are you included in
                                    religious/ spiritual gatherings?
                                  </div>
                                  <div className="CheckSheet">
                                    {selected?.is_religious}
                                  </div>
                                  <br /> <br />
                                  <div className="FormSect">
                                    Mental health- How do you feel about your
                                    life now?
                                  </div>
                                  <div className="CheckSheet">
                                    {selected?.is_mental}
                                  </div>
                                  <br /> <br />
                                  <div className="FormSect">
                                    Mental health- In the past two weeks, have
                                    you felt sad, anxious, worried?
                                  </div>
                                  <div className="CheckSheet">
                                    {selected?.is_anxious}
                                  </div>
                                  <br /> <br />
                                  <div className="FormSect">
                                    How many days in the past two weeks, have
                                    you felt sad, anxious, worried?
                                  </div>
                                  <div className="CheckSheet">
                                  {selected?.is_days}
                                  </div>
                                  <br /> <br />
                                  <div className="FormSect">
                                    How happy are you with your life (1 you are
                                    very happy and 7 you are very unhappy)?
                                  </div>
                                  <div className="CheckSheet">
                                    {selected?.is_happy}
                                  </div>
                          
                                  <br />
                                  <br />
                                  <div className="FormSect">
                                    Quality of life (Caretaker cpompleting ,
                                    "your child" is the noma Patient)
                                  </div>
                                  <br />
                                  <br />
                                  <div className="FormSect">
                                    Is it painful for your child to eat or
                                    drink?
                                  </div>
                                  <div className="CheckSheet">
                                    {selected?.is_child}
                                  </div>
                                  <br />
                                  <br />
                                  <div className="FormSect">
                                    Does your child's restricted mouth opening
                                    make eating or drinking difficult?
                                  </div>
                                  <div className="CheckSheet">
                                    {selected?.is_restricted}
                                  </div>
                                  <br />
                                  <br />
                                  <div className="FormSect">
                                    Do you include your child in communal
                                    mealtimes?
                                  </div>
                                  <div className="CheckSheet">
                                    {selected?.is_meal_times}
                                  </div>
                                  <br />
                                  <br />
                                  <div className="FormSect">
                                    Do people make fun of the way your child
                                    eats, drinks or speaks?
                                  </div>
                                  <div className="CheckSheet">
                                    {selected?.is_speaks}
                                  </div>
                                  <br />
                                  <br />
                                  <div className="FormSect">
                                    Can your child smile and speak without pain?
                                  </div>
                                  <div className="CheckSheet">
                                    {selected?.is_without}
                                  </div>
                                  <br />
                                  <br />
                                  <div className="FormSect">
                                    Does your child like the way they look?
                                  </div>
                                  <div className="CheckSheet">
                                    {selected?.is_look}
                                  </div>
                                  <br />
                                  <br />
                                  <div className="FormSect">
                                    Physical health- In the past month, has your
                                    child's physical health been good?
                                  </div>
                                  <div className="CheckSheet">
                                    {selected?.is_past_month}
                                  </div>
                                  <br />
                                  <br />
                                  <div className="FormSect">
                                    Physical health- How many days in the past
                                    month has your child's physical health not
                                    been good?
                                  </div>
                                  <div className="CheckSheet">
                                    {selected?.is_good}
                                  </div>
                                  <br />
                                  <br />
                                  <div className="FormSect">
                                    Physical health- In the past month, has
                                    physical pain stopped your child from doing
                                    their usual activities such as playing,
                                    going to school etc.?
                                  </div>
                                  <div className="CheckSheet">
                                    {selected?.is_stopped}
                                  </div>
                                  <br />
                                  <br />
                                  <div className="FormSect">
                                    Physical health- How many days in the past
                                    month has your child's physical pain stopped
                                    them doing their usual activities?
                                  </div>
                                  <div className="CheckSheet">
                                    {selected?.is_usual}
                                  </div>
                                  <br />
                                  <br />
                                  <div className="FormSect">
                                    Independence- Do you feel noma has limited
                                    your child's ability to take care of
                                    themselves?
                                  </div>
                                  <div className="CheckSheet">
                                    {selected?.is_ability}
                                  </div>
                                  <br />
                                  <br />
                                  <div className="FormSect">
                                    Social inclusion- Do people exclude your
                                    child from social activities due to noma?
                                  </div>
                                  <div className="CheckSheet">
                                    {selected?.is_y_exclude}
                                  </div>
                                  <br />
                                  <br />
                                  <div className="FormSect">
                                    Social inclusion- Have your family been left
                                    out of communal activities because of your
                                    child's noma?
                                  </div>
                                  <div className="CheckSheet">
                                    {selected?.is_social_inclusion}
                                  </div>
                                  <br />
                                  <br />
                                  <div className="FormSect">
                                    Social inclusion- Do people treat your child
                                    the same as they treat other community
                                    members?
                                  </div>
                                  <div className="CheckSheet">
                                    {selected?.is_treat_other}
                                  </div>
                                  <br />
                                  <br />
                                  <div className="FormSect">
                                    Social inclusion- Are you and your child
                                    included in religious/ spiritual gatherings?
                                  </div>
                                  <div className="CheckSheet">
                                    {selected?.is_gathering}
                                  </div>
                                  <br />
                                  <br />
                                  <div className="FormSect">
                                    Mental health- In the past two weeks, has
                                    your child felt sad, anxious or worried?
                                  </div>
                                  <div className="CheckSheet">
                                    {selected?.is_worried}
                                  </div>
                                  <br />
                                  <br />
                                  <div className="FormSect">
                                    How many days in the past two weeks has your
                                    child felt sad, anxious or worried?
                                  </div>
                                  <div className="CheckSheet">
                                    {selected?.is_child_felt}
                                  </div>
                                  <br />
                                  <br />
                                  <div className="FormSect">
                                    Psychosocial needs
                                  </div>
                                  <div className="FormSect">
                                    Psychosocial needs of the patient
                                  </div>
                                 
                                  <div className="CheckSheet">
                                    {selected?.is_psychosocial}
                                  </div>
                                  <br />
                                  <br />
                                  <div className="FormSect-Contianer">
                                    <div className="FormSect">
                                      Presenting Concerns
                                    </div>
                                    <div className="FormSect-Note">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: selected?.presenting_concern,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                  <div className="FormSect-Contianer">
                                    <div className="FormSect">
                                      Relevant Medical History
                                    </div>
                                    <div className="FormSect-Note">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            selected?.relevant_medical_history,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                  <div className="FormSect-Contianer">
                                    <div className="FormSect">
                                      Psychosocial factors{" "}
                                    </div>
                                    <div className="FormSect-Note">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            selected?.psychosocial_factors,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />

                          {/* <div className="Report-Display">
                          {" "}
                          Presenting Concerns
                        </div> */}

                          {/* <div className="Report-Date4">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: selected?.presenting_concern,
                            }}
                          ></div>
                        </div>
                        <br />
                        <div className="Report-Display">
                          Relevant Medical History
                        </div>
                       
                        <div className="Report-Date4">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: selected?.relevant_medical_history,
                            }}
                          ></div>
                        </div>

                        <br />
                        <div className="Report-Display">
                          Psychosocial factors
                        </div>
                       
                        <div className="Report-Date4">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: selected?.psychosocial_factors,
                            }}
                          ></div>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default ViewAssessmentModal;
