import { React, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import { getUser } from "../../../../services/AuthService";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import moment from "moment";
import { fetchPhysiotherapy } from "../../../../features/patients/physiotherapySlice";
import { updatePatientClinicalNote } from "../../../../services/PatientClinicalNoteService";

import RichTextEditor from "react-rte"; // Copy Editor

function ViewSurgicalChecklist({ show, onClose, selected }) {
  const [value, setValue] = useState(RichTextEditor.createEmptyValue()); // Copy Editor

  let { id } = useParams();
  const dispatch = useDispatch();
  const user = getUser();
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);

  const [viewPreOpNoteDetailsShow, setViewPreOpNoteDetailsShow] =
    useState(false);
  const handleViewPreOpNoteDetails = () => setViewPreOpNoteDetailsShow(false);

  const showDate = (passDate) => {
    return moment(passDate).format("DD-MM-YYYY");
  };

  const handleUpdate = () => {
    setLoading(true);
    const data = {
      id: selected?._id,
      doc_note: comment,
      doctor: user?.full_name,
    };
    console.log(data);
    updatePatientClinicalNote(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(
            fetchPhysiotherapy({ patientId: id, type: "physiotherapy" })
          );
          setComment("");
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  {
    /* Copy Editor */
  }
  const onChange = (value) => {
    setValue(value);
    value.toString("html");
    setComment(value.toString("html"));
  };

  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera-Modal-Container">
                <br />
                <div className="Report-Display-Heading">
                  Surgical safety checklist{" "}
                </div>
                <div className="Report-Tittle">Surgical safety checklist</div>
                <div className="Solera-Modal-Container">
                  <div className="Note-Modal-Cont">
                    <div>
                      <div className="Doctors-Name">
                        Doctors's name : &nbsp;
                        <span>{selected?.user?.full_name}</span>
                      </div>
                      <div className="Report-Date">
                        Report Date: &nbsp;
                        <span>{showDate(selected?.created_at)}</span>
                      </div>
                      <div className="Report-Date">
                        Note Number: &nbsp;<span>{selected?.code}</span>
                      </div>
                    </div>

                    <br />
                    <div className="Report-Display">Diagnosis</div>
                    <div className="Report-Date4">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: selected?.diagnosis,
                        }}
                      ></div>
                    </div>
                    <br />
                    <div className="Report-Display">Operation</div>
                    <div className="Report-Date4">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: selected?.operation,
                        }}
                      ></div>
                    </div>

                    <div>
                      <div className="Sheet30">
                        Confirm all team members introduction by name and role
                        for each patient listed
                      </div>
                      <div className="Sheet40">Yes or No </div>
                    </div>
                    <br />

                    <div>
                      <div className="Sheet30">
                        Surgeon confirms a specific equipment requirements of
                        investigation
                      </div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">Critical unexpected steps</div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">Essential imaging required</div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">Duration of surgery</div>
                      <div className="Sheet40">Display</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">Anticipated blood loss</div>
                      <div className="Sheet40">Display</div>
                    </div>
                    <br />
                    <div>
                      <div className="Report40">Anaesthetist confirms</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">patient specific</div>
                      <div className="Sheet40">Display</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">SAntibiotics prosphylaxis</div>
                      <div className="Sheet40">Display</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">Blood availability</div>
                      <div className="Sheet40">Display</div>
                    </div>
                    <br />
                    <br />
                    <div>
                      <div className="Report40">Nursing team confirms</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        Review sterility of instruments?(Including indicator
                        status)
                      </div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        Any equipment or staffing concerns
                      </div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div className="Report40">SIGN IN</div>

                    <div>
                      <div className="Sheet30">
                        Has the patient confirmed his /her identity site,
                        procedure, and consent?
                      </div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">Patient label correct?</div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        Is the anesthesia machine, materials and medication
                        check complete?
                      </div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        Is the pulse oximeter of the patient functioning?
                      </div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        Does the patient have a known allergy?
                      </div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        Difficult airway or aspiration risk?
                      </div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        Is equipment and assistance available?
                      </div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        Risk of &gt; 500ml blood loss (7ml/kg in children)?
                      </div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        IVs/central access and fluid planned specialty. specify
                        checklist needed
                      </div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        Appropriate cooling/warming of the <br />
                        theatre?
                      </div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        Confirm all teams have introduced themselves by name and
                        role
                      </div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        To surgeon, anaesthetist, and nurse, confirm patient's
                        name , procedure and incision site
                      </div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        Has antibiotics prophylaxis been given within the last
                        60 mins
                      </div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div className="Report40">ANTICIPATED CRITICAL EVENTS</div>
                    <div className="Report40">To Surgeon</div>

                    <div>
                      <div className="Sheet30">
                        what are the critical or non-routine steps?
                      </div>
                      <div className="Sheet40">Display</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        How long will the case take?
                      </div>
                      <div className="Sheet40">Display</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        what are the critical or non-routine steps?
                      </div>
                      <div className="Sheet40">Display</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        How long will the case take?
                      </div>
                      <div className="Sheet40">Display</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        What is the anticipated blood loss?
                      </div>
                      <div className="Sheet40">Display</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        Review and confirm the correct position of Protheses
                        with Matron
                      </div>
                      <div className="Sheet40">Display</div>
                    </div>
                    <br />
                    <div className="Report40">To Anaesthetist</div>

                    <div>
                      <div className="Sheet30">
                        Are there any patient specific concerns to the nursing
                        team?
                      </div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        Has sterility (including indicator result) been
                        confirmed?
                      </div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        Are there any equipment issues or concerns?
                      </div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />

                    <div className="Report-Display">
                      {" "}
                      Additional checks allergies
                    </div>
                    <div className="Report40">Note allergies Prosthesis</div>
                    <div className="Report-Date4">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: selected?.additional,
                        }}
                      ></div>
                    </div>
                    <br />

                    <div>
                      <div className="Sheet30">
                        Confirm the correct position for the <br />
                        prosthesis
                      </div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        Confirm the correct position for the prosthesis
                        normothermia
                      </div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        Check that it has been monitored and appropriate action
                        taken
                      </div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        Is essential imaging displayed?
                      </div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        Diathermy is functioning and the pad is in place?
                      </div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        Compatibility form of blood available?
                      </div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        Anticoagulation /DVT prophylaxis, mechanical boots or
                        chemical UMWH/Others?
                      </div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div className="Report40">SIGN OUT</div>

                    <div>
                      <div className="Sheet30">
                        Registered practitioner verbally confirms the name of
                        the procedure
                      </div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">Confirm documented</div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        Compilation of instrumemt, sponge and needle counts
                      </div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        Specimen labelled and recorded in specimen
                      </div>
                      <div className="Sheet40">Display</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">
                        Whether there are any equipment problems and addressed
                      </div>
                      <div className="Sheet40">Display</div>
                    </div>
                    <br />
                    <div className="Report40">
                      To Surgeon Anaesthetist and nurse
                    </div>
                    <div className="Report40">
                      What are the key concerns for the recovery and management
                      of this patient?
                    </div>
                    <div>
                      <div className="Sheet30">Mention key concerns</div>
                      <div className="Sheet40">Display</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">Tournquet removed?</div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                    <br />
                    <div>
                      <div className="Sheet30">Throat pack removed?</div>
                      <div className="Sheet40">Yes or No</div>
                    </div>
                  </div>
                </div>
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default ViewSurgicalChecklist;
