import { React, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import moment from "moment";
import ViewDispensedItems from "./ViewDispensedItems";

function DispensedList({dispensed}) {
  const [drugsDetailsShow, setDrugsDetailsShow] = useState(false);
  const handleDrugsDetails = () => setDrugsDetailsShow(false);

  const [editDrugsShow, setEditDrugsShow] = useState(false);
  const handleEditDrugs = () => setEditDrugsShow(false);
  const [selected, setSelected] = useState({});

  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY h:mm A');
  }


  console.log("ALEE", dispensed, selected)

  return (
    <div>
      <div className="Patient-Displays-Details2">
        <div className="PNumber-C">
          <div className="Wallet-Ref-Heading">Item's Name</div>
          <div className="Wallet-Ref-Result">{dispensed?.code}</div>
        </div>

        <div className="PatientNumber-C">
          <div className="Wallet-Ref-Heading">Item's Name </div>
          <div className="Wallet-Ref-Result">
            
            {/* {dispensed?.drug?.name.substring(0, 9)+'...'} */}
            {dispensed?.drug?.name}


          </div>
        </div>
        <div className="Role-Cont-P">
          <div className="Wallet-Ref-Heading">Quantity</div>
          <div className="Wallet-Ref-Result">{dispensed?.qty}</div>
        </div>
        <div className="Role-Cont-Date">
          <div className="Wallet-Ref-Heading">Dispensed Date</div>
       

          <div className="Wallet-Ref-Result">
                {showDate(dispensed?.created_at)}
              </div>
        </div>
        <div className="By-Cont-P">
          <div className="Wallet-Ref-Heading">Reveiver</div>
          <div className="Wallet-Ref-Result">{dispensed?.patient?.full_name}</div>
        </div>
        <div className="By-Cont-P">
          <div className="Wallet-Ref-Heading">Dispensed By</div>
          <div className="Wallet-Ref-Result">{dispensed?.user?.full_name}</div>
        </div>
        <div className="Stat-Cont-P">
          <div className="Wallet-Ref-Heading">Status</div>
          <div className="Wallet-Ref-Result">{dispensed?.status}</div>
        </div>

        <div className="Action-Drop-Down">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className="Action-Drop">
              Manage Dispensed Drugs
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                className="Solera-Patients"
                onClick={() => {setSelected(dispensed); setDrugsDetailsShow(true)}}
              >
                View Details
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div>
        <ViewDispensedItems
          selected={selected}
          show={drugsDetailsShow}
          onClose={handleDrugsDetails}
          onOpen={() => setDrugsDetailsShow(true)}
        />
      </div>
    </div>
  );
}

export default DispensedList;
