import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { patientClinicalNoteCount } from '../../../../services/PatientClinicalNoteService'

const initialState = {
    loading: false,
    count: 0,
}

export const fetchPatienClinicalNotesCount = createAsyncThunk('fetchPatienClinicalNotesCount', (initData) => {
    return patientClinicalNoteCount(initData)
    .then((response) =>  response.data.data)
})


const patientClinicalNotesCountSlice = createSlice({
    name: 'patientClinicalNotesCount',
    initialState,  
    extraReducers: (builder) => {
       builder.addCase(fetchPatienClinicalNotesCount.pending, (state) => {
        state.loading = true
       })
       builder.addCase(fetchPatienClinicalNotesCount.fulfilled, (state, action ) => {
        state.loading = false
        state.count = action.payload
       })
    }
})

export default patientClinicalNotesCountSlice.reducer 