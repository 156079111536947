import { React, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import { getUser } from "../../../../services/AuthService";
import { useDispatch } from "react-redux";
import { addPatientClinicalNote } from "../../../../services/PatientClinicalNoteService";
import { toast } from "react-toastify";
import { fetchPostOps } from "../../../../features/patients/postOpSlice";
import { Bars } from "react-loader-spinner";
import RichTextEditor from "react-rte"; // Copy Editor
import Form from "react-bootstrap/Form";

function PostOpNoteModal({ show, onClose }) {
  const [value, setValue] = useState(RichTextEditor.createEmptyValue()); // Copy Editor

  let { id } = useParams();
  const user = getUser();
  const dispatch = useDispatch();
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);

  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);


  const handleAdd = () => {
    setLoading(true);
    const data = {
      user: user?._id,
      patient: id,
      note: note,
      type: "post Op",
      is_b: isB,
      is_time1: isTime1,
      is_initial1: isInitial1,
      is_bodywash: isBodywash,
      is_time2: isTime2,
      is_initial2: isInitial2,
      is_urine: isUrine,
      is_time3: isTime3,
      is_initial3: isInitial3,
      is_oral: isOral,
      is_time4: isTime4,
      is_initial4: isInitial4,
      is_vital: isVital,
      is_time5: isTime5,
      is_initial5: isInitial5,
      is_pulse: isPulse,
      is_time6: isTime6,
      is_initial6: isInitial6,
      is_temp: isTemp,
      is_time7: isTime7,
      is_initial7: isInitial7,
      is_respRate: isRespRate,
      is_time8: isTime8,
      is_initial8: isInitial8,
      is_ivf_access: isIvfAccess,
      is_time9: isTime9,
      is_initial9:isInitial9,
      is_premedication: isPremedication,
      is_time0: isTime0,
      is_initial0: isInitial0,
    };
    addPatientClinicalNote(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(fetchPostOps({ patientId: id, type: "post Op" }));
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  {
    /* Copy Editor */
  }
  const onChange = (value) => {
    setValue(value);
    value.toString("html");
    setNote(value.toString("html"));
  };


  const [isB, setIfB] = useState();
  const [isTime1, setIsTime1] = useState();
  const [isInitial1, setIsInitial1] = useState();



  // setBodywash  2


  const [isBodywash, setBodywash] = useState();
  const [isTime2, setIsTime2] = useState();
  const [isInitial2, setIsInitial2] = useState();



  const [isUrine, setUrine] = useState();
  const [isTime3, setIsTime3] = useState();
  const [isInitial3, setIsInitial3] = useState();


  const [isOral, setOral] = useState();
  const [isTime4, setIsTime4] = useState();
  const [isInitial4, setIsInitial4] = useState();


  const [isVital, setVital] = useState();
  const [isTime5, setIsTime5] = useState();
  const [isInitial5, setIsInitial5] = useState();

  const [isPulse, setPulse] = useState();
  const [isTime6, setIsTime6] = useState();
  const [isInitial6, setIsInitial6] = useState();


  const [isTemp, setTemp] = useState();
  const [isTime7, setIsTime7] = useState();
  const [isInitial7, setIsInitial7] = useState();

  
  
  
  const [isRespRate, setRespRate] = useState();
  const [isTime8, setIsTime8] = useState();
  const [isInitial8, setIsInitial8] = useState();


  const [isIvfAccess, setIvfAccess] = useState();
  const [isTime9, setIsTime9] = useState();
  const [isInitial9, setIsInitial9] = useState();


  const [isPremedication, setPremedication] = useState();
  const [isTime0, setIsTime0] = useState();
  const [isInitial0, setIsInitial0] = useState();



 

  

  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                    <div className="Solera-Settings-Header">
                   PRE OPERATIVE PREPERATION
                    </div>
                    <div className="Solera-Settings-Note">
                     
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-lg-8 FormSect">TO DO LIST</div>
                    <div className="col-lg-2 FormSect">TIME</div>
                    <div className="col-lg-2 FormSect">INITIAL</div>
                  </div>
                </div>{" "}
                <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-lg-8 CheckSheet">
                      <div className="CheckSheet">
                       

                        <Form.Check
                           type="checkbox"
                          id="IfCareWas"
                          name="IfCareWas"
                          onChange={(e) => setIfB("TO Do")}
                          label="TO Do"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      <TextField
                        fullWidth
                        label="Time"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input "
                        onChange={(e) => setIsTime1(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      {" "}
                      <TextField
                        fullWidth
                        label="Initial"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsInitial1(e.target.value)}
                      />
                    </div>
                  </div>
                </div>{" "}
                <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-lg-8 CheckSheet">
                  
                      <Form.Check
                          type="checkbox"
                          id="Body"
                          name="Body"
                          onChange={(e) => setBodywash("Body wash")}
                          label="Body wash"
                        />
                      
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      <TextField
                        fullWidth
                        label="Time"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input "
                        onChange={(e) => setIsTime2(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      {" "}
                      <TextField
                        fullWidth
                        label="Initial"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsInitial2(e.target.value)}
                      />
                    </div>
                  </div>
                </div>{" "}
                <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-lg-8 CheckSheet">
                 
                      <Form.Check
                           type="checkbox"
                          id="Urine"
                          name="Urine"
                          onChange={(e) => setUrine("Urine")}
                          label="Urine"
                        />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      <TextField
                        fullWidth
                        label="Time"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input "
                        onChange={(e) => setIsTime3(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      {" "}
                      <TextField
                        fullWidth
                        label="Initial"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsInitial3(e.target.value)}
                      />
                    </div>
                  </div>
                </div>{" "}
                <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-lg-8 CheckSheet">
                    
                      <Form.Check
                          type="checkbox"
                          id="Oral"
                          name="Oral"
                          onChange={(e) => setOral("Oral Disinfection")}
                          label="Oral Disinfection"
                        />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      <TextField
                        fullWidth
                        label="Time"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input "
                        onChange={(e) => setIsTime4(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      {" "}
                      <TextField
                        fullWidth
                        label="Initial"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsInitial4(e.target.value)}
                      />
                    </div>
                  </div>
                </div>{" "}
                <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-lg-8 CheckSheet">
                     

                      <Form.Check
                          type="checkbox"
                          id="Vital"
                          name="Vital"
                          onChange={(e) => setVital("VITAL SIGN BP")}
                          label="VITAL SIGN BP"
                        />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      <TextField
                        fullWidth
                        label="Time"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input "
                        onChange={(e) => setIsTime5(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      {" "}
                      <TextField
                        fullWidth
                        label="Initial"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsInitial5(e.target.value)}
                      />
                    </div>
                  </div>
                </div>{" "}
                <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-lg-8 CheckSheet">
                   
                      <Form.Check
                            type="checkbox"
                          id="Pulse"
                          name="Pulse"
                          onChange={(e) => setPulse("PULSE")}
                          label="PULSE"
                        />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      <TextField
                        fullWidth
                        label="Time"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input "
                        onChange={(e) => setIsTime6(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      {" "}
                      <TextField
                        fullWidth
                        label="Initial"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsInitial6(e.target.value)}
                      />
                    </div>
                  </div>
                </div>{" "}
                <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-lg-8 CheckSheet">
                     
                      <Form.Check
                           type="checkbox"
                          id="Temp"
                          name="Temp"
                          onChange={(e) => setTemp("TEMPERATURE")}
                          label="TEMPERATURE"
                        />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      <TextField
                        fullWidth
                        label="Time"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input "
                        onChange={(e) => setIsTime7(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      <TextField
                        fullWidth
                        label="Initial"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsInitial7(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-lg-8 CheckSheet">
                     
                      <Form.Check
                          type="checkbox"
                          id="RespRate"
                          name="RespRate"
                          onChange={(e) => setRespRate("RESP RATE")}
                          label="RESP RATE"
                        />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      <TextField
                        fullWidth
                        label="Time"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input "
                        onChange={(e) => setIsTime8(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      <TextField
                        fullWidth
                        label="Initial"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsInitial8(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-lg-8 CheckSheet">
                      <Form.Check
                         type="checkbox"
                          id="IvfAccess"
                          name="IvfAccess"
                          onChange={(e) => setIvfAccess("IVF ACCESS")}
                          label="IVF ACCESS"
                        />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      <TextField
                        fullWidth
                        label="Time"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input "
                        onChange={(e) => setIsTime9(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      <TextField
                        fullWidth
                        label="Initial"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsInitial9(e.target.value)}
                      />
                    </div>
                  </div>
                </div>{" "}
                <div className="container-fluid p-0 FormInput">
                  <div className="row g-2">
                    <div className="col-lg-8 CheckSheet">
                  
                      <Form.Check
                           type="checkbox"
                          id="Pre-medication"
                          name="Pre-medication"
                          onChange={(e) => setPremedication("Pre-medication")}
                          label="Pre-medication"
                        />
                    </div>
                    <div className="col-lg-2 CheckSheet">
                      <TextField
                        fullWidth
                        label="Time"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input "
                        onChange={(e) => setIsTime0(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-2 CheckSheet">              
                      <TextField
                        fullWidth
                        label="Initial"
                        id="fullWidth"
                        type="text"
                        className="Solera-Login-Input mb-3"
                        onChange={(e) => setIsInitial0(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="FormSect-Contianer">
                  <div className="container-fluid p-0 FormInput">
                    <div className="col">
                      <div className="FormSect">Remarks</div>
                      <br />
                      <RichTextEditor
                        className="editor"
                        value={value}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="FormUpload">
                  {loading ? (
                    <Bars
                      heigth="30"
                      width="30"
                      color="gray"
                      ariaLabel="loading-indicator"
                    />
                  ) : (
                    <button className="Add-Button" onClick={handleAdd}>
                      Submit{" "}
                    </button>
                  )}
                </div>
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default PostOpNoteModal;
