import axios from '../axiosinstance';

      
export function listPatientPreAnaesthetics(initData){
        return axios.get('/patient-pre-anaesthetics', { params: { name: initData?.search, type: initData?.type, patient: initData?.patientId } })
}

export function addPatientPreAnaesthetic(data){
        return axios.post('/patient-pre-anaesthetics', data)
}

export function getPatientPreAnaesthetic(initData){
        return axios.get('/patient-pre-anaesthetics/'+initData?.id)
}

export function updatePatientPreAnaesthetic(id, initData){
        return axios.patch('/patient-pre-anaesthetics/'+id, initData)
}

export function patientPreAnaestheticsCount(search){
        return axios.get('/patient-pre-anaesthetics/stat/count', { params: search })
}

