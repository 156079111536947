import { React, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import { getUser } from "../../../../services/AuthService";
import { useDispatch } from "react-redux";
import { addPatientClinicalNote } from "../../../../services/PatientClinicalNoteService";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import { fetchPreOps } from "../../../../features/patients/preOpSlice";
import MenuItem from "@mui/material/MenuItem";

import RichTextEditor from "react-rte"; // Copy Editor
import { addPatientOperativeReport } from "../../../../services/PatientOperativeReportService";
import { fetchPatientOperativeReport } from "../../../../features/patients/patientOperativeReportSlice";
import { fetchPatientOperativeReportsCount } from "../../../../features/patients/counts/patientOperativeReportsCountSlice";

function PreOpNoteModal({ show, onClose }) {
  const sex = [
    {
      value: "Gender",
      label: "Gender",
    },
    {
      value: "Male",
      label: "Male",
    },
    {
      value: "Female",
      label: "Female",
    },
  ];
  // const status = [
  //   {
  //     value: "Single",
  //     label: "Single",
  //   },
  //   {
  //     value: "Married",
  //     label: "Married",
  //   },
  // ];

  const language = [
    {
      value: "PG",
      label: "PG",
    },
    {
      value: "HA",
      label: "HA",
    },
    {
      value: "IG",
      label: "IG",
    },
    {
      value: "YO",
      label: "YO",
    },
    {
      value: "FUL",
      label: "FUL",
    },
    {
      value: "OTHERS",
      label: "OTHERS",
    },
  ];
  const [value, setValue] = useState(RichTextEditor.createEmptyValue()); // Copy Editor

  let { id } = useParams();
  const user = getUser();
  const dispatch = useDispatch();
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);

  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);

  const handleSub = () => {
    onClose();
    setAddReportShow(false);

    Swal.fire({
      customClass: {
        title: "title-class",
        icon: "icon-class",
      },

      icon: "success",
      title: "Successful",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const handleAdd = () => {
    setLoading(true);
    const data = {
      user: user?._id,
      patient: id,
      is_anatomical_location: isAnatomicallocation,
      is_cote: isCote,
      is_diagnostic_details: isDiagnosticdetails,
      is_operations: isOperations,
      is_dates: isDates,
      is_cote2: isCote2,
      is_operation_details: isOperationDetails,
      is_principal_operator: isPrincipalOperator,
      is_assistant: isAssistant,
      is_instrumentalist: isInstrumentalist,
      is_anesthetist2: isAnesthetist2,
      is_doorup: isDoorup,
      is_finup: isFinup,
      is_post_operative: isPostOperative,
      is_patient_positioning: isPatientPositioning,
      is_private_surveillance: isPrivatesurveillance,
      is_son: isSon,
      is_first_dressing: isFirstdressing,
      is_changeof_plaster: isChangeofplaster,
      is_possible: isPossible,
      is_perceived: isPerceived,
      is_next: isNext,
      is_diagnostic: isDiagnostic,
    };
    console.log(data);
    addPatientOperativeReport(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(fetchPatientOperativeReport({ patientId: id }));
          dispatch(fetchPatientOperativeReportsCount({ patientId: id }));
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  {
    /* Copy Editor */
  }
  const onChange = (value) => {
    setValue(value);
    value.toString("html");
    setNote(value.toString("html"));
  };


  const [isAnatomicallocation, setIsAnatomicallocation] = useState();
  const [isCote, setIsCote] = useState();
  const [isDiagnosticdetails, setIsDiagnosticdetails] = useState();
  const [isOperations, setIsOperations] = useState();
  const [isDates, setIsDates] = useState();
  const [isCote2, setIsCote2] = useState();
  const [isOperationDetails, setIsOperationDetails] = useState();
  const [isPrincipalOperator, setIsPrincipalOperator] = useState();
  const [isAssistant, setIsAssistant] = useState();
  const [isInstrumentalist, setIsInstrumentalist] = useState();
  const [isAnesthetist2, setIsAnesthetist2] = useState();
  const [isDoorup, setIsDoorup] = useState();
  const [isFinup, setIsFinup] = useState();
  const [isPostOperative, setIsPostOperative] = useState();
  const [isPatientPositioning, setIsPatientPositioning] = useState();
  const [isPrivatesurveillance, setIsPrivatesurveillance] = useState();
  const [isSon, setIsSon] = useState();
  const [isFirstdressing, setIsFirstdressing] = useState();
  const [isChangeofplaster, setIsChangeofplaster] = useState();
  const [isPossible, setIsPossible] = useState();
  const [isPerceived, setIsPerceived] = useState();
  const [isNext, setIsNext] = useState();
  const [isDiagnostic, setIsDiagnostic] = useState();


      

  return (
    <div>
      <div>
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                    <div className="Solera-Settings-Header">
                      Operative Report Form
                    </div>
                    <div className="Solera-Settings-Note">Add a report</div>
                  </div>
                </div>
                <br />
                <br />
                <div className="FormSect4">
                  <div className="FormSect">DIAGNOSTIC OPERATION</div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>

                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <TextField
                          fullWidth
                          label="Anatomical location
                          "
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                          onChange={(e) => setIsAnatomicallocation(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <TextField
                          fullWidth
                          label="Cote"
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                          onChange={(e) => setIsCote(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <TextField
                          fullWidth
                          label="Diagnostic details

                          "
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                          onChange={(e) => setIsDiagnosticdetails(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <div className="FormSect">Operation</div>
                        <TextField
                          fullWidth
                          label="Operation"
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                          onChange={(e) => setIsOperations(e.target.value)}
                        />
                      </div>
                      <div className="col-lg">
                        <div className="FormSect">Date </div>
                        <TextField
                          fullWidth
                          label=""
                          id="fullWidth"
                          type="date"
                          className="Solera-Login-Input mb-3"
                          onChange={(e) => setIsDates(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div className="FormSect4">
                  {/* <div className="FormSect">DIAGNOSTIC OPERATION</div> */}
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>

                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <TextField
                          fullWidth
                          label="Cote"
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                          onChange={(e) => setIsCote2(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <TextField
                          fullWidth
                          label="Operation Details"
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                          onChange={(e) => setIsOperationDetails(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  
               
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <TextField
                          fullWidth
                          label="Principal Operator"
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                          onChange={(e) => setIsPrincipalOperator(e.target.value)}
                        />
                      </div>
                      <div className="col-lg">
                        {/* <div className="FormSect">Operation</div> */}
                        <TextField
                          fullWidth
                          label="Assistant"
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                          onChange={(e) => setIsAssistant(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <TextField
                          fullWidth
                          label="Instrumentalist"
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                          onChange={(e) => setIsInstrumentalist(e.target.value)}
                        />
                      </div>
                      <div className="col-lg">
                        {/* <div className="FormSect">Operation</div> */}
                        <TextField
                          fullWidth
                          label="Anesthetist"
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                          onChange={(e) => setIsAnesthetist2(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <TextField
                          fullWidth
                          label="Door up
                          "
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                          onChange={(e) => setIsDoorup(e.target.value)}
                        />
                      </div>
                      <div className="col-lg">
                        {/* <div className="FormSect">Operation</div> */}
                        <TextField
                          fullWidth
                          label="fin up"
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                          onChange={(e) => setIsFinup(e.target.value)}
                        />
                      </div>

                   

                    </div>
                  </div>
                  <div className="FormSect-Contianer">
                    <div className="container-fluid p-0 FormInput">
                      <div className="col">
                        {/* <TextField
                        fullWidth
                        id="outlined-multiline-static "
                        label="Make a note for this patient"
                        multiline
                        rows={12}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        // defaultValue="Default Value"
                      /> */}
                        <div className="FormSect">
                          Description of the intervention{" "}
                        </div>

                        {/* Copy Editor */}
                        <RichTextEditor
                          className="editor"
                          value={value}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div className="FormSect4">
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <TextField
                          fullWidth
                          label="Post operative

                          "
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                          onChange={(e) => setIsPostOperative(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <TextField
                          fullWidth
                          label="Patient positioning
                          "
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                          onChange={(e) => setIsPatientPositioning(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <TextField
                          fullWidth
                          label="Private surveillance

                          "
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                          onChange={(e) => setIsPrivatesurveillance(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <TextField
                          fullWidth
                          label="First dressing

                          "
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                          onChange={(e) => setIsFirstdressing(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                      <div className="FormSect">son (Date of ablation if necessary) </div>
                        <TextField
                          fullWidth
                          label=""
                          id="fullWidth"
                          type="date"
                          className="Solera-Login-Input mb-3"
                          onChange={(e) => setIsSon(e.target.value)}
                        
                        />
                     
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <TextField
                          fullWidth
                          label="Change of plaster

                          "
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                          onChange={(e) => setIsChangeofplaster(e.target.value)}

                       
                        />
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <TextField
                          fullWidth
                          label="Possible departure from the hospital on
                          "
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                          onChange={(e) => setIsPossible(e.target.value)}


                       


                        />
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <TextField
                          fullWidth
                          label="Perceived tracking"
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                          onChange={(e) => setIsPerceived(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <TextField
                          fullWidth
                          label="next consultation

                          "
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                          onChange={(e) => setIsNext(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row gx-4">
                      <div className="col-lg">
                        <TextField
                          fullWidth
                          label="Diagnostic details

                          "
                          id="fullWidth"
                          type="text"
                          className="Solera-Login-Input mb-3"
                          onChange={(e) => setIsDiagnostic(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                 
                </div>
                <br /> <br />
                <div className="FormUpload">
                  {loading ? (
                    <Bars
                      heigth="50"
                      width="50"
                      color="gray"
                      ariaLabel="loading-indicator"
                    />
                  ) : (
                    <button className="Add-Button" onClick={handleAdd}>
                      Add Report
                    </button>
                  )}
                </div>
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default PreOpNoteModal;
