import { React, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import moment from "moment";

function ViewDsModal({ show, onClose, selected }) {
  const [viewPreOpNoteDetailsShow, setViewPreOpNoteDetailsShow] =
    useState(false);
  const handleViewPreOpNoteDetails = () => setViewPreOpNoteDetailsShow(false);

  const showDate = (passDate) => {
    return moment(passDate).format('DD-MM-YYYY')
  }
  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>

              <div className="Solera-Modal-Container">
                <br />
                <div className="Report-Display-Heading">Discharge Summary</div>
                <div className="Report-Tittle">Discharge Summary Display</div>
                <div className="Solera-Modal-Container">
                  <div className="Note-Modal-Cont">
                    <div>
                      <div className="Doctors-Name">
                        Doctors's name : &nbsp;<span>Doctors's name</span>
                      </div>
                      <div className="Report-Date">
                        Report Date: &nbsp;<span>{showDate(selected?.created_at)}</span>
                      </div>
           
                    </div>

                    <br />
                    <div className="Report-Display"> Discharge Diagnosis</div>
                    <div className="Report-Display-Note">
                      <div
                        dangerouslySetInnerHTML={{ __html: selected?.discharge_diagnosis }}
                      ></div>
                    </div>

                    <br />
                    <div className="Report-Display">
                      Procedures and Therapies
                    </div>
                    <div className="Report-Display-Note">
                      {selected?.is_procedures}
                    </div>
                    <br />
                    <div className="Report-Display">Complications</div>
                    <div className="Report-Display-Note">
                     {selected?.is_complications}
                    </div>
                    <br />

                    <div className="Report-Display">Consultations</div>
                    <div className="Report-Display-Note">
                      {selected?.is_consultations}
                    </div>
                    <br />

                    <div className="Solera-Settings-Header44">
                      Pertinent History
                    </div>

                    <div className="Report-Display-Note">
                      {selected?.is_conduct}
                    </div>
                    <br />
                    <div className="Report-Display">Lab</div>
                    <div className="Report-Display-Note">
                      {selected?.lab}
                    </div>

                    <br />
                    <div className="Report-Display">Conduct on Discharge</div>
                    <div className="Report-Display-Note">
                      {selected?.is_discharge}
                    </div>
                    <br />
                    <div className="Report-Display">Disposition</div>
                    <div className="Report-Display-Note">
                      {selected?.is_disposition}
                    </div>
                    <br />
                    <div className="Report-Display">Discharge to</div>
                    <div className="Report-Display-Note">
                      {selected?.is_disposition}
                    </div>
                    <br />
                    <div className="Report-Display">Diet</div>
                    <div className="Report-Display-Note">
                      {selected?.is_diet}
                    </div>
                    <br />
                    <div className="Report-Display">Activity</div>
                    <div className="Report-Display-Note">
                    {selected?.is_activity}
                    </div>
                    <br />
                    <div className="Report-Display">DME</div>
                    <div className="Report-Display-Note">
                      {selected?.is_dME}
                    </div>
                    <br />
                    <div className="Report-Display">Home Health Service</div>
                    <div className="Report-Display-Note">
                      {selected?.is_iHomeHealth}  
                    </div>
                    <br />
                    <div className="Report-Display">Lab2</div>
                    <div className="Report-Display-Note">
                      {selected?.is_lab2}
                    </div>
                    <br />
                    <div className="Report-Display">F/U Apts</div>
                    <div className="Report-Display-Note">
                      {selected?.is_apts}
                    </div>
                    <br />
                    <div className="Report-Display">Meds</div>
                    <div className="Report-Display-Note">
                      {selected?.is_meds}
                    </div>

                    <br />
                    <div className="Report-Display">Stop</div>
                    <div className="Report-Display-Note">
                      {selected?.is_stop}
                    </div>
                    <br />
                    <div className="Report-Display">Modify</div>
                    <div className="Report-Display-Note">
                      {selected?.is_modify}
                    </div>
                    <br />
                    <div className="Report-Display">New Rx</div>
                    <div className="Report-Display-Note">
                      {selected?.is_newRx}
                    </div>
                    
                  </div>
                </div>
                <br /> <br /> <br />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default ViewDsModal;
