import { React, useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../../../SoleraCss/Responsive.css";
import "../../../../SoleraCss/Solera.css";
import "../../../../Component/Modals/Modal.css";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import { getUser } from "../../../../services/AuthService";
import { useDispatch } from "react-redux";
import { addPatientClinicalNote } from "../../../../services/PatientClinicalNoteService";
import { toast } from "react-toastify";
import { fetchPhysiotherapy } from "../../../../features/patients/physiotherapySlice";
import { Bars } from "react-loader-spinner";
import RichTextEditor from "react-rte"; // Copy Editor
import { addPatientSurgicalSafety } from "../../../../services/PatientSurgicalSafetyService";
import { fetchPatientsSurgicalSafety } from "../../../../features/patients/patientsSurgicalSafetySlice";
import MenuItem from "@mui/material/MenuItem";
import Form from "react-bootstrap/Form";

function AddSurgicalModal({ show, onClose }) {
  let { id } = useParams();

  const [value, setValue] = useState(RichTextEditor.createEmptyValue()); // Copy Editor
  const [value2, setValue2] = useState(RichTextEditor.createEmptyValue()); // Copy Editor
  const [value3, setValue3] = useState(RichTextEditor.createEmptyValue()); // Copy Editor



  const user = getUser();
  const dispatch = useDispatch();
  const [diagnosis, setDiagnosis] = useState("");
  const [operation, setOperation] = useState("");
  const [additional, setAdditional] = useState("");
  

  const [loading, setLoading] = useState(false);

  const [addReportShow, setAddReportShow] = useState(false);
  const handleAddReport = () => setAddReportShow(false);

  const handleAdd = () => {
    setLoading(true);
    const data = {
      user: user?._id,
      patient: id,
      diagnosis: diagnosis,
      operation: operation,
      additional: additional,
    };
    console.log(data);
    addPatientSurgicalSafety(data)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          //closeRef.current.click()
          toast.success(
            response.data.message
              ? response.data.message
              : "Successfully Created"
          );
          onClose();
          dispatch(fetchPatientsSurgicalSafety({ patient: id }));
          Swal.fire({
            customClass: {
              title: "title-class",
              icon: "icon-class",
            },

            icon: "success",
            title: "Successful",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

 
  const onChange = (value) => {
    setValue(value);
    value.toString("html");
    setDiagnosis(value.toString("html"));
  };


  const onChange2 = (value) => {
    setValue2(value);
    value.toString("html");
    setOperation(value.toString("html"));
  };



  const onChange3 = (value) => {
    setValue3(value);
    value.toString("html");
    setAdditional(value.toString("html"));
  };


  
  const [isConfirm, setIfConfirm] = useState();
  const [isSurgeon, setIfSurgeon] = useState();
  const [isUnexpected, setIfUnexpected] = useState();
  const [isRequired, setIfRequired] = useState();
  const [isDuration, setIsDuration] = useState();
  const [isAnticipated2, setIsAnticipated2] = useState();
  const [isPatientspecific, setIsPatientspecific] = useState();
  const [isAntibiotics, setIsAntibiotics] = useState();
  const [isBloodavailability, setIsBloodavailability] = useState();
  const [isReview, setIfReview] = useState();
  const [isEquipment, setIfEquipment] = useState();
  const [isHasthe, setIfHasthe] = useState();
  const [isPatientlabel, setIfPatientlabel] = useState();
  const [isMachine, setIfMachine] = useState();
  const [isOximeter, setIfOximeter] = useState();
  const [isKnownallergy, setIfKnownallergy] = useState();
  const [isDifficult, setIfDifficult] = useState();
  const [isAvailable, setIfAvailable] = useState();
  const [isBlood, setIfBlood] = useState();
  const [isPlanned, setIfPlanned] = useState();
  const [isAppropriate, setIfAppropriate] = useState();
  const [isTeams, setIfTeams] = useState();
  const [isIncision, setIncision] = useState();
  const [isProphylaxis, setIfProphylaxis] = useState();
  const [isRoutine, setIsRoutine] = useState();
  const [isCasetake, setIsCasetake] = useState();
  const [isCritical, setIsCritical] = useState();
  const [isHowlong, setIsHowlong] = useState();
  const [isBloodLoss,   setIsBloodLoss  ] = useState();
  const [isProtheses, setIsProtheses] = useState();
  const [isSpecific, setIfSpecific] = useState();
  const [isIfIndicator, setIfIndicator] = useState();
  const [isIfConcerns, setIfConcerns] = useState();
  const [isPosition, setIfPosition] = useState();
  const [isProsthesis, setIfProsthesis] = useState();
  const [isMonitored, setIfMonitored] = useState();
  const [isDisplayed, setIfDisplayed] = useState();
  const [isDiathermy, setIfDiathermy] = useState();
  const [isCompatibility, setIfCompatibility] = useState();
  const [isAnticoagulation, setIfAnticoagulation] = useState();
  const [isRegistered, setIfRegistered] = useState();
  const [isDocumented, setIfDocumented] = useState();
  const [isSponge, setIfSponge] = useState();
  const [isSpecimen, setIsSpecimen] = useState();
  const [iskey, setIskey] = useState();
  const [isTournquet, setIfTournquet] = useState();
  const [isThroat, setIfThroat] = useState();
  const [isWhether, setIsWhether] = useState();
  



  return (
    <div>
      {" "}
      <div>
        {" "}
        <div>
          <Modal
            size="xl"
            show={show}
            onHide={onClose}
            backdrop="static"
            aria-labelledby="example-modal-sizes-title-lg"
            keyboard={false}
            animation={true}
          >
            <Modal.Body>
              <div className="Solera__ModalHeadings">
                <MdClose className="Solera__Close" onClick={onClose} />
              </div>
              <div className="Solera__ModalHeadings"></div>
              <div className="Solera-Modal-Container">
                <div className="Solera__Headings">
                  <div className="Log__Cont">
                    <img
                      src="/assets/lg.png"
                      alt=""
                      className="Log__Img__Modal"
                    />
                  </div>
                  <div className="Solera__Headings__Note">
                    <div className="Solera-Settings-Header">
                      Surgical safety checklist
                    </div>
                    <div className="Solera-Settings-Note">
                      Make a request for the Surgical safety checklist
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div className="FormSect4">
                  <div className="FormSect-Contianer">
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg">
                          <div>
                            <div className="FormSect">Diagnosis</div>
                            <div className="FormSect-Note">
                              {/* Please fill all the required field */}
                            </div>
                          </div>
                          {/* Copy Editor */} <br />
                          <RichTextEditor
                            className="editor"
                            value={value}
                            onChange={onChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="FormSect-Contianer">
                    <div className="container-fluid p-0 FormInput">
                      <div className="row g-2">
                        <div className="col-lg">
                          <div>
                            <div className="FormSect">Operation</div>
                            <div className="FormSect-Note">
                              {/* Please fill all the required field */}
                            </div>
                          </div>{" "}
                          <br />
                          <RichTextEditor
                            className="editor"
                            value={value2}
                            onChange={onChange2}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="FormSect">PRE OPERATIVE BRIEFING</div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg">
                        <div className="FormSect">
                          Confirm all team members introduction by name and role
                          for each patient listed
                        </div>
                        <div className="FormSect-Contianer">
                          {" "}
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Confirm"
                                    name="Confirm"
                                    onChange={(e) => setIfConfirm("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Confirm"
                                    name="Confirm"
                                    onChange={(e) => setIfConfirm("No")}
                                    label="Yes"
                                  />
                                </div>
                              </div>
                            </div>{" "}
                          </Form>
                        </div>
                      </div>
                      <div className="col-lg">
                        <div className="FormSect">
                          Surgeon confirms a specific equipment requirements of
                          investigation
                        </div>
                        <div className="FormSect-Note">
                          {/* Please fill all the required field */}
                        </div>
                        <div className="FormSect-Contianer">
                          {" "}
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Surgeon"
                                    name="Surgeon"
                                    onChange={(e) => setIfSurgeon("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Surgeon"
                                    name="Surgeon"
                                    onChange={(e) => setIfSurgeon("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>{" "}
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
            
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg">
                        <div className="FormSect">
                          Critical unexpected <br />
                          steps
                        </div>
                        <div className="FormSect-Note">
                          {/* Please fill all the required field */}
                        </div>
                        <div className="FormSect-Contianer">
                          {" "}
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Unexpected"
                                    name="Unexpected"
                                    onChange={(e) => setIfUnexpected("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Unexpected"
                                    name="Unexpected"
                                    onChange={(e) => setIfUnexpected("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>{" "}
                          </Form>
                        </div>
                      </div>
                      <div className="col-lg">
                        <div className="FormSect">
                          Essential imaging required
                        </div>
                        <div className="FormSect-Note">
                          {/* Please fill all the required field */}
                        </div>
                        <div className="FormSect-Contianer">
                          {" "}
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Required"
                                    name="Required"
                                    onChange={(e) => setIfRequired("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Required"
                                    name="Required"
                                    onChange={(e) => setIfRequired("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>{" "}
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>

               
                  
                  <div className="FormSect-Contianer">
                    <div className="container-fluid p-0 FormInput">
                      <div className="row gx-4">
                        <div className="col-lg">
                          <TextField
                            fullWidth
                            label="Duration of surgery "
                            id="fullWidth"
                            type="text"
                            className="Solera-Login-Input mb-3"
                            onChange={(e) => setIsDuration(e.target.value)}
                          />
                        </div>

                        <div className="col-lg">
                          <TextField
                            fullWidth
                            label="Anticipated blood loss"
                            id="fullWidth"
                            type="text"
                            className="Solera-Login-Input mb-3"
                            onChange={(e) => setIsAnticipated2(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="FormSect">Anaesthetist confirms</div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                  <div className="FormSect-Contianer">
                    <div className="container-fluid p-0 FormInput">
                      <div className="row gx-4">
                        <div className="col-lg">
                          <TextField
                            fullWidth
                            label="patient specific"
                            id="fullWidth"
                            type="text"
                            className="Solera-Login-Input mb-3"
                            onChange={(e) => setIsPatientspecific(e.target.value)}
                          />
                        </div>

                        <div className="col-lg">
                          <TextField
                            fullWidth
                            label="Antibiotics prosphylaxis"
                            id="fullWidth"
                            type="text"
                            className="Solera-Login-Input mb-3"
                            onChange={(e) => setIsAntibiotics(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>


              



                  <div className="FormSect-Contianer">
                    <div className="container-fluid p-0 FormInput">
                      <div className="row gx-4">
                        <div className="col-lg">
                          <TextField
                            fullWidth
                            label="Blood availability"
                            id="fullWidth"
                            type="text"
                            className="Solera-Login-Input mb-3"
                            onChange={(e) => setIsBloodavailability(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="FormSect">Nursing team confirms</div>
                  <div className="FormSect-Note">
                    {/* Please fill all the required field */}
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg">
                        <div>
                          <div className="FormSect">
                            Review sterility of instruments?(Including indicator
                            status)
                          </div>
                          <div className="FormSect-Note">
                            {/* Please fill all the required field */}
                          </div>
                        </div>{" "}
                        <div className="FormSect-Contianer">
                          {" "}
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Review"
                                    name="Review"
                                    onChange={(e) => setIfReview("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Review"
                                    name="Review"
                                    onChange={(e) => setIfReview("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>{" "}
                          </Form>
                        </div>
                      </div>
                      <div className="col-lg">
                        <div>
                          <div className="FormSect">
                            Any equipment or staffing concerns
                          </div>
                          <div className="FormSect-Note">
                            {/* Please fill all the required field */}
                          </div>
                        </div>{" "}
                        <div className="FormSect-Contianer">
                          {" "}
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="equipment"
                                    name="equipment"
                                    onChange={(e) => setIfEquipment("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="equipment"
                                    name="equipment"
                                    onChange={(e) => setIfEquipment("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>{" "}
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <br />

              
                <div className="FormSect4">
                  <div className="FormSect">SIGN IN</div>

                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg">
                        <div>
                          <div className="FormSect">
                            Has the patient confirmed his /her identity site,
                            procedure, and consent?
                          </div>
                          <div className="FormSect-Note">
                            {/* Please fill all the required field */}
                          </div>
                        </div>{" "}
                        <div className="FormSect-Contianer">
                          {" "}
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Hasthe"
                                    name="Hasthe"
                                    onChange={(e) => setIfHasthe("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Hasthe"
                                    name="Hasthe"
                                    onChange={(e) => setIfHasthe("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>{" "}
                          </Form>
                        </div>
                      </div>
                      <div className="col-lg">
                        <div>
                          <div className="FormSect">
                            Patient label <br />
                            correct?
                          </div>
                          <div className="FormSect-Note">
                            {/* Please fill all the required field */}
                          </div>
                        </div>
                        <div className="FormSect-Contianer">
                          {" "}
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Patientlabel"
                                    name="Patientlabel"
                                    onChange={(e) => setIfPatientlabel("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Patientlabel"
                                    name="Patientlabel"
                                    onChange={(e) => setIfPatientlabel("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>{" "}
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg">
                        <div>
                          <div className="FormSect">
                            Is the anesthesia machine, materials and medication
                            check complete?
                          </div>
                          <div className="FormSect-Note">
                            {/* Please fill all the required field */}
                          </div>
                        </div>
                        <div className="FormSect-Contianer">
                          {" "}
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Machine"
                                    name="Machine"
                                    onChange={(e) => setIfMachine("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Machine"
                                    name="Machine"
                                    onChange={(e) => setIfMachine("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>{" "}
                          </Form>
                        </div>
                      </div>





                      <div className="col-lg">
                        <div className="FormSect">
                          Is the pulse oximeter of the patient <br />
                          functioning?
                        </div>
                        <div className="FormSect-Note">
                          {/* Please fill all the required field */}
                        </div>

                        <div className="FormSect-Contianer">
                          {" "}
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Oximeter"
                                    name="Oximeter"
                                    onChange={(e) => setIfOximeter("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Oximeter"
                                    name="Oximeter"
                                    onChange={(e) => setIfOximeter("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>{" "}
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg">
                        <div className="FormSect">
                          Does the patient have a known allergy?
                        </div>
                        <div className="FormSect-Note">
                          {/* Please fill all the required field */}
                        </div>
                        <div className="FormSect-Contianer">
                          {" "}
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Knownallergy"
                                    name="Knownallergy"
                                    onChange={(e) => setIfKnownallergy("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Knownallergy"
                                    name="Knownallergy"
                                    onChange={(e) => setIfKnownallergy("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>{" "}
                          </Form>
                        </div>
                      </div>

                


                      <div className="col-lg">
                        <div className="FormSect">
                          Difficult airway or aspiration risk?
                        </div>
                        <div className="FormSect-Note">
                          {/* Please fill all the required field */}
                        </div>
                        <div className="FormSect-Contianer">
                          {" "}
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Difficult"
                                    name="Difficult"
                                    onChange={(e) => setIfDifficult("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Difficult"
                                    name="Difficult"
                                    onChange={(e) => setIfDifficult("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>{" "}
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg">
                        <div className="FormSect">
                          Is equipment and assistance available?
                        </div>
                        <div className="FormSect-Note">
                          {/* Please fill all the required field */}
                        </div>
                        <div className="FormSect-Contianer">
                          {" "}
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Available"
                                    name="Available"
                                    onChange={(e) => setIfAvailable("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Available"
                                    name="Available"
                                    onChange={(e) => setIfAvailable("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>{" "}
                          </Form>
                        </div>
                      </div>
                      <div className="col-lg">
                        <div>
                          <div className="FormSect">
                            Risk of &gt; 500ml blood loss (7ml/kg in children)?
                          </div>
                          <div className="FormSect-Note">
                            {/* Please fill all the required field */}
                          </div>
                        </div>{" "}
                        <div className="FormSect-Contianer">
                          {" "}
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Blood"
                                    name="Blood"
                                    onChange={(e) => setIfBlood("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Blood"
                                    name="Blood"
                                    onChange={(e) => setIfBlood("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>{" "}
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>

                        
                 
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg">
                        <div>
                          <div className="FormSect">
                            IVs/central access and fluid planned specialty.
                            specify checklist needed
                          </div>
                          <div className="FormSect-Note">
                            {/* Please fill all the required field */}
                          </div>
                        </div>{" "}
                        <div className="FormSect-Contianer">
                          {" "}
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Planned"
                                    name="Planned"
                                    onChange={(e) => setIfPlanned("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Planned"
                                    name="Planned"
                                    onChange={(e) => setIfPlanned("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>{" "}
                          </Form>
                        </div>
                      </div>
                      <div className="col-lg">
                        <div>
                          <div className="FormSect">
                            Appropriate cooling/warming of the <br />
                            theatre?
                          </div>
                          <div className="FormSect-Note">
                            {/* Please fill all the required field */}
                          </div>
                        </div>{" "}
                        <div className="FormSect-Contianer">
                          {" "}
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Appropriate"
                                    name="Appropriate"
                                    onChange={(e) => setIfAppropriate("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Appropriate"
                                    name="Appropriate"
                                    onChange={(e) => setIfAppropriate("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>{" "}
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div className="FormSect4">
                  <div className="FormSect">TIME OUT</div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg">
                        <div>
                          <div className="FormSect">
                            Confirm all teams have introduced themselves by name
                            and role
                          </div>
                          <div className="FormSect-Note">
                            {/* Please fill all the required field */}
                          </div>
                        </div>
                        <div className="FormSect-Contianer">
                          {" "}
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Teams"
                                    name="Teams"
                                    onChange={(e) => setIfTeams("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Teams"
                                    name="Teams"
                                    onChange={(e) => setIfTeams("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>{" "}
                          </Form>
                        </div>
                      </div>
                      <div className="col-lg">
                        <div>
                          <div className="FormSect">
                            To surgeon, anaesthetist, and nurse, confirm
                            patient's name , procedure and incision site
                          </div>
                          <div className="FormSect-Note">
                            {/* Please fill all the required field */}
                          </div>
                        </div>
                        <div className="FormSect-Contianer">
                          {" "}
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Incision"
                                    name="Incision"
                                    onChange={(e) => setIncision("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Incision"
                                    name="Incision"
                                    onChange={(e) => setIncision("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>{" "}
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg">
                        <div>
                          <div className="FormSect">
                            Has antibiotics prophylaxis been given within the
                            last 60 mins
                          </div>
                          <div className="FormSect-Note">
                            {/* Please fill all the required field */}
                          </div>
                        </div>
                        <div className="FormSect-Contianer">
                          {" "}
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Prophylaxis"
                                    name="Prophylaxis"
                                    onChange={(e) => setIfProphylaxis("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Prophylaxis"
                                    name="Prophylaxis"
                                    onChange={(e) => setIfProphylaxis("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>{" "}
                          </Form>
                        </div>
                      </div>

                 
                      <div className="col-lg"></div>
                    </div>
                  </div>
                  <div className="FormSect">ANTICIPATED CRITICAL EVENTS</div>
                  <div>
                    <div className="FormSect">To Surgeon</div>
                    <div className="FormSect-Note">
                      {/* Please fill all the required field */}
                    </div>
                  </div>
                  <div className="FormSect-Contianer">
                    <div className="container-fluid p-0 FormInput">
                      <div className="row gx-4">
                        <div className="col-lg">
                          <TextField
                            fullWidth
                            label="what are the critical or non-routine steps?"
                            id="fullWidth"
                            type="text"
                            className="Solera-Login-Input mb-3"
                            onChange={(e) => setIsRoutine(e.target.value)}
                          />
                        </div>
                        <div className="col-lg">
                          <TextField
                            fullWidth
                            label="How long will the case take?"
                            id="fullWidth"
                            type="text"
                            className="Solera-Login-Input mb-3"
                            onChange={(e) => setIsCasetake(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="FormSect-Contianer">
                    <div className="container-fluid p-0 FormInput">
                      <div className="row gx-4">
                        <div className="col-lg">
                          <TextField
                            fullWidth
                            label="What are the critical or non-routine steps?"
                            id="fullWidth"
                            type="text"
                            className="Solera-Login-Input mb-3"
                            onChange={(e) => setIsCritical(e.target.value)}
                          />
                        </div>
                        <div className="col-lg">
                          <TextField
                            fullWidth
                            label="How long will the case take?"
                            id="fullWidth"
                            type="text"
                            className="Solera-Login-Input mb-3"
                            onChange={(e) => setIsHowlong(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="FormSect-Contianer">
                    <div className="container-fluid p-0 FormInput">
                      <div className="row gx-4">
                        <div className="col-lg">
                          <TextField
                            fullWidth
                            label="What is the anticipated blood loss?"
                            id="fullWidth"
                            type="text"
                            className="Solera-Login-Input mb-3"
                            onChange={(e) => setIsBloodLoss(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="FormSect">Allergies & Prosthesis</div>
                  <div className="FormSect-Contianer">
                    <div className="container-fluid p-0 FormInput">
                      <div className="row gx-4">
                        <div className="col-lg">
                          <TextField
                            fullWidth
                            label=" Review and confirm the correct position of Protheses with Matron"
                            id="fullWidth"
                            type="text"
                            className="Solera-Login-Input mb-3"
                            onChange={(e) => setIsProtheses(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />

                 
                  <div className="FormSect">To Anaesthetist</div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg">
                        <div>
                          <div className="FormSect">
                            Are there any patient specific concerns to the
                            nursing team?
                          </div>
                          <div className="FormSect-Note">
                            {/* Please fill all the required field */}
                          </div>
                        </div>
                        <div className="FormSect-Contianer">
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Specific"
                                    name="Specific"
                                    onChange={(e) => setIfSpecific("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Specific"
                                    name="Specific"
                                    onChange={(e) => setIfSpecific("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                      <div className="col-lg">
                        <div>
                          <div className="FormSect">
                            Has sterility (including indicator result) been
                            confirmed?
                          </div>
                          <div className="FormSect-Note">
                            {/* Please fill all the required field */}
                          </div>
                        </div>
                        <div className="FormSect-Contianer">
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Indicator"
                                    name="Indicator"
                                    onChange={(e) => setIfIndicator("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Indicator"
                                    name="Indicator"
                                    onChange={(e) => setIfIndicator("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg">
                        <div>
                          <div className="FormSect">
                            Are there any equipment issues or concerns?
                          </div>
                          <div className="FormSect-Note">
                            {/* Please fill all the required field */}
                          </div>
                        </div>
                        <div className="FormSect-Contianer">
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Concerns"
                                    name="Concerns"
                                    onChange={(e) => setIfConcerns("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Concerns"
                                    name="Concerns"
                                    onChange={(e) => setIfConcerns("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                      <div className="col-lg"></div>
                    </div>
                  </div>
                  <div>
                    <div className="FormSect">Additional checks allergies</div>
                    <div className="FormSect-Note">
                      Note allergies Prosthesis
                    </div>
                  </div>{" "}
                  <br />
                  <RichTextEditor
                    className="editor"
                    value={value3}
                    onChange={onChange3}
                  />
                  <br />
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg"></div>
                      <div className="col-lg"></div>
                    </div>
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg"></div>
                      <div className="col-lg"></div>
                    </div>
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg">
                        <div>
                          <div className="FormSect">
                            Confirm the correct position for the <br />
                            prosthesis
                          </div>
                          <div className="FormSect-Note">
                            {/* Please fill all the required field */}
                          </div>
                        </div>{" "}
                        <div className="FormSect-Contianer">
              
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Position"
                                    name="Position"
                                    onChange={(e) => setIfPosition("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Position"
                                    name="Position"
                                    onChange={(e) => setIfPosition("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>{" "}
                          </Form>
                        </div>
                      </div>
                      <div className="col-lg">
                        <div>
                          <div className="FormSect">
                            Confirm the correct position for the prosthesis
                            normothermia
                          </div>
                          <div className="FormSect-Note">
                            {/* Please fill all the required field */}
                          </div>
                        </div>{" "}
                        <div className="FormSect-Contianer">
                          {" "}
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Prosthesis"
                                    name="Prosthesis"
                                    onChange={(e) => setIfProsthesis("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Prosthesis"
                                    name="Prosthesis"
                                    onChange={(e) => setIfProsthesis("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>{" "}
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg">
                        <div>
                          <div className="FormSect">
                            Check that it has been monitored and appropriate
                            action taken
                          </div>
                          <div className="FormSect-Note">
                            {/* Please fill all the required field */}
                          </div>
                        </div>{" "}
                        <div className="FormSect-Contianer">
                          {" "}
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Monitored"
                                    name="Monitored"
                                    onChange={(e) => setIfMonitored("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Monitored"
                                    name="Monitored"
                                    onChange={(e) => setIfMonitored("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>{" "}
                          </Form>
                        </div>
                      </div>


              




                      <div className="col-lg">
                        <div>
                          <div className="FormSect">
                            Is essential imaging displayed?
                          </div>
                          <div className="FormSect-Note">
                            {/* Please fill all the required field */}
                          </div>
                        </div>{" "}
                        <div className="FormSect-Contianer">
                          {" "}
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Displayed"
                                    name="Displayed"
                                    onChange={(e) => setIfDisplayed("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Displayed"
                                    name="Displayed"
                                    onChange={(e) => setIfDisplayed("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>{" "}
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg">
                        {" "}
                        <div>
                          <div className="FormSect">
                            Diathermy is functioning and the pad is in place?
                          </div>
                          <div className="FormSect-Note">
                            {/* Please fill all the required field */}
                          </div>
                        </div>{" "}
                        <div className="FormSect-Contianer">
                          {" "}
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Diathermy"
                                    name="Diathermy"
                                    onChange={(e) => setIfDiathermy("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Diathermy"
                                    name="Diathermy"
                                    onChange={(e) => setIfDiathermy("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>{" "}
                          </Form>
                        </div>
                      </div>
                      <div className="col-lg">
                        {" "}
                        <div>
                          <div className="FormSect">
                            Compatibility form of blood available?
                          </div>
                          <div className="FormSect-Note">
                            {/* Please fill all the required field */}
                          </div>
                        </div>{" "}
                        <div className="FormSect-Contianer">
                          {" "}
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Compatibility"
                                    name="Compatibility"
                                    onChange={(e) => setIfCompatibility("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Compatibility"
                                    name="Compatibility"
                                    onChange={(e) => setIfCompatibility("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>{" "}
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col">
                        {" "}
                        <div>
                          <div className="FormSect">
                            Anticoagulation /DVT prophylaxis, mechanical boots
                            or chemical UMWH/Others?
                          </div>
                          <div className="FormSect-Note">
                            {/* Please fill all the required field */}
                          </div>
                        </div>{" "}
                        <div className="FormSect-Contianer">
                          {" "}
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Anticoagulation"
                                    name="Anticoagulation"
                                    onChange={(e) =>
                                      setIfAnticoagulation("Yes")
                                    }
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Anticoagulation"
                                    name="Anticoagulation"
                                    onChange={(e) => setIfAnticoagulation("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>{" "}
                          </Form>
                        </div>
                      </div>
                      <div className="col-lg"></div>
                    </div>
                  </div>
                </div>
                <br />
                <br />


              

                <div className="FormSect4">
                  <div className="FormSect">SIGN OUT</div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg">
                        <div>
                          <div className="FormSect">
                            Registered practitioner verbally confirms the name
                            of the procedure
                          </div>
                          <div className="FormSect-Note">
                            {/* Please fill all the required field */}
                          </div>
                        </div>
                        <div className="FormSect-Contianer">
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Registered"
                                    name="Registered"
                                    onChange={(e) => setIfRegistered("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Registered"
                                    name="Registered"
                                    onChange={(e) => setIfRegistered("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                      <div className="col-lg">
                        <div>
                          <div className="FormSect">
                            Confirm <br />
                            documented
                          </div>
                          <div className="FormSect-Note">
                            {/* Please fill all the required field */}
                          </div>
                        </div>
                        <div className="FormSect-Contianer">
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Documented"
                                    name="Documented"
                                    onChange={(e) => setIfDocumented("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Documented"
                                    name="Documented"
                                    onChange={(e) => setIfDocumented("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="FormSect">
                      Compilation of instrumemt, sponge and needle counts
                    </div>
                    <div className="FormSect-Note">
                      {/* Please fill all the required field */}
                    </div>
                  </div>{" "}
                  <div className="FormSect-Contianer">
                    {" "}
                    <Form>
                      <div className="container-fluid p-0 FormInput">
                        <div className="row g-2">
                          <div className="col-lg-3 CheckSheet">
                            <Form.Check
                              type="radio"
                              id="Sponge"
                              name="Sponge"
                              onChange={(e) => setIfSponge("Yes")}
                              label="Yes"
                            />
                          </div>
                          <div className="col-lg-3 CheckSheet">
                            <Form.Check
                              type="radio"
                              id="Sponge"
                              name="Sponge"
                              onChange={(e) => setIfSponge("No")}
                              label="No"
                            />
                          </div>
                        </div>
                      </div>{" "}
                    </Form>
                  </div>
                  <div className="FormSect-Contianer">
                    <div className="container-fluid p-0 FormInput">
                      <div className="row gx-4">
                        <div className="col-lg">
                          <TextField
                            fullWidth
                            label="Specimen labelled and recorded in specimen"
                            id="fullWidth"
                            type="text"
                            className="Solera-Login-Input mb-3"
                            onChange={(e) => setIsSpecimen(e.target.value)}
                          />
                        </div>

                        <div className="col-lg">
                          <TextField
                            fullWidth
                            label="Whether there are any equipment problems and addressed"
                            id="fullWidth"
                            type="text"
                            className="Solera-Login-Input mb-3"
                            onChange={(e) => setIsWhether(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="FormSect">
                    To Surgeon Anaesthetist and nurse
                  </div>
                  <div>
                    <div className="FormSect">
                      What are the key concerns for the recovery and management
                      of this patient?
                    </div>
                    <div className="FormSect-Note">
                      {/* Please fill all the required field */}
                    </div>
                  </div>
                  <div className="FormSect-Contianer">
                    <div className="container-fluid p-0 FormInput">
                      <div className="row gx-4">
                        <div className="col-lg">
                          <TextField
                            fullWidth
                            label="Mention key concerns"
                            id="fullWidth"
                            type="text"
                            className="Solera-Login-Input mb-3"
                            onChange={(e) => setIskey(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid p-0 FormInput">
                    <div className="row g-2">
                      <div className="col-lg">
                        {" "}
                        <div>
                          <div className="FormSect">Tournquet removed?</div>
                          <div className="FormSect-Note">
                            {/* Please fill all the required field */}
                          </div>
                        </div>
                        <div className="FormSect-Contianer">
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Tournquet"
                                    name="Tournquet"
                                    onChange={(e) => setIfTournquet("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Tournquet"
                                    name="Tournquet"
                                    onChange={(e) => setIfTournquet("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                      <div className="col-lg">
                        {" "}
                        <div>
                          <div className="FormSect">Throat pack removed?</div>
                          <div className="FormSect-Note">
                            {/* Please fill all the required field */}
                          </div>
                        </div>
                        <div className="FormSect-Contianer">
                          <Form>
                            <div className="container-fluid p-0 FormInput">
                              <div className="row g-2">
                              <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Throat"
                                    name="Throat"
                                    onChange={(e) => setIfThroat("Yes")}
                                    label="Yes"
                                  />
                                </div>
                                <div className="col-lg-3 CheckSheet">
                                  <Form.Check
                                    type="radio"
                                    id="Throat"
                                    name="Throat"
                                    onChange={(e) => setIfThroat("No")}
                                    label="No"
                                  />
                                </div>
                              </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{" "}



               
                <br />
                <br />
                <div className="FormUpload">
                  {loading ? (
                    <Bars
                      heigth="50"
                      width="50"
                      color="gray"
                      ariaLabel="loading-indicator"
                    />
                  ) : (
                    <button className="Add-Button" onClick={handleAdd}>
                      Submit
                    </button>
                  )}
                </div>
              </div>{" "}
              <br />
              <br />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default AddSurgicalModal;
